import React from "react";
import Misc from "../pages/dashboards/Misc";
import Page from "@jumbo/shared/Page";

const dashboardRoutes = [
    {
        path: "/dashboard",
        element: <Page component={Misc} />
    }
];

export default dashboardRoutes;
