import React, { useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import moment from 'moment';

import { styled } from '@mui/material/styles';
import { Button, Grid } from "@mui/material";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from "@mui/lab/LoadingButton";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import { installmentsServices, projectServices, adminExpenseDailyServices, usersServices } from "../../../../../services";

const validationSchema = yup.object({
    ref_companyid: yup
        .number()
        .typeError('เลือกบริษัท')
        .required('Company is required'),
});
const initialValues = {
    ref_companyid: null,
    ref_project: null,
    ref_installment: null,
};

const SearchPanel = ({ company, yearsInstallments, onSubmit, refreshKey, t }) => {
    const Swal = useSwalWrapper();

    const [installment, Setinstallment] = React.useState([{ label: t('title.please_select'), value: null }]);
    const [projects, setProjects] = React.useState([{ label: t('title.select_all'), value: null }]);
    const [users, setUsers] = React.useState([]);

    const handleSearch = (data, { setSubmitting }) => {
        setSubmitting(true);
        searchMutation.mutate(data);
        setSubmitting(false);
    };

    const fetchInstallments = async (year) => {
        const responseinstallment = await installmentsServices.getAll({
            year: year,
        });
        if (responseinstallment && responseinstallment.data) {
            Setinstallment([
                { label: 'Please Select', value: null, key: -1 },
                ...responseinstallment.data.map(({ number, start_date, end_date, id }, index) => {
                    return { label: `งวดที่ ${number} วันที่ ${moment(start_date).format('DD/MM/YYYY')} - ${moment(end_date).format('DD/MM/YYYY')}`, value: id, key: index };
                })
            ])
        }
    }

    const fetchProjects = async (ref_companyid) => {
        const response = await projectServices.getlist({
            ref_companyid: ref_companyid,
            getall: true,
        });
        if (response && response) {
            setProjects([
                { label: t('title.select_all'), value: null, key: -1 },
                ...response.data.map(({ name, id }, index) => {
                    return { label: `${name}`, value: id, key: index };
                })
            ])
        }
    }

    const fetchEmployee = async (ref_companyid) => {
        const response = await usersServices.getListByParams({
            ref_companyid,
            limit: 1000,
        });
        if (response && response.data) {
            setUsers(response.data);
        }
    }

    const searchExpensesMutation = useMutation(adminExpenseDailyServices.getList, {
        onSuccess: (data, param) => {
            onSubmit(data, param)
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Search Expenses failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const handleInstallments = async (e) => {
        await fetchInstallments(e);
    };

    const searchMutation = React.useMemo(() => {
        return searchExpensesMutation;
    }, []);

    useEffect(() => {
        document.getElementById("submitsearch").click();
    }, [refreshKey]);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={t('display.search')}
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
            >
                <Formik
                    validateOnChange={true}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleSearch}
                    validator={() => ({})}
                >
                    {({ isSubmitting, setFieldValue, values, errors, touched, resetForm }) => (
                        <Form noValidate autoComplete="off">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_companyid"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue("ref_companyid", newValue.value);
                                                    setFieldValue("ref_project", null);
                                                    fetchProjects(newValue.value);
                                                    fetchEmployee(newValue.value);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.key}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={company ? company : []}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('display.company')} name="ref_companyid" error={(errors.ref_companyid && touched.ref_companyid)} helperText={(errors.ref_companyid && touched.ref_companyid) && errors.ref_companyid} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_project"
                                            onChange={(event, newValue) => {
                                                setFieldValue("ref_project", newValue.value);
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.key}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={projects ? projects : []}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('form.project')} name="ref_project" error={(errors.ref_project && touched.ref_project)} helperText={(errors.ref_project && touched.ref_project) && errors.ref_project} />}
                                            disabled={!values?.ref_companyid}
                                            value={values && projects.find(f => f.value === values?.ref_project)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="userid"
                                            name="userid"
                                            defaultValue={{ label: t('title.select_all'), value: null }}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue("userid", newValue.value);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={[
                                                { label: t('title.select_all'), value: null },
                                                ...users
                                                    .map(({ user_no, firstname, lastname, id }) => {
                                                        return { label: `${user_no} : ${firstname} ${lastname}`, value: id };
                                                    }),
                                            ]}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('display.employee')} />}
                                            disabled={!values?.ref_companyid}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="year"
                                            name="year"
                                            onChange={(event, newValue) => {
                                                setFieldValue("year", newValue.value);
                                                handleInstallments(newValue.value);
                                            }}
                                            options={[
                                                { label: 'Please Select', value: null, key: -1 },
                                                ...yearsInstallments
                                                    .map(({ year }, index) => {
                                                        return { label: year, value: year };
                                                    })
                                            ]}
                                            value={values?.year}
                                            isOptionEqualToValue={(option, value) => option.value === value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label="Year" error={(errors.year && touched.year)} helperText={(errors.year && touched.year) && errors.year} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_installment"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue("ref_installment", newValue.value);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.key}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            value={installment.find(f => f.value === values?.ref_installment)?.label}
                                            options={installment ? installment : []}
                                            isOptionEqualToValue={(option, value) => option.label === value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('form.installment')} name="ref_installment" error={(errors.ref_installment && touched.ref_installment)} helperText={(errors.ref_installment && touched.ref_installment) && errors.ref_installment} />}
                                            disabled={!values?.year}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mt: 3, margin: "0 auto", width: "25%" }} >
                                    <Grid item xs={6} textAlign={'center'} >
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            id="submitsearch"
                                            variant="contained"
                                            size="medium"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >{t('display.search')}</LoadingButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            type="reset"
                                            onClick={resetForm}
                                            variant="outlined"
                                            size="medium"
                                            sx={{ mb: 3 }}
                                        >{t('display.reset')}</Button>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Form>
                    )}
                </Formik>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default SearchPanel;