import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Box, Card, CardContent, Step, StepLabel, Stepper, Grid, Typography, TextField, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/History';

import Div from "@jumbo/shared/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import FormUploadFile from "../../../../../app/shared/widgets/FormUploadFile"
import { userovertimeServices } from "../../../../services";
import Storage from "../../../../common/Storage";

const validationSchema = yup.object({
    startdate: yup
        .string('Please Input Start date')
        .required('startdate is required'),
    enddate: yup
        .string('Please Input End date')
        .required('enddate is required'),
});
const initialValues = {
    date: null,
    startdate: "",
    enddate: "",
    note: "",
};
const UserovertimerequestForm = ({ userovertime, mode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const user = Storage.getUser();

    const pkref = useRef(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [triggerUploadFile, setTriggerUploadFile] = React.useState(false);

    const steps = [
        t("display.created"),
        t("display.draft"),
        t("display.waiting_for_approve"),
        t("display.completed"),
    ];

    const onUserovertimeSave = (data, { setSubmitting }) => {
        if (!userovertime) {
            data.statusCode = "waiting_approve"
        }
        if (data.date === null) {
            data.date = moment().format('YYYY-MM-DD')
        }
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };
    const addUserovertimeMutation = useMutation(userovertimeServices.add, {
        onSuccess: (data) => {
            pkref.current = data.data.id;
            setTriggerUploadFile((current) => !current);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User overtime has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
            navigate({
                pathname: '/time/user-overtime-request',
            })
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'User overtime has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const updateUserovertimeMutation = useMutation(userovertimeServices.update, {
        onSuccess: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User Expense has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'User Expense has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const saveMutation = React.useMemo(() => {
        return userovertime?.id ? updateUserovertimeMutation : addUserovertimeMutation;
    }, [userovertime, updateUserovertimeMutation, addUserovertimeMutation]);

    return (
        <>
            <JumboCardQuick sx={{ mb: 2 }}>
                <Div sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Div>
            </JumboCardQuick>

            <JumboCardQuick
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
                title={<Typography variant={"h5"} mb={0}>Created User Expense</Typography>}>
                <Formik
                    validateOnChange={true}
                    initialValues={userovertime?.id ? userovertime : initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onUserovertimeSave}
                    validator={() => ({})}
                >
                    {({ isSubmitting, setFieldValue, values, errors }) => (
                        <Form noValidate autoComplete="off">
                            <Div
                                sx={{
                                    '& .MuiTextField-root': { m: 1 },
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Card variant="outlined">
                                                <CardContent sx={{ pt: 1, }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} md={12} sx={{ ' .MuiTextField-root': { m: 0 } }} >
                                                            <DesktopDatePicker label={t('display.request_date')} name="date" value={moment(userovertime?.date)} format="YYYY-MM-DD" slotProps={{ textField: { fullWidth: true } }} disabled={mode && mode === "view"}
                                                                onChange={(value) => {
                                                                    setFieldValue("date", value.format('YYYY-MM-DD'));
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField fullWidth {...params} defaultValue={moment(userovertime?.date)} />
                                                                )} />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{
                                                            ' .MuiTextField-root': { m: 0 },
                                                        }} >
                                                            <DateTimePicker label={t('form.startdate')} name="startdate" defaultValue={userovertime ? moment(userovertime?.startdate) : null} format="YYYY-MM-DD HH:mm:ss" slotProps={{ textField: { fullWidth: true } }} disabled={mode && mode === "view"}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                onChange={(value) => {
                                                                    setFieldValue("startdate", value.format('YYYY-MM-DD HH:mm:ss'));
                                                                }} />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{
                                                            ' .MuiTextField-root': { m: 0 },
                                                        }} >
                                                            <DateTimePicker label={t('form.enddate')} name="enddate" defaultValue={userovertime ? moment(userovertime?.enddate) : null} format="YYYY-MM-DD HH:mm:ss" slotProps={{ textField: { fullWidth: true } }} disabled={mode && mode === "view"}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                onChange={(value) => {
                                                                    setFieldValue("enddate", value.format('YYYY-MM-DD HH:mm:ss'));
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField fullWidth {...params} />
                                                                )} />
                                                        </Grid>
                                                        <Grid item xs={12} md={12} sx={{
                                                            ' .MuiTextField-root': { m: 0 },
                                                        }} >
                                                            <JumboTextField fullWidth name="note" label={t('form.note')} multiline minRows={3} maxRows={20} disabled={mode && mode === "view"} />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormUploadFile
                                            disabled={mode && mode === "view"}
                                            triggerUpload={triggerUploadFile}
                                            keyRef1={userovertime ? userovertime.id : pkref.current}
                                            // fileType={fileTypes.ATTACH_FILE}
                                            onChange={(res) => {
                                                // handleChange(res);
                                            }}
                                            onError={(error) => {
                                                // setHasError(error);
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box textAlign='center' sx={{ mt: 1 }} >
                                    {(userovertime && userovertime.statusCode === "waiting_approve" && !['employee'].includes(user.role)) && (
                                        <LoadingButton
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mr: 1 }}
                                            endIcon={<SendIcon />}
                                            loadingPosition="end"
                                            loading={isSubmitting || saveMutation.isLoading}
                                        >Approve</LoadingButton>
                                    )}
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        sx={{ mr: 1 }}
                                        endIcon={<SendIcon />}
                                        loadingPosition="end"
                                        loading={isSubmitting || saveMutation.isLoading}
                                        disabled={mode && mode === "view" || userovertime && !['waiting_approve'].includes(userovertime.statusCode)}
                                    >Submit</LoadingButton>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant='outlined'
                                        startIcon={<HistoryIcon />}
                                        onClick={() => navigate({
                                            pathname: "/time/user-expense-request",
                                        })}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Div>
                        </Form>
                    )}

                </Formik>
            </JumboCardQuick>
        </>
    );
};

export default UserovertimerequestForm;