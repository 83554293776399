import React from 'react';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import authServices from "../../services/auth-services";
import { BasicAuthContext } from "./BasicAuthContext";
import Storage from "../../common/Storage";

import { companyServices } from "../../services";

const BasicAuth = ({ children }) => {
    const { setAuthValues, startAuthLoading, stopAuthLoading } = useJumboAuth();

    const init = async () => {
        const response = await companyServices.getAll();
        if (response && response.data) {
            Storage.setCompany(response.data);
        }
    }

    React.useEffect(() => {
        const token = Storage.getToken();
        startAuthLoading();
        if (token) {
            authServices.getCurrentUser().then((user) => {
                if (user) {
                    setAuthValues({ authToken: token, authUser: user });
                }
            }).catch((error) => {
                console.error(error, "error while getting auth user: ");
            })
        }
        else {
            setAuthValues({
                authToken: null,
                authUser: null,
            });
        }
    }, [setAuthValues, startAuthLoading, stopAuthLoading]);

    const signIn = React.useCallback((username, password, successCallback, errorCallback) => {
        // startAuthLoading();
        const param = { username, password };
        authServices.signIn(param)
            .then((response) => {
                if (response && response.status === 200) {
                    if (response?.data?.access_token) {
                        Storage.setUser(response?.data?.user);
                        Storage.setToken(response?.data?.access_token);
                        init();
                        setAuthValues({ authToken: response?.data?.access_token, authUser: response.data.user });
                        successCallback(response?.data)
                    }
                } else {
                    console.log(response)
                    errorCallback(response?.data);
                }
            })
            .catch(error => {
                console.log("login error: ", error)
                errorCallback(error)
            });
        // stopAuthLoading();
    }, [setAuthValues, startAuthLoading, stopAuthLoading]);


    const logout = React.useCallback(() => {
        Storage.clear();
        startAuthLoading();
        setAuthValues({ authToken: null, authUser: null }, { delay: 1000 });
    }, [setAuthValues, startAuthLoading]);

    const signUp = React.useCallback(() => {
        //work to finish the signup
    }, []);


    const contextValue = React.useMemo(() => ({
        signUp,
        signIn,
        logout,
    }), [signUp, signIn, logout]);

    return (
        <BasicAuthContext.Provider value={contextValue}>
            {children}
        </BasicAuthContext.Provider>
    )
};

export default BasicAuth;