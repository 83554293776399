import React from 'react';
import moment from 'moment';

import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Div from "@jumbo/shared/Div";

const DialogBox = (props) => {
    const { isOpen, data, onClose, onConfirm } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                        {(data.error) ? (
                            <div>
                                <HighlightOffIcon color='error' fontSize="large" style={{ fontSize: '5rem' }} />
                                <Typography variant={"h1"}>{'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}</Typography>
                            </div>
                        ) : (
                            <div>
                                <CheckCircleOutlineIcon color='success' fontSize="large" style={{ fontSize: '5rem' }} />
                                {(data && data.type) && (<Typography variant={"h1"}><span>{data.type}</span></Typography>)}
                                {(data && data.unix) && (<Typography variant={"h3"}>{moment.unix(data.unix).format('DD MMMM YYYY')}</Typography>)}
                                {(data && data.unix) && (<Typography variant={"h3"}>{moment.unix(data.unix).format('HH:mm:ss')}</Typography>)}
                                {(data && data.name) && (<Typography variant={"h4"}>{data.name} {data.address}</Typography>)}
                            </div>)
                        }
                        <Button onClick={handleClose}>Close!</Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DialogBox;