import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import moment from 'moment';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";
import SearchPanel from '../SearchPanel/SearchPanel';

import { expenseServices, exportReportServices, fileServices } from "../../../../services";

const ReportExpense = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [searchParams] = useSearchParams();
    const [listtypeExpense, SetlisttypeExpense] = React.useState([]);

    const handleExport = async (params) => {
        try {
            const filename = "Expense_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            const response = await exportReportServices.expense(params, filename);
            if (response) {
                // await fileServices.downloadFile(response.url, filename);
            }
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Export report expense failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    React.useEffect(async () => {
        const resTypes = await expenseServices.getTypes();
        if (resTypes) {
            let labelsTypes = [
                ...resTypes
                    .map(({ value1 }, index) => {
                        return { label: t('form.' + value1), value: value1 };
                    }),
            ];
            SetlisttypeExpense(labelsTypes);
        }
    }, []);

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t("display.report_expense")} />}
            layoutOptions={layoutOptions}
        >
            <SearchPanel
                title={t('display.report_expense_detail')}
                listtypeExpense={listtypeExpense}
                onExport={handleExport}
            />
        </JumboContentLayout>
    );
};

export default ReportExpense;