import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Button, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import { userleaveServices } from "../../../../../services";

const validationSchema = yup.object({
    reason: yup
        .string('Enter your Reason')
        .required('Reason is required'),
});
const initialValues = {
    reason: "",
};

const CancelForm = ({ item, onSave, onCancel }) => {
    const { t } = useTranslation();
    const Swal = useSwalWrapper();

    const onCancelSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const rejectFormMutation = useMutation(userleaveServices.reject, {
        onSuccess: () => {
            onSave();
        },
        onError: (error) => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Reject failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const handleCancelModal = () => {
        onCancel()
    }

    const saveMutation = React.useMemo(() => {
        return rejectFormMutation;
    }, [item, rejectFormMutation]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={item?.id ? item : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onCancelSave}
            validator={() => ({})}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <p>{t("detail.reject_request")}</p>
                        <JumboTextField fullWidth name="reason" label={t('form.reason')} multiline minRows={3} maxRows={20} />

                        <Grid container spacing={1} sx={{ mt: 2, margin: "0 auto", width: '50%' }} >
                            <Grid item xs={8} textAlign={'center'} >
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    id="submitsearch"
                                    variant="contained"
                                    size="medium"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >{t('display.submit')}</LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    type="reset"
                                    onClick={handleCancelModal}
                                    variant="outlined"
                                    size="medium"
                                    sx={{ mb: 3 }}
                                >{t('display.close')}</Button>
                            </Grid>
                        </Grid>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default CancelForm;