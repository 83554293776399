import Storage from "./Storage";

export function authHeader() {
    const token = Storage.getToken();
    let header = {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Headers': '*',
    };

    if (token) {
        header["Authorization"] = "Bearer " + token;
    }

    return header;
}

export function commonHeader() {
    const user = Storage.getUser();

    let header = {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Headers': '*',
    };

    if (user && user.token) {
        header["X-ByUser-Id"] = user.id;
        header["X-ByUser-Name"] = user.username;
    }

    return header;
}
