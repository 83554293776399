import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import CalendarWrapper from "./CalendarWrapper";

const localizer = momentLocalizer(moment);
const CalendarHoliday = ({ holiday }) => {
    const [event, setEvents] = React.useState([]);

    React.useEffect(() => {
        if (holiday) {
            const res = holiday.map((item, index) => {
                return ({
                    title: item.name,
                    allDay: true,
                    start: new Date(item.date),
                    end: new Date(item.date),
                })
            })

            setEvents(res);
        }
    }, [holiday]);

    return (
        <React.Fragment>
            <CalendarWrapper>
                <Calendar
                    localizer={localizer}
                    events={event}
                    step={60}
                    defaultDate={new Date()}
                    views={['month']}
                    style={{ height: 600 }}
                />
            </CalendarWrapper>
        </React.Fragment>
    );
};

export default CalendarHoliday;