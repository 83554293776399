import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { Typography, Fab, CircularProgress, Box } from "@mui/material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import liff from '@line/liff';
import moment from 'moment';
import { useTranslation } from "react-i18next";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import { LINE_LIFF_ID } from "../../../utils/constants/paths";

import { openModalAction } from "../../../redux/actions/ModalAction";

import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import CameraModal from '../../components/models/CameraModal'
import DialogBox from '../DialogBox/DialogBox';

import Storage from "../../../common/Storage";
import { longdoMapServices, usersServices } from '../../../services/index'

const Clockinout = () => {
    const { t } = useTranslation();
    const { showDialog, hideDialog } = useJumboDialog();
    const user = Storage.getUser();
    const dispatch = useDispatch();
    const { theme } = useJumboTheme();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [param, setParam] = React.useState({
        latitude: null,
        longitude: null,
        type: "clockIn"
    });
    const [time, setTime] = useState({
        unix: moment().unix(),
    });

    const getPosition = async (data) => {
        let param = {
            lat: data.coords.latitude,
            lng: data.coords.longitude,
        }
        const getAddress = await longdoMapServices.getAddress(param);
        setParam({
            ...param,
            getlocation: getAddress.data,
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
        })
    };

    const errorGetPosition = (err) => {
        setError(err);
    }

    const handleTakePhoto = async () => {
        const friend = await liff.getFriendship();
        if (!friend.friendFlag) {
            window.location = 'https://line.me/R/ti/p/@526nhfse';
        } else {
            dispatch(
                openModalAction({
                    type: "FORM",
                    headerText: "Take Camera",
                    isOpen: true,
                    data: param.getlocation,
                    t: t,
                    onClose: () => { },
                    onConfirm: async (data) => {
                        await handleSave(data);
                    },
                    contents: (<CameraModal />),
                })
            );
        }
    }

    const handleSave = async (data) => {
        setLoading(true);
        let output = {
            latitude: Number(param.latitude.toFixed(10)),
            longitude: Number(param.longitude.toFixed(10)),
            address: data?.getSelect?.address,
            name: data?.getSelect?.name,
            image: data.upLoadFileId,
        };
        try {
            const getProfileByLine = await liff.getProfile();
            if (!getProfileByLine) {
                output = {
                    ...output,
                    error: true,
                };
            } else {
                output = {
                    ...output,
                    ...time,
                    ...getProfileByLine
                }
                const res = await usersServices.clockInOut(output);
                if (res) {
                    setSuccess(true);
                }
            }
        } catch (error) {
            setError(error);
            output = {
                ...output,
                error: true,
                errormsg: error,
            };
        } finally {
            renderDiabox(output)
            setLoading(false);
        }
    }

    const renderDiabox = (param) => {
        dispatch(
            openModalAction({
                type: "FORM",
                headerText: t("display.take_camera"),
                data: param,
                isOpen: true,
                onClose: () => { },
                contents: (<DialogBox />),
            })
        );
    }

    const initializeLiff = (myLiffId) => {
        liff.init({
            liffId: myLiffId
        }).then(() => {
            if (!liff.isLoggedIn()) {
                liff.login();
            }
        }).catch((err) => {
            console.log(err.code, err.message);
        });
    }

    useEffect(() => {
        if (LINE_LIFF_ID && user) {
            initializeLiff(LINE_LIFF_ID);
        }
    }, [LINE_LIFF_ID])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(getPosition, errorGetPosition);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime({
                unix: moment().unix(),
            });
        }, 500);
        return () => clearInterval(interval)
    }, [])

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    return (
        <JumboContentLayout
            header={
                <PageHeader
                    title={t("display.time_attendance")}
                    subheader={""} />
            }
            layoutOptions={layoutOptions}
        >
            <Grid container justifyContent="center" alignItems="center" spacing={3.75} >
                <Grid item xs={12} lg={6}>
                    <JumboCardQuick sx={{ textAlign: 'center' }}>
                        <Typography variant={"h2"}>{moment.unix(time.unix).format('DD MMMM YYYY')}</Typography>
                        <Typography variant={"h1"}>{moment.unix(time.unix).format('HH:mm:ss')}</Typography>
                        <Box justifyContent='center' sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Fab
                                    aria-label="save"
                                    color={error ? "primary" : "error"}
                                    onClick={handleTakePhoto}
                                    disabled={loading || success || error || param.longitude === null}
                                >
                                    {error ? <PriorityHighIcon /> : success ? <CheckIcon /> : <PowerSettingsNewIcon />}
                                </Fab>
                                {loading && (
                                    <CircularProgress
                                        size={68}
                                        sx={{
                                            position: 'absolute',
                                            top: -6,
                                            left: -6,
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        {!param.longitude && (<Typography variant={"h4"} color={'red'}>{t("detail.please_all_location")}</Typography>)}
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </JumboContentLayout>
    );
};

export default Clockinout;