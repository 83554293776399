
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export const menuItems = [
    {
        icon: <EditRoundedIcon size={20} />,
        title: "Edit",
        value: "EDIT"
    },
    {
        icon: <DeleteRoundedIcon size={20} />,
        title: "Delete",
        value: "DELETE"
    }
];
