import React, { Fragment, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import _ from 'lodash';

import { Box, Button, styled, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Stack, Fab } from '@mui/material';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import { tableCellClasses } from '@mui/material/TableCell';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TextField from '@mui/material/TextField';

import { executeServices } from '../../../../services'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    }, "&.MuiTableCell-root": {
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        minWidth: 120
    }
}));

const DataTable = ({ data, params, listCompany, swal, onSubmit, onExport, onRefresh, loading }) => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const [body, Setbody] = React.useState(null);
    const [model, SetModel] = React.useState([]);
    const [getCompany, SetGetCompany] = React.useState(listCompany && listCompany.find(f => f.id === params.ref_companyid));

    const handleExport = () => {
        onExport();
    }

    const handleSubmit = () => {
        onSubmit(model);
    }

    const handleInput = (value, name, user_id, ref_installment, total) => {
        let getFindInput = model.find(f => f.user_id === user_id && f.ref_installment === ref_installment);
        let bonus = 0
        if (getFindInput) {
            bonus = getFindInput.bonus;
        }
        let cal = total - Number(value);
        if (cal <= 0) {
            cal = 0;
            value = total;
        }

        if (getFindInput) {
            SetModel(prevState => {
                const newState = prevState.map(obj => {
                    if (obj.user_id === user_id && obj.ref_installment === ref_installment) {
                        return {
                            ...obj,
                            remain: total - Number(value) + bonus,
                            actionMode: "EDIT",
                            [name]: Number(value),
                        };
                    }
                    return obj;
                });
                return newState;
            });
        }
        else {
            getFindInput = {
                user_id: user_id,
                ref_installment: ref_installment,
                ref_companyid: params.ref_companyid,
                total: total,
                remain: total - Number(value) + bonus,
                actionMode: "EDIT",
                [name]: value,
            }
            SetModel(prevState => [...prevState, getFindInput])
        }


    }

    const handleInputAdd = (value, name, user_id, ref_installment, total) => {
        let getFindInput = model.find(f => f.user_id === user_id && f.ref_installment === ref_installment);
        let cut_wages = 0
        if (getFindInput) {
            cut_wages = getFindInput.cut_wages;
        }

        if (getFindInput) {
            SetModel(prevState => {
                const newState = prevState.map(obj => {
                    if (obj.user_id === user_id && obj.ref_installment === ref_installment) {
                        return {
                            ...obj,
                            remain: Number(total) + Number(value) - Number(cut_wages),
                            actionMode: "EDIT",
                            [name]: Number(value),
                        };
                    }
                    return obj;
                });
                return newState;
            });
        }
        else {
            getFindInput = {
                user_id: user_id,
                ref_installment: ref_installment,
                ref_companyid: params.ref_companyid,
                total: total,
                remain: Number(total) + Number(value) - Number(cut_wages),
                actionMode: "EDIT",
                [name]: value,
            }
            SetModel(prevState => [...prevState, getFindInput])
        }


    }
    const handleReSyncExpenses = async () => {
        try {
            if (params?.ref_companyid == null) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'โปรดเลือกบริษัท',
                    showConfirmButton: false,
                    timer: 1500
                });
                return;
            }
            const inputs = {
                ...params
            }
            const res = await executeServices.adminExpenseDaily(inputs);
            if (res && res.success) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'ReSync Expenses has been successfully.',
                    showConfirmButton: false,
                    timer: 1500
                });
                onRefresh();
            }
        } catch (err) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'ReSyncExpenses has been failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    }

    useEffect(() => {
        Setbody(data);

        if (data) {
            SetModel(data.expenseGroupProject);
        }
    }, [data]);

    return (
        <Fragment>
            <JumboCardQuick
                title={<span>ฟอร์มค่าใช้จ่ายแบบแยก แผนก/โปรเจ็ค</span>}
                action={<Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Fab variant="extended" size="small" color="primary" aria-label="reload" onClick={handleReSyncExpenses} >
                        <LoopRoundedIcon sx={{ mr: 1 }} />
                        คำนวณค่าใช้จ่ายใหม่
                    </Fab>
                </Stack>}
                wrapperSx={{ p: 0 }}
                sx={{ mt: 2 }}
            >
                <TableContainer sx={{ maxHeight: 650 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>รหัสพนักงาน</StyledTableCell>
                                <StyledTableCell>ชื่อ</StyledTableCell>
                                <StyledTableCell>นามสกุล</StyledTableCell>
                                <StyledTableCell>ค่าแรงรายวัน</StyledTableCell>
                                {body && body?.projects.map((item, index) => (
                                    <StyledTableCell key={index} >
                                        {item.name}
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell>ผลรวม</StyledTableCell>
                                <StyledTableCell>โบนัส</StyledTableCell>
                                <StyledTableCell>หักเงิน</StyledTableCell>
                                <StyledTableCell>คงเหลือ</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {body && body?.users?.map((user, index) => {
                                const total = _.sumBy(body.datas.filter(f => f.user_id === user.id), item => Number(item.total));

                                return <TableRow key={index} >
                                    <StyledTableCell>{user.user_no}</StyledTableCell>
                                    <StyledTableCell>{user.firstname}</StyledTableCell>
                                    <StyledTableCell>{user.lastname}</StyledTableCell>
                                    <StyledTableCell>{user.wage || 0}</StyledTableCell>
                                    {body && body?.projects.map((item, index) => (
                                        <StyledTableCell key={index} >
                                            {body?.datas.find(f => f.user_id === user.id && f.ref_project === item.id)?.total || 0}
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell>{total}</StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            type="number"
                                            name={`bonus`}
                                            size='small'
                                            fullWidth
                                            value={model.find(f => f.user_id === user.id && f.ref_installment === params.ref_installment)?.bonus}
                                            onChange={(e) => {
                                                handleInputAdd(e.target.value, e.target.name, user.id, params.ref_installment, total);
                                            }}
                                            autoComplete={false}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            type="number"
                                            name={`cut_wages`}
                                            size='small'
                                            fullWidth
                                            value={model.find(f => f.user_id === user.id && f.ref_installment === params.ref_installment)?.cut_wages}
                                            onChange={(e) => {
                                                handleInput(e.target.value, e.target.name, user.id, params.ref_installment, total);
                                            }}
                                            autoComplete={false}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{model.find(f => f.user_id === user.id && f.ref_installment === params.ref_installment)?.remain || 0}</StyledTableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box textAlign='center'>
                    <Button id="submitsearch" variant="contained" size="medium" onClick={handleSubmit} disabled={loading}>{t('display.submit')}</Button>
                    <Button variant='outlined' sx={{ ml: 1 }}>{t('display.cancel')}</Button>
                </Box>
            </JumboCardQuick>
        </Fragment>
    );
};

export default DataTable;