import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { installmentsServices } from "../../../../services";

const validationSchema = yup.object({
    year: yup
        .string('Enter Year')
        .required('Year is required'),
});
const initialValues = {
    year: moment().format('YYYY'),
    number: null,
    start_date: "",
    end_date: "",
};
const InstallmentsForm = ({ installments, onSave }) => {
    const { t } = useTranslation();
    const Swal = useSwalWrapper();

    const onInstallmentsSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const addInstallmentsServicesMutation = useMutation(installmentsServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Installments has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Installments has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateInstallmentsServicesMutation = useMutation(installmentsServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Installments has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Installments has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return installments?.id ? updateInstallmentsServicesMutation : addInstallmentsServicesMutation;
    }, [installments, updateInstallmentsServicesMutation, addInstallmentsServicesMutation]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={installments?.id ? installments : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onInstallmentsSave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="number" type="text" label="installments" />
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} lg={6}>
                                        <DesktopDatePicker label={t('form.startdate')} name="start_date" size="small" defaultValue={moment(values.start_date)} format="YYYY-MM-DD" fullWidth
                                            maxDate={moment(values.end_date)}
                                            onChange={(value) => {
                                                setFieldValue("start_date", value.format('YYYY-MM-DD'));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <DesktopDatePicker label={t('form.enddate')} name="end_date" size="small" defaultValue={moment(values.end_date)} format="YYYY-MM-DD" fullWidth
                                            minDate={moment(values.start_date)}
                                            onChange={(value) => {
                                                setFieldValue("end_date", value.format('YYYY-MM-DD'));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" fullWidth />
                                            )} />
                                    </Grid>
                                </Grid>

                            </LocalizationProvider>
                        </FormControl>
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )}
        </Formik >
    );
};

export default InstallmentsForm;