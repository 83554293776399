import React, { Fragment } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import Storage from "../../../../common/Storage";
import { socialsecurityServices } from "../../../../services";


const initialValues = {
    year: moment().format('YYYY'),
    number: null,
    start_date: "",
    end_date: "",
};
const SocialsecurityForm = ({ model, onSave }) => {
    const listCompany = Storage.getCompany();
    const Swal = useSwalWrapper();
    const [company] = React.useState(
        listCompany ? [
            { label: 'Please Select', value: null, key: -1 },
            ...listCompany?.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            ,
        ] : []
    )

    const onSubmit = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const updateServicesMutation = useMutation(socialsecurityServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Social Security has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Social Security  has been updated failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const addServicesMutation = useMutation(socialsecurityServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Social Security has been add successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Social Security has been add failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return model?.id ? updateServicesMutation : addServicesMutation;
    }, [model, updateServicesMutation, addServicesMutation]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={model?.id ? model : initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            label="กำหนดเป็นข้อมูลตั้งต้น"
                                            control={<Checkbox
                                                checked={values.is_default}
                                                onChange={(event) => {
                                                    setFieldValue("is_default", event.target.checked);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <DatePicker disabled={values.is_default} label={'ปี'} openTo="year" views={['year']} defaultValue={moment(values.years)} sx={{ width: "100%" }} format="YYYY"
                                            onChange={(value) => {
                                                setFieldValue("years", value.format('YYYY'));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" />
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <DatePicker disabled={values.is_default} label={'เดือน'} openTo="month" views={['month']} defaultValue={moment(values.month)} sx={{ width: "100%" }} format="MM"
                                            onChange={(value) => {
                                                setFieldValue("month", value.format('MM'));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" />
                                            )} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_companyid"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue("ref_companyid", newValue.value);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.key}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            defaultValue={model?.companyName}
                                            options={company ? company : []}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label="Company" name="ref_companyid" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <JumboTextField fullWidth variant="outlined" name="totalcal" type="number" label="ค่าประกันสังคม" />
                                    </Grid>
                                </Grid>

                            </LocalizationProvider>
                        </FormControl>
                    </Div>

                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )}
        </Formik>
    );
};

export default SocialsecurityForm;