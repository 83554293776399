import React from 'react';
import { List } from "@mui/material";
import NoDataPlaceHolder from '../../../components/NoDataPlaceHolder'

import CompanyItem from '../CompanyItem'

const CompanyList = ({ companys, onEditCallback, onDeleteCallback }) => {
    const onAction = (action, item) => {
        switch (action) {
            case 'EDIT':
                onEditCallback(item);
                break;
            case 'DELETE':
                onDeleteCallback(item);
                break;
            default:
        }
    };

    return (
        <List disablePadding>
            {
                companys && companys.length <= 0 && (<NoDataPlaceHolder></NoDataPlaceHolder>)
            }
            {
                companys && companys.length > 0 && companys.map((company, index) => (
                    <CompanyItem company={company} key={index} onAction={onAction} />
                ))
            }
        </List>
    );
};

export default CompanyList;