import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const companyServices = {};
companyServices.getList = async ({ queryKey }) => {
    const { queryParams } = queryKey[queryKey.length - 1];
    return await get('/company', queryParams);
};

companyServices.getAll = async () => {
    return await get('/company');
};

companyServices.getDetail = async (id) => {
    return get(`/company/${id}`);
};
companyServices.add = async (params) => {
    return post(`/company`, params);
};
companyServices.update = async (params) => {
    return put(`/company/${params.id}`, params);
};

companyServices.delete = async (id) => {
    return del(`/company/${id}`);
};

export default companyServices;