import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get } = http(baseUrl);

const rolesServices = {};

rolesServices.getAll = async () => {
    return await get('/roles');
};

export default rolesServices;