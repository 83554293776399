import React from "react";
import { Navigate } from "react-router-dom";

// Page
import Page from "@jumbo/shared/Page";

import Error404 from "../pages/extra-pages/Error404";
import Logins from "../pages/auth-pages/Logins";

// Route
import companyRoutes from "./companyRoutes";
import clockinoutRoutes from "./clockinoutRoutes";
import dashboardRoutes from "./dashboardRoutes";
import timesRoutes from "./timesRoutes";
import usersRoutes from "./usersRoutes";
import adminRoutes from "./adminRoutes";
import settingRoutes from "./settingRoutes";
import reportRoutes from "./reportRoutes";


/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "*",
        element: <Page component={Error404} layout={"solo-page"} />
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    ...companyRoutes,
    ...clockinoutRoutes,
    ...dashboardRoutes,
    ...timesRoutes,
    ...usersRoutes,
    ...adminRoutes,
    ...settingRoutes,
    ...reportRoutes,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Logins} layout={"solo-page"} disableSmLogin={true} />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };