import React from "react";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const CompanyDropdown = ({ company, active, onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value);
    };
    return (
        <Select value={active} onChange={handleChange} size={"small"}>
            <MenuItem value={"all"}>All Years</MenuItem>
            {
                company && company.length > 0 && company.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                        {item?.label}
                    </MenuItem>
                ))
            }
        </Select>
    );
};
export default CompanyDropdown;
