import { axios, http } from "../common";
const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);
const expenseServices = {};

expenseServices.getList = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    return await get('/user-expense-request', { ...queryParams, page, limit });
};

expenseServices.getById = async (id) => {
    return await get('/user-expense-request/' + id);
};

expenseServices.getTypes = async () => {
    return await get('/user-expense-request/getTypes');
};

expenseServices.add = async (params) => {
    return await post('/user-expense-request', { ...params });
};

expenseServices.update = async (params) => {
    return await put('/user-expense-request/' + params.id, { ...params });
};

expenseServices.approve = async (params) => {
    return await post('/user-expense-request/approve/' + params.id, { ...params });
};

expenseServices.reject = async (params) => {
    return await post('/user-expense-request/reject/' + params.id, { ...params });
};

expenseServices.approveGetList = async (params) => {
    const { page, pageSize, queryParams } = params;
    return await get('/user-expense-request', { ...queryParams, page, limit: pageSize });
};


export default expenseServices;
