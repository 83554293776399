import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";

import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import PageHeaderCustom from "../../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import { installmentsServices } from "../../../../services";
import Storage from "../../../../common/Storage";

import SearchPanel from './SearchPanel';
import DataTable from './DataTable';

const useStyles = makeStyles({
    grid: {
        display: "flex",
        flexDirection: "column-reverse"
    }
});
const AdminExpenses = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { theme } = useJumboTheme();
    const mounted = useRef(false);
    const listCompany = Storage.getCompany();
    const [company, setCompany] = React.useState(
        [
            { label: t('title.please_select'), value: null, key: -1 },
        ]
    )

    const [params, SetParams] = React.useState({});
    const [data, Setdata] = React.useState(null);
    const [yearsInstallments, setYearsInstallments] = React.useState([]);
    const [refreshKey, setRefreshKey] = React.useState(0);

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    const handleSearch = (data, param) => {
        SetParams(param);
        Setdata(data);
    };

    const handleApprove = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }

    const handleReject = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }

    useEffect(() => {
        if (listCompany) {
            const selectbox = listCompany.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            setCompany(selectbox)
        }
    }, []);

    useEffect(() => {
        async function fetchYearsInstallments() {
            const response = await installmentsServices.getYears();
            if (response && response) {
                setYearsInstallments(response.data)
            }
        }
        fetchYearsInstallments();
        return () => mounted.current = false;
    }, []);

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t('title.approve_expense_daily_request')} />}
            layoutOptions={layoutOptions}
        >

            <SearchPanel
                company={company}
                yearsInstallments={yearsInstallments}
                onSubmit={handleSearch}
                refreshKey={refreshKey}
                t={t}
            />
            {data && <DataTable
                data={data}
                params={params}
                onApprove={handleApprove}
                onReject={handleReject} />}

        </JumboContentLayout>
    );
};

export default AdminExpenses;