import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const workShiftServices = {};

workShiftServices.add = async () => {
    return get('/work-shift');
};

workShiftServices.getlist = async (params) => {
    return get('/work-shift', params);
};

workShiftServices.add = async (params) => {
    return post(`/work-shift`, params);
};

workShiftServices.update = async (params) => {
    return put(`/work-shift/${params.id}`, params);
};

workShiftServices.delete = async (id) => {
    return del(`/work-shift/${id}`);
};

export default workShiftServices;