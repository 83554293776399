import React from 'react';
import UsersAppContext from "./UsersAppContext";
import { CONTACT_APP_ACTIONS } from "./utils/constants";

const init = (appState) => appState;

const usersAppReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedUsers: action?.payload
            };
        case CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH:
            return {
                ...state,
                refreshUsersList: action.payload,
            };
        case CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH:
            return {
                ...state,
                refreshLabelsList: action.payload,
            };
        default:
            return state;
    }
};

const UsersAppProvider = ({ children }) => {
    const [usersApp, setUsersApp] = React.useReducer(usersAppReducer, {
        selectedUsers: [],
        refreshUsersList: false,
        refreshLabelsList: false,
    }, init);

    const setSelectedUsers = React.useCallback((users) => {
        setUsersApp({ type: CONTACT_APP_ACTIONS.SET_SELECTED_ITEMS, payload: users });
    }, [setUsersApp]);

    const setUsersListRefresh = React.useCallback((refresh) => {
        setUsersApp({ type: CONTACT_APP_ACTIONS.SET_CONTACTS_LIST_REFRESH, payload: refresh })
    }, [setUsersApp]);

    const setLabelsListRefresh = React.useCallback((refresh) => {
        setUsersApp({ type: CONTACT_APP_ACTIONS.SET_LABELS_LIST_REFRESH, payload: refresh })
    }, [setUsersApp]);

    const contextValue = React.useMemo(() => ({
        ...usersApp,
        setSelectedUsers: setSelectedUsers,
        setUsersListRefresh: setUsersListRefresh,
        setLabelsListRefresh: setLabelsListRefresh,
    }), [usersApp]);
    return (
        <UsersAppContext.Provider value={contextValue}>
            {children}
        </UsersAppContext.Provider>
    );
};

export default UsersAppProvider;
