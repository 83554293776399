import React, { Fragment } from 'react';

import { Button } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import UserForm from "../UserForm";
import useUsersApp from "../../hooks/useUsersApp";
import CompanyList from "../CompanyList";

const UsersAppSidebar = (props) => {
    const { department, role, usersByHeader, listCompany, t } = props;
    const { showDialog, hideDialog } = useJumboDialog();
    const { setUsersListRefresh } = useUsersApp();
    const [company] = React.useState(
        listCompany ? [
            { label: 'Please Select', value: null, key: -1 },
            ...listCompany?.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            ,
        ] : []
    )

    const handleUserAdd = React.useCallback(() => {
        hideDialog();
        setUsersListRefresh(true);
    }, [hideDialog, setUsersListRefresh]);

    const showAddUserDialog = React.useCallback(() => {
        showDialog({
            title: t('title.add_new_user'),
            content: <UserForm company={company} role={role} usersByHeader={usersByHeader} departments={department} onSave={handleUserAdd} />
        });
    }, [handleUserAdd, showDialog, usersByHeader]);

    return (
        <Fragment>
            <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<PersonAddIcon />}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={showAddUserDialog}>Add Employee</Button>

            <CompanyList />
        </Fragment>
    );
};

export default UsersAppSidebar;