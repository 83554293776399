import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AppLayout from "./components/AppLayout";
import AppRoutes from "./components/AppRoutes";
import configureStore, { history } from './redux/store';
import AppProvider from "./components/AppProvider";
import AppJumboBuilder from "./components/AppJumboBuilder";
import AppSnackbar from "./components/AppSnackbar";
import { ModalContainer } from "./components/Modals";
import { AlertContainer } from "./components/Alerts";

import Spinner from "./shared/Spinner";
import Storage from "./common/Storage";
import "./services/apis";
import "./components/Sentry/Sentry";

var { EventEmitter } = require("fbemitter");
window.emitter = new EventEmitter();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const store = configureStore();

function App() {
    window.emitter.addListener("on-error", (data) => onError(data));
    const onError = (data) => {
        const { code, message } = data;
        if (code === 401) {
            try {
                Storage.clear();
            } catch (e) {
                console.log("onError", e);
            }
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <AppJumboBuilder>
                            <AppSnackbar>
                                <AppLayout>
                                    <Suspense fallback={<Spinner />}>
                                        <AppRoutes />

                                        <ModalContainer />
                                        <AlertContainer />
                                    </Suspense>
                                </AppLayout>
                            </AppSnackbar>
                        </AppJumboBuilder>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
