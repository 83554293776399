import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";

import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from "@mui/lab/LoadingButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { projectServices, usersServices } from "../../../../services";

const validationSchema = yup.object({
    name: yup
        .string('Enter your Project Name')
        .required('Project Name is required'),
    ref_companyid: yup
        .string('Enter your company')
        .required('company is required'),
    ref_owner: yup
        .string('Enter your owner')
        .required('owner is required'),
});
const initialValues = {
    ref_companyid: null,
    name: "",
    description: "",
    ref_owner: "",
    users: null,
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ProjectForm = ({ project, company, onSave, t }) => {
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const [user, setUsers] = React.useState([]);
    const [selectName, setSelectName] = React.useState([]);

    const onProjectSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        let arrUser = [];
        selectName.forEach(item => {
            const getuser = user.find(f => f.user_no === item);
            arrUser.push({
                ...getuser,
                ref_usersid: getuser?.id,
                roletypename: getuser?.roletype?.name,
            });
        });
        data['users'] = arrUser;
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const addProjectMutation = useMutation(projectServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Project has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Project has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateProjectMutation = useMutation(projectServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Project has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Project has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return project?.id ? updateProjectMutation : addProjectMutation;
    }, [project, updateProjectMutation, addProjectMutation]);

    React.useEffect(() => {
        async function fetchData(project) {
            const param = { ref_company: project.ref_companyid };
            const response = await usersServices.getListByParams(param);
            if (response) {
                setUsers(response.data)
            }
            return response;
        }

        if (project) {
            fetchData(project);
        }
    }, [project]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const fetchUser = async (ref_companyid) => {
        const param = { ref_company: ref_companyid };
        const response = await usersServices.getListByParams(param);
        if (response) {
            setUsers(response.data)
        }
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={project?.id ? project : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onProjectSave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values, errors }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            id="ref_companyid"
                            name="ref_companyid"
                            onChange={(event, newValue) => {
                                setFieldValue("ref_companyid", newValue.value);
                                fetchUser(newValue.value);
                            }}
                            options={company ? company : []}
                            size="small"
                            variant="outlined"
                            defaultValue={project ? company.find(f => f.value === project?.ref_companyid)?.label : null}
                            renderInput={(params) => <TextField {...params} label="Company" />}
                        />

                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Project Name" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="description" label="Description" multiline minRows={3} maxRows={20} />
                        <Autocomplete
                            fullWidth
                            id="ref_owner"
                            name="ref_owner"
                            onChange={(event, newValue) => {
                                setFieldValue("ref_owner", newValue.value);
                            }}
                            options={[
                                ...user
                                    .map(({ user_no, firstname, lastname, id }) => {
                                        return { label: `${user_no} : ${firstname} ${lastname}`, value: id };
                                    }),
                            ]}
                            size="small"
                            variant="outlined"
                            defaultValue={project ? `${project?.owner?.user_no} : ${project?.owner?.firstname} ${project?.owner?.lastname}` : null}
                            renderInput={(params) => <TextField {...params} label="Project Owner" />}
                        />
                        <FormControl sx={{ mb: 3, minWidth: '100%' }}>
                            <InputLabel id="user-project">User</InputLabel>
                            <Select
                                labelId="user-project"
                                id="user-project"
                                multiple
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={selectName}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            const item = user.find(f => f.user_no === value);
                                            return (<Chip key={value} label={`${item.user_no} : ${item.firstname} ${item.lastname}`} />)
                                        })}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                sx={{ '.MuiSelect-multiple': { pb: -2 } }}
                            >
                                {user.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.user_no}
                                        style={getStyles(item.user_no, selectName, theme)}
                                    >
                                        {item.user_no} : {item.firstname} {item.lastname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <JumboTextField fullWidth size="small" variant="outlined" name="customer" label="provider" />
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )
            }
        </Formik >
    );
};

export default ProjectForm;