import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import UserleaverequestForm from './UserleaverequestForm'
import UserleaverequestList from './UserleaverequestList'

import { userleaveServices } from "../../../services";

const Userleaverequest = () => {
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [searchParams] = useSearchParams();
    const [userleave, setUserleave] = React.useState(null);

    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    React.useEffect(async () => {
        const getid = searchParams.get('id');
        if (getid) {
            const response = await userleaveServices.getById(getid);
            if (response) {
                setUserleave(response.data);
            }
        }
    }, [])

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t("sidebar.menuItem.userleaverequest")} />}
            layoutOptions={layoutOptions}
        >
            {searchParams.get('mode') ? (<UserleaverequestForm mode={searchParams.get('mode')} userleave={userleave} />) : (<UserleaverequestList />)}
        </JumboContentLayout>
    );
};

export default Userleaverequest;