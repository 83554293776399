import { axios } from "../common";
import { authHeader, commonHeader } from "../common/requestHeader";
const baseUrl = `${process.env.REACT_APP_API_URL}`;
axios.defaults.baseURL = '' + baseUrl;

const authServices = {};

authServices.getCurrentUser = async () => {
    try {
        const response = await axios.get("/auth/userProfile", {
            headers: {
                ...authHeader(),
                ...commonHeader(),
            },
        });
        return response.data
    } catch (e) {
        return e;
    }
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    try {
        return await axios.post('/auth/login', loginCreds);
    } catch (e) {
        return e;
    }

};

export default authServices;