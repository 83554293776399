import React, { useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { Typography, Stack, Fab, Dialog, DialogActions, Button, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";

import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import CompanyList from '../CompanyList'
import { companyServices } from "../../../services";


const validationSchema = yup.object({
    name: yup
        .string('Enter your CompanyName')
        .required('CompanyName is required'),
    address_name: yup
        .string('Enter your Address')
        .required('Address is required'),
    phone_number: yup
        .string('Enter your Phone Number')
        .required('Phone Number is required'),
});
const initialValues = {
    name: "",
    address_name: "",
    phone_number: "",
    total_work_date: null,
    allow_timelate: null,
};

const Detail = () => {
    const Swal = useSwalWrapper();
    const isFirstRender = React.useRef(true);
    const { showDialog, hideDialog } = useJumboDialog();

    const [openModal, setOpenModal] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [listCompany, SetCompanys] = React.useState([]);
    const [company, setCompany] = React.useState(null);

    const onCompanySave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    }

    const addCompanyMutation = useMutation(companyServices.add, {
        onSuccess: () => {
            setOpenModal(false);
            setRefreshKey(oldKey => oldKey + 1);
            // onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Company has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: (error) => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Company has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateCompanyMutation = useMutation(companyServices.update, {
        onSuccess: () => {
            setOpenModal(false);
            setRefreshKey(oldKey => oldKey + 1);
            // onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Company has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const deleteContactMutation = useMutation(companyServices.delete, {
        onSuccess: () => {
            hideDialog();
            setRefreshKey(oldKey => oldKey + 1);
            // onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Company has been deleted successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const onEditCallback = (data) => {
        console.log(data);
        setCompany(data);
        setOpenModal(true);
    }

    const onDeleteCallback = (data) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this Company?',
            content: "You won't be able to recover this company later",
            onYes: () => deleteContactMutation.mutate(data.id),
            onNo: hideDialog
        })
    };

    useEffect(() => {
        async function fetchData() {
            const response = await companyServices.getAll();
            if (response) {
                SetCompanys(response.data)
            }
        }
        fetchData();
    }, [refreshKey]);

    const saveMutation = React.useMemo(() => {
        return company?.id ? updateCompanyMutation : addCompanyMutation;
    }, [company, updateCompanyMutation, addCompanyMutation]);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={'Company'}
                action={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Fab size="small" color="primary" aria-label="add" onClick={() => { setCompany(null); setOpenModal(true); }}>
                            <AddIcon />
                        </Fab>
                    </Stack>
                }
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',

                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
                wrapperSx={{ p: 0 }}
            >
                <JumboScrollbar
                    autoHeight
                    autoHeightMin={460}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                >
                    <CompanyList companys={listCompany} onEditCallback={onEditCallback} onDeleteCallback={onDeleteCallback} />
                </JumboScrollbar>
            </JumboCardQuick>

            <Dialog fullWidth={true} maxWidth={'sm'} open={openModal} onClose={() => setOpenModal(false)}>
                <Formik
                    validateOnChange={true}
                    initialValues={company?.id ? company : initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onCompanySave}
                    validate={(values) => {
                        let errors = {};
                        if (!values.name) {
                            errors.name = "Required";
                        }
                        return errors;
                    }}
                >
                    {({ isSubmitting, setFieldValue, errors }) => (
                        <><Form noValidate autoComplete="off">
                            <DialogTitle>{company?.id ? 'Update Company' : 'Create Company'}</DialogTitle>
                            <DialogContent>
                                <Div
                                    sx={{
                                        '& .MuiTextField-root': {
                                            mt: 1,
                                        },
                                    }}
                                >
                                    <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Company" />
                                    <JumboTextField fullWidth size="small" variant="outlined" name="address_name" label="Address" />
                                    <JumboTextField fullWidth size="small" variant="outlined" name="phone_number" label="Phone Number" />
                                    <Div sx={{
                                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                            pr: 1,
                                            pb: 0,
                                            width: { xs: '100%', sm: '50%' }
                                        },
                                    }}>
                                        <FormControl>
                                            <JumboTextField fullWidth size="small" type="number" variant="outlined" name="total_work_date" label="Total Work (Hrs)" />
                                        </FormControl>
                                        <FormControl>
                                            <JumboTextField fullWidth size="small" type="number" variant="outlined" name="allow_timelate" label="Allow Timelate" />
                                        </FormControl>

                                    </Div>
                                </Div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 1 }}
                                    loading={isSubmitting || saveMutation.isLoading}
                                >Save</LoadingButton>
                            </DialogActions>
                        </Form>
                        </>)}
                </Formik>
            </Dialog>
        </React.Fragment>
    );
};

export default Detail;