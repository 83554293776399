import FileDownload from "js-file-download";
import { http, axios } from "../common";
import { authHeader, commonHeader } from "../common/requestHeader";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get } = http(baseUrl);

const exportReportServices = {};

exportReportServices.expense = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            params: {
                ...params,
            },
            url: '/export-report-expense',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject("404 (Not Found)");
                }
            })
            .catch((error) => reject(error));
    });
}

exportReportServices.leave = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            params: {
                ...params,
            },
            url: '/export-report-leave',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject("404 (Not Found)");
                }
            })
            .catch((error) => reject(error));
    });
};

exportReportServices.attendance = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            params: {
                ...params,
            },
            url: '/export-report-attendance',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

exportReportServices.expenseDaily = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            params: {
                ...params,
            },
            url: '/export-report-expense-daily',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

exportReportServices.adminexpenseDaily = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            params: {
                ...params,
            },
            url: '/export-report-admin-expense-daily',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export default exportReportServices;