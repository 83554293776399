import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Button, Grid, Select, MenuItem } from "@mui/material";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const SearchPanel = ({ title = 'search_panel', listtypeLeave, listtypeExpense, onExport }) => {
    const { t } = useTranslation();
    const [params, SetParams] = React.useState({
        typeleave: "all",
        typeexpense: "all",
    });

    const handleChange = (value, type) => {
        SetParams({
            ...params,
            [type]: value
        });
    };

    const handleExport = () => {
        onExport(params);
    };
    const handleReset = () => {
        SetParams({
            typeleave: "all",
            typeexpense: "all",
        });
    };

    return (
        <JumboCardQuick
            title={title}
            headerSx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -.75
                }
            }}

        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container spacing={3} sx={{ mt: 3, margin: "0 auto", width: "50%" }} >
                    <Grid item lg={4} md={6} xs={12} sx={{ margin: "0 auto", textAlign: "center" }} >
                        <DatePicker label={t('form.startdate')} maxDate={moment(params.enddate) || null}
                            onChange={(value) => {
                                handleChange(value.format('YYYY-MM-DD'), "startdate")
                            }} />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sx={{ margin: "0 auto", textAlign: "center" }} >
                        <DatePicker label={t('form.enddate')} minDate={moment(params.startdate) || null} onChange={(value) => {
                            handleChange(value.format('YYYY-MM-DD'), "enddate")
                        }} />
                    </Grid>

                    {listtypeLeave && listtypeLeave.length > 0 && (
                        <Grid item lg={4} md={6} xs={12} sx={{ margin: "0 auto", textAlign: "center" }} >
                            <Select value={params.typeleave} onChange={e => { handleChange(e.target.value, "typeleave") }} fullWidth >
                                <MenuItem value={"all"}>Select All</MenuItem>
                                {
                                    listtypeLeave.map((leave, index) => (
                                        <MenuItem key={index} value={leave.value}>
                                            {leave.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    )}

                    {listtypeExpense && listtypeExpense.length > 0 && (
                        <Grid item lg={4} md={6} xs={12} sx={{ margin: "0 auto", textAlign: "center" }} >
                            <Select value={params.typeexpense} onChange={e => { handleChange(e.target.value, "typeexpense") }} fullWidth >
                                <MenuItem value={"all"}>Select All</MenuItem>
                                {
                                    listtypeExpense.map((expense, index) => (
                                        <MenuItem key={index} value={expense.value}>
                                            {expense.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    )}
                </Grid>
            </LocalizationProvider>


            <Grid container spacing={1} sx={{ margin: "0 auto", mt: 2, width: "25%" }} >
                <Grid item xs={6} textAlign={'center'} >
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleExport}
                        size="medium"
                        sx={{ mb: 3 }}
                    >Export</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        type="reset"
                        onClick={handleReset}
                        variant="outlined"
                        size="medium"
                        sx={{ mb: 3 }}
                    >Reset</Button>
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
};

export default SearchPanel;