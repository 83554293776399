import React, { useMemo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";
import SearchPanel from '../SearchPanel/SearchPanel';

import { userleaveServices, exportReportServices, fileServices } from "../../../../services";

const ReportLeave = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [listtypeLeave, SetlisttypeLeave] = React.useState([]);

    const handleExport = async (params) => {
        try {
            const filename = "Leave_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            const response = await exportReportServices.leave(params, filename);
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Export Report Leave failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    useEffect(() => {
        async function fetchData() {
            const resTypes = await userleaveServices.getTypes();
            if (resTypes) {
                let labelsTypes = [
                    ...resTypes
                        .map(({ value1 }, index) => {
                            return { label: t('form.' + value1), value: value1 };
                        }),
                ];
                SetlisttypeLeave(labelsTypes);
            }
        }
        fetchData();
    }, []);

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t("display.report_leave")} />}
            layoutOptions={layoutOptions}
        >
            <SearchPanel
                title={t('display.report_leave_detail')}
                listtypeLeave={listtypeLeave}
                onExport={handleExport}
            />

        </JumboContentLayout>
    );
};

export default ReportLeave;