import { OPEN_MODAL, CLOSE_MODAL } from "./actionTypes";

export function openModalAction(modal) {
    return function (dispatch) {
        dispatch({
            type: OPEN_MODAL,
            payload: modal,
        });
    };
}

export function closeModalAction(modal) {
    return function (dispatch) {
        dispatch({
            type: CLOSE_MODAL,
            payload: modal,
        });
    };
}
