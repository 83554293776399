import { axios } from ".";
import { authHeader, commonHeader } from "./requestHeader";

function http(baseUrl) {
    const get = (url, param, headers) => {
        const apiUrl = `${baseUrl}${url}`;
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    ...commonHeader(),
                    ...authHeader(),
                    ...{ ...(headers || {}) },
                },
                method: "GET",
                url: `${apiUrl}`,
                params: param,
                mode: 'no-cors',
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    };

    const post = (url, data, headers) => {
        const apiUrl = `${baseUrl}${url}`;
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    ...commonHeader(),
                    ...authHeader(),
                    ...{ ...(headers || {}) },
                },
                method: "post",
                url: `${apiUrl}`,
                data: data,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    };

    const put = (url, data, headers) => {
        const apiUrl = `${baseUrl}${url}`;
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    ...commonHeader(),
                    ...authHeader(),
                    ...{ ...(headers || {}) },
                },
                method: "put",
                url: `${apiUrl}`,
                data: data,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    };

    const del = (url, param, headers) => {
        const apiUrl = `${baseUrl}${url}`;
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    ...commonHeader(),
                    ...authHeader(),
                    ...{ ...(headers || {}) },
                },
                method: "delete",
                url: `${apiUrl}`,
                params: param,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    };

    return { get, post, put, del };
}
export default http;
