import React, { useRef, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';

import {
    Card,
    Button,
    IconButton,
    ButtonGroup,
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboSearch from "@jumbo/components/JumboSearch";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";

import { usersServices } from "../../../services";

import useUsersApp from "../hooks/useUsersApp";
import ImportUser from "../components/ImportUser"

import BulkActions from "./BulkActions";
import UserItem from './UserItem';

const Userlist = (props) => {
    const { department, role, usersByHeader, listCompany, t } = props;
    const Swal = useSwalWrapper();
    const params = useParams();
    const listRef = useRef();
    const { refreshUsersList, setSelectedUsers, setUsersListRefresh } = useUsersApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const [view, setView] = useState("list");
    const [company] = React.useState(
        listCompany ? [
            { label: 'Please Select', value: null, key: -1 },
            ...listCompany?.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            ,
        ] : []
    )
    const [queryOptions, setQueryOptions] = useState({
        queryParams: { refCompanyid: params.id },
        queryKey: "data",
        countKey: "total",
        dataKey: "data",
    });

    const renderUser = useCallback((user) => {
        return (<UserItem user={user} company={company} usersByHeader={usersByHeader} departments={department} role={role} view={view} />)
    }, [department]);

    const handleOnChange = useCallback((keywords) => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords,
            }
        }))
    }, []);

    const handleImport = () => {
        showDialog({
            title: 'Import Users',
            content: <ImportUser onSave={hideDialogAndRefreshContactsList} />
        });
    }

    const handleExport = async () => {
        try {
            const params = { ...queryOptions.queryParams };
            const filename = "User_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            await usersServices.export(params, filename);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Download Complete',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message
            });
        }
    };

    const hideDialogAndRefreshContactsList = React.useCallback(() => {
        hideDialog();
        setUsersListRefresh(true);
    }, [hideDialog, setUsersListRefresh]);

    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: { ...state.queryParams, refCompanyid: params.id }
        }))
    }, [params]);

    React.useEffect(() => {
        if (refreshUsersList) {
            listRef.current.refresh();
            setUsersListRefresh(false);
        }
    }, [refreshUsersList]);



    return (
        <JumboRqList
            ref={listRef}
            wrapperComponent={Card}
            service={usersServices.getList}
            primaryKey={"id"}
            queryOptions={queryOptions}
            itemsPerPage={10}
            itemsPerPageOptions={[10]}
            renderItem={renderUser}
            componentElement={"div"}
            sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar
                    hideItemsPerPage={false}
                    bulkActions={
                        <BulkActions />
                    }
                    actionTail={
                        <ButtonGroup
                            variant="outlined"
                            disableElevation
                            sx={{
                                '& .MuiButton-root': {
                                    px: 1,
                                }
                            }}
                        >
                            <IconButton color='primary' alt="upload file" onClick={handleImport}><FileUploadRoundedIcon /></IconButton>

                            <IconButton color='primary' alt="download file" onClick={handleExport}><FileDownloadRoundedIcon /></IconButton>

                            <IconButton color='primary' disabled alt="filter file" onClick={() => { }}><FilterAltRoundedIcon /></IconButton>

                        </ButtonGroup>
                    }
                >
                    {/* <JumboSearch
                        onChange={handleOnChange}
                        sx={{
                            display: { xs: 'none', md: 'block' }
                        }}
                    /> */}
                </JumboListToolbar>
            }
            onSelectionChange={setSelectedUsers}
            view={"list"}
        />
    );
};

export default Userlist;