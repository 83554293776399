import React, { useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Button, Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from "@mui/lab/LoadingButton";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import { projectServices, adminExpenseDailyServices, installmentsServices, usersServices } from "../../../../services";

const validationSchema = yup.object({
    ref_companyid: yup
        .number()
        .typeError('เลือกบริษัท')
        .required('Company is required'),
    userid: yup
        .number()
        .typeError('เลือกพนักงาน')
        .required('Employee is required'),
});
const initialValues = {
    ref_companyid: null,
    ref_project: null,
    picked_select_date: '1',
    date: null,
    year: null,
    ref_installment: null,
    userid: null,
};

const SearchPanel = ({ company, onSubmit, refreshKey }) => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const mounted = React.useRef(false);

    const [yearsInstallments, setYearsInstallments] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [projects, setProjects] = React.useState([{ label: t('title.select_all'), value: null }]);
    const [installment, setInstallment] = React.useState([{ label: t('title.select_all'), value: null }]);

    const handleInstallments = async (e) => {
        await fetchInstallments(e);
    };

    const handleSearch = (data, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit([], data);
        searchMutation.mutate(data);
        setSubmitting(false);
    };

    const fetchEmployee = async (ref_companyid) => {
        const response = await usersServices.getListByParams({
            ref_companyid,
            limit: 1000,
        });
        if (response && response.data) {
            setUsers(response.data);
        }
    }

    const fetchInstallments = async (year) => {
        const responseinstallment = await installmentsServices.getAll({
            year: year,
        });
        if (responseinstallment && responseinstallment.data) {
            setInstallment(responseinstallment.data)
        }
    }

    const fetchProjects = async (ref_companyid) => {
        const response = await projectServices.getlist({
            ref_companyid: ref_companyid,
            getall: true,
        });
        if (response && response) {
            setProjects([
                { label: t('title.select_all'), value: null, key: -1 },
                ...response.data.map(({ name, id }, index) => {
                    return { label: `${name}`, value: id, key: index };
                })
            ])
        }
    }

    const searchExpensesMutation = useMutation(adminExpenseDailyServices.getList, {
        onSuccess: (data, param) => {
            onSubmit(data, param)
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Search Expenses failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const searchMutation = React.useMemo(() => {
        return searchExpensesMutation;
    }, []);

    useEffect(() => {
        document.getElementById("submitsearch").click();
    }, [refreshKey]);

    useEffect(() => {
        async function fetchYearsInstallments() {
            const response = await installmentsServices.getYears();
            if (response && response) {
                console.log(response);
                setYearsInstallments(response.data)
            }
        }
        fetchYearsInstallments();
        return () => mounted.current = false;
    }, []);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={t('display.searchexpenses')}
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
            >
                <Formik
                    validateOnChange={true}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleSearch}
                    validator={() => ({})}
                >
                    {({ isSubmitting, setFieldValue, values, errors, touched, resetForm }) => (
                        <Form noValidate autoComplete="off">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_companyid"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    const ref_companyid = newValue.value;
                                                    setFieldValue("ref_companyid", ref_companyid);
                                                    setFieldValue("ref_project", null);
                                                    fetchProjects(ref_companyid);
                                                    fetchEmployee(ref_companyid);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={company ? company : []}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('display.company')} name="ref_companyid" error={(errors.ref_companyid && touched.ref_companyid)} helperText={(errors.ref_companyid && touched.ref_companyid) && errors.ref_companyid} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="ref_project"
                                            defaultValue={{ label: t('title.select_all'), value: null }}
                                            onChange={(event, newValue) => {
                                                setFieldValue("ref_project", newValue.value);
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={projects ? projects : []}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('form.project')} name="ref_project" error={(errors.ref_project && touched.ref_project)} helperText={(errors.ref_project && touched.ref_project) && errors.ref_project} />}
                                            disabled={!values?.ref_companyid}
                                            value={values && projects.find(f => f.value === values?.ref_project)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="userid"
                                            name="userid"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue("userid", newValue.value);
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            options={[
                                                { label: 'โปรดเลือกพนักงาน', value: null },
                                                ...users
                                                    .map(({ user_no, firstname, lastname, id }) => {
                                                        return { label: `${user_no} : ${firstname} ${lastname}`, value: id };
                                                    }),
                                            ]}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} label={t('display.employee')} error={(errors.userid && touched.userid)} helperText={(errors.userid && touched.userid) && errors.userid} />}
                                            disabled={!values?.ref_companyid}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <FormLabel id="option-select-date-label">เลือกประเภทการค้นหา</FormLabel>
                                            <RadioGroup row aria-labelledby="option-select-date-label" name="picked_select_date" value={values.picked_select_date}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("picked_select_date", newValue);
                                                }} >
                                                <FormControlLabel value="1" control={<Radio />} label="แบบงวด" />
                                                <FormControlLabel value="2" control={<Radio />} label="แบบวันที่" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {(values.picked_select_date === '1') && <>
                                        <Grid item xs={12} md={4.5}>
                                            <Autocomplete
                                                fullWidth
                                                disableClearable
                                                id="year"
                                                name="year"
                                                onChange={(event, newValue) => {
                                                    setFieldValue("year", newValue.value);
                                                    handleInstallments(newValue.value);
                                                }}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.value}>
                                                            {option.label}
                                                        </li>
                                                    );
                                                }}
                                                options={[
                                                    ...yearsInstallments
                                                        .map(({ year }, index) => {
                                                            return { label: year, value: year };
                                                        })
                                                ]}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                variant="outlined"
                                                renderInput={(params) => <TextField {...params} label={t('form.years')} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4.5}>
                                            <Autocomplete
                                                fullWidth
                                                disableClearable
                                                id="ref_installment"
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setFieldValue("ref_installment", newValue.value);
                                                        setFieldValue("objinstallment", installment?.find(f => f.id === newValue.value));
                                                    }
                                                }}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.key}>
                                                            {option.label}
                                                        </li>
                                                    );
                                                }}
                                                options={installment ?
                                                    [
                                                        ...installment.map(({ number, start_date, end_date, id }, index) => {
                                                            return { label: `งวดที่ ${number} วันที่ ${moment(start_date).format('DD/MM/YYYY')} - ${moment(end_date).format('DD/MM/YYYY')}`, value: id, key: index };
                                                        })
                                                    ] : []}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                variant="outlined"
                                                renderInput={(params) => <TextField {...params} label={t('form.installment')} name="ref_installment" />}
                                                disabled={!values.year}
                                            />
                                        </Grid>
                                    </>}

                                    {(values.picked_select_date === '2') && <Grid item xs={12} md={9}>
                                        <DatePicker
                                            label={t('form.date')}
                                            format='YYYY-MM-DD'
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: Boolean(touched.date && errors.date),
                                                    helperText: (errors.date && touched.date) && errors.date
                                                },
                                            }}
                                            onChange={(value) => {
                                                setFieldValue("date", value.format('YYYY-MM-DD'));
                                            }} />
                                    </Grid>}

                                </Grid>

                                <Grid container spacing={1} sx={{ mt: 3, margin: "0 auto", width: "25%" }} >
                                    <Grid item xs={6} textAlign={'center'} >
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            id="submitsearch"
                                            variant="contained"
                                            size="medium"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >{t('display.search')}</LoadingButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            type="reset"
                                            onClick={resetForm}
                                            variant="outlined"
                                            size="medium"
                                            sx={{ mb: 3 }}
                                        >{t('display.reset')}</Button>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Form>
                    )}
                </Formik>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default SearchPanel;