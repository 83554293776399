import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const holidayServices = {};
holidayServices.getList = async (params) => {
    return await get('/holiday', params);
};

holidayServices.add = async (params) => {
    return await post('/holiday', { ...params });
};

holidayServices.update = async (params) => {
    return await put('/holiday/' + params.id, { ...params });
};

holidayServices.delete = async (id) => {
    return await del('/holiday/' + id);
};

export default holidayServices;