import React from 'react';
import {
    ListItemText,
    Typography,
    Chip,
    Stack,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
// import { contactService } from "../../../../services/contact-services";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import moment from 'moment';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const ExpensetItem = ({ contact, t }) => {
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={contact}
                secondaryAction={
                    <>
                        {(contact.statusCode === "waiting_approve") && (
                            <>
                                <Typography variant={"body1"}>{contact.statusCode && <Chip color="primary" label={t('display.' + contact.statusCode)}></Chip>}</Typography>
                            </>
                        )}

                        {(contact.statusCode === "approve") && (
                            <>
                                <Typography variant={"body1"}>{contact.statusCode && <Chip color="success" label={t('display.' + contact.statusCode)}></Chip>}</Typography>
                            </>
                        )}

                        {(contact.statusCode === "reject") && (
                            <>
                                <Typography variant={"body1"}>{contact.statusCode && <Chip color="error" label={t('display.' + contact.statusCode)}></Chip>}</Typography>
                            </>
                        )}


                    </>
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >
                <ListItemText
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
                                <Item sx={{ flexBasis: { xs: '100%', sm: '50%', md: '20%' } }} >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('display.request_date')}
                                    </Typography>
                                    <Typography variant={"body1"}>{moment(contact.date).format('YYYY-MM-DD')}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '20%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('display.expense_type')}
                                    </Typography>
                                    <Typography variant={"body1"}>{t('form.' + contact.type_name)}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '50%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Detail
                                    </Typography>
                                    {['Private_Car_Expense', 'Taxi_Expense'].includes(contact.type_name) && (
                                        <Typography variant={"body1"}>
                                            <Typography
                                                fontSize={"14px"}
                                                variant={"h6"}
                                                color={"text.dark"}
                                                mb={.15}
                                            >
                                                โครงการ : {contact.projectName}
                                            </Typography>
                                            <Typography
                                                fontSize={"14px"}
                                                variant={"h6"}
                                                color={"text.dark"}
                                                mb={.15}
                                            >
                                                เริ่มจาก: {contact.travel_location_start} ถึง {contact.travel_location_end}
                                            </Typography>
                                            <Typography
                                                fontSize={"14px"}
                                                variant={"h6"}
                                                color={"text.dark"}
                                                mb={.15}
                                            >
                                                ระยะทาง: {contact.travel_distance} กม., ค่าเดินทาง: {contact.travel_amount} บาท, ค่าทางด่วน: {contact.travel_express_way} บาท,
                                            </Typography>
                                            <Typography
                                                fontSize={"14px"}
                                                variant={"h6"}
                                                color={"text.dark"}
                                                mb={.15}
                                            >
                                            </Typography>
                                        </Typography>
                                    )}

                                    {['Other_Expense_Welfare', 'Other_Expense_Allowance', 'Other_Expense'].includes(contact.type_name) && (
                                        <>
                                            <Typography variant={"body1"}>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    โครงการ : {contact.projectName}
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    รายการเบิกจ่าย: {contact.withdraw_list}
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    ค่าใช้จ่ายทั้งหมด: {contact.other_expense_amount} บาท
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                </Typography>
                                            </Typography>
                                        </>
                                    )}

                                    {['Mobile_Expense'].includes(contact.type_name) && (
                                        <>
                                            <Typography variant={"body1"}>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    โครงการ : {contact.projectName}
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    เบอร์โทรศัพท์: {contact.mobile}
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                    ค่าใช้จ่ายทั้งหมด: {contact.mobile_amount} บาท
                                                </Typography>
                                                <Typography
                                                    fontSize={"14px"}
                                                    variant={"h6"}
                                                    color={"text.dark"}
                                                    mb={.15}
                                                >
                                                </Typography>
                                            </Typography>
                                        </>
                                    )}
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '35%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Note
                                    </Typography>
                                    <Typography variant={"body1"}>{contact?.note ? contact.note : "-"}</Typography>
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo contact, view prop define */
export default ExpensetItem;
