import React, { useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import Div from "@jumbo/shared/Div";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { holidayServices, usersServices } from "../../../../services";

const validationSchema = yup.object({
    name: yup
        .string('Enter your Title')
        .required('Title is required'),
});
const initialValues = {
    ref_companyid: null,
    name: "",
    date: "",
    usergroup: [],
};

const HolidayForm = ({ holiday, company, onSave }) => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const [usersSelect, setUserSelect] = React.useState([]);
    const [users, setUsers] = React.useState([]);

    const onHolidaySave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const addHolidayMutation = useMutation(holidayServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Holiday has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Holiday has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateHolidayMutation = useMutation(holidayServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Department has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Department has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return holiday?.id ? updateHolidayMutation : addHolidayMutation;
    }, [holiday, updateHolidayMutation, addHolidayMutation]);

    const fetchEmployee = async (ref_companyid) => {
        const param = { ref_companyid: ref_companyid, limit: 1000, };
        const response = await usersServices.getListByParams(param);
        if (response) {
            setUsers(response.data)
        }
        return response;
    }

    useEffect(async () => {
        if (holiday) {
            const getUser = await fetchEmployee(holiday.ref_companyid);
            if (getUser.data) {
                setUserSelect(holiday.usergroup_holiday.map(x => ({
                    id: x.ref_user,
                    firstname: getUser.data.find(f => x.ref_user == f.id)?.firstname,
                    lastname: getUser.data.find(f => x.ref_user == f.id)?.lastname,
                })));
            }

        }
    }, [holiday]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={holiday?.id ? holiday : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onHolidaySave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            id="ref_companyid"
                            name="ref_companyid"
                            onChange={(event, newValue) => {
                                setFieldValue("ref_companyid", newValue.value);
                                fetchEmployee(newValue.value);
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={company ? company : []}
                            variant="outlined"
                            defaultValue={holiday ? company.find(f => f.value === holiday.ref_companyid)?.label : null}
                            renderInput={(params) => <TextField {...params} label={t('display.company')} />}
                        />
                        <JumboTextField fullWidth variant="outlined" name="name" label={t('display.holidayname')} />
                        <FormControl fullWidth >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label={t('display.holiday_date')} name="date" defaultValue={moment(values.date)} usePickerValue={moment(values.date)} format="YYYY-MM-DD" fullWidth size="small"
                                    onChange={(value) => {
                                        setFieldValue("date", value.format('YYYY-MM-DD'));
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }} >
                            <Autocomplete
                                fullWidth
                                multiple
                                disablePortal
                                disableCloseOnSelect
                                limitTags={2}
                                id="usergroup"
                                value={usersSelect}
                                options={users}
                                getOptionLabel={(item) => `${item?.id} : ${item?.firstname} ${item?.lastname}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('display.employee')} />
                                )}
                                onChange={(event, newValue) => {
                                    const value = newValue;
                                    setFieldValue("usergroup", typeof value === 'string' ? value.split(',') : value);
                                    setUserSelect(
                                        // On autofill we get a stringified value.
                                        typeof value === 'string' ? value.split(',') : value,
                                    );
                                }}
                            />
                            {/* <Select
                                labelId="refUserlist-label"
                                id="usergroup"
                                multiple
                                fullWidth                   
                                value={usersSelect}
                                onChange={(event) => {
                                    const { target: { value } } = event;
                                    setFieldValue("usergroup", typeof value === 'string' ? value.split(',') : value);
                                    setUserSelect(
                                        // On autofill we get a stringified value.
                                        typeof value === 'string' ? value.split(',') : value,
                                    );
                                }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            const getUser = users.find(f => f.id === value);
                                            return <Chip key={value} label={`${getUser?.user_no} : ${getUser?.firstname} ${getUser?.lastname}`} />
                                        }
                                        )}
                                    </Box>
                                )}
                                input={<OutlinedInput label="Tag" />}
                            >
                                {users.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        <Checkbox checked={usersSelect.indexOf(item.id) > -1} />
                                        <ListItemText primary={`${item.user_no} : ${item.firstname} ${item.lastname}`} />
                                    </MenuItem>
                                ))}
                            </Select> */}
                        </FormControl>
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )
            }
        </Formik >
    );
};

export default HolidayForm;