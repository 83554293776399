import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Button, Grid, Select, MenuItem, InputLabel, FormControl, Divider, TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import { installmentsServices, projectServices, usersServices } from "../../../../../services";
import Storage from "../../../../../common/Storage";

const menuItem = [
    {
        "title": "แสดงค่าแรงรายวัน (บาท)",
        "value": "1"
    },
    {
        "title": "แสดงค่าแรงรายวัน แบบสรุปยอดรวม",
        "value": "2"
    },
    {
        "title": "แสดงค่าแรงรายวัน แบบสรุปรวมบุคคล",
        "value": "3"
    },
    {
        "title": "แสดงค่าแรงรายวัน แบบรวมค่าใช้จ่าย",
        "value": "4"
    }
]
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SearchPanel = ({ title, onExport }) => {
    const { t } = useTranslation();
    const mounted = React.useRef(false);
    const listCompany = Storage.getCompany();
    const [typeSearch, setTypeSearch] = React.useState("");
    const [params, SetParams] = React.useState({
        ref_project: "all",
        picked_select_date: '1',
        typereport: ""
    });
    const [yearsInstallments, setYearsInstallments] = React.useState([]);
    const [installment, Setinstallment] = React.useState([{ label: t('title.please_select'), value: null }]);
    const [projects, setProjects] = React.useState([{ label: t('title.select_all'), value: 'all' }]);
    const [users, setUsers] = React.useState([]);
    const [company] = React.useState(
        listCompany ? [
            { label: 'Please Select', value: null, key: -1 },
            ...listCompany?.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            ,
        ] : []
    )

    const handleInstallments = async (e) => {
        await fetchInstallments(e);
    };

    const handleChange = (value, type) => {

        let setProject = null;
        if (type === 'ref_companyid') {
            setProject = {
                ref_project: 'all',
            }
        }

        if (type === "typereport" && value === '3') {
            setProject = {
                ...setProject,
                picked_select_date: '1',
            }
        }

        let param = {
            ...params,
            ...setProject,
            [type]: value
        };
        SetParams(param);
    };

    const handleExport = () => {
        console.log(params)
        onExport(params);
    };

    const handleReset = () => {
        SetParams({
            ref_project: "all"
        });
    };

    const fetchInstallments = async (year) => {
        const responseinstallment = await installmentsServices.getAll({
            year: year,
        });
        if (responseinstallment && responseinstallment.data) {
            Setinstallment([
                { label: 'Please Select', value: null, key: -1 },
                ...responseinstallment.data.map(({ number, start_date, end_date, id }, index) => {
                    return { label: `งวดที่ ${number} วันที่ ${moment(start_date).format('DD/MM/YYYY')} - ${moment(end_date).format('DD/MM/YYYY')}`, value: id, key: index };
                })
            ])
        }
    }

    const fetchProjects = async (ref_companyid) => {
        const response = await projectServices.getlist({
            ref_companyid: ref_companyid,
            getall: true,
        });
        if (response && response) {
            setProjects([
                { label: t('title.select_all'), value: 'all', key: -1 },
                ...response.data.map(({ name, id }, index) => {
                    return { label: `${name}`, value: id, key: index };
                })
            ])
        }
    }

    const fetchEmployee = async (ref_companyid) => {
        const response = await usersServices.getListByParams({
            ref_companyid,
            limit: 1000,
        });
        if (response && response.data) {
            setUsers(response.data);
        }
    }

    useEffect(() => {
        async function fetchYearsInstallments() {
            const response = await installmentsServices.getYears();
            if (response && response) {
                setYearsInstallments(response.data)
            }
        }
        fetchYearsInstallments();
        return () => mounted.current = false;
    }, []);

    return (
        <JumboCardQuick
            title={title}
            headerSx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -.75
                }
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={4} >
                        <Autocomplete
                            fullWidth
                            disableClearable
                            id="ref_companyid"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    fetchProjects(newValue.value);
                                    fetchEmployee(newValue.value);
                                    handleChange(newValue.value, "ref_companyid");
                                }
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.key}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            options={company ? company : []}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            variant="outlined"
                            renderInput={(params) => <TextField {...params} label="Company" name="ref_companyid" />}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} >
                        <Autocomplete
                            fullWidth
                            disableClearable
                            id="ref_project"
                            onChange={(event, newValue) => {
                                handleChange(newValue.value, "ref_project");
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            defaultValue={{ label: t('title.select_all'), value: null }}
                            options={projects ? projects : []}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            variant="outlined"
                            renderInput={(params) => <TextField {...params} label={t('form.project')} name="ref_project" />}
                            disabled={!params?.ref_companyid || params && params.typereport === '3'}
                            value={params && projects.find(f => f.value === params?.ref_project)}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            fullWidth
                            disableClearable
                            id="userid"
                            name="userid"
                            defaultValue={{ label: t('title.select_all'), value: null }}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    handleChange(newValue.value, "userid");
                                }
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            options={[
                                { label: t('title.select_all'), value: null },
                                ...users
                                    .map(({ user_no, firstname, lastname, id }) => {
                                        return { label: `${user_no} : ${firstname} ${lastname}`, value: id };
                                    }),
                            ]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            variant="outlined"
                            renderInput={(params) => <TextField {...params} label={t('display.employee')} />}
                            disabled={!params?.ref_companyid}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl>
                            <FormLabel id="option-select-date-label">เลือกประเภทการค้นหา</FormLabel>
                            <RadioGroup row aria-labelledby="option-select-date-label" name="picked_select_date" value={params.picked_select_date}
                                onChange={(event, newValue) => {
                                    handleChange(newValue, "picked_select_date");
                                }}  >
                                <FormControlLabel value="3" control={<Radio />} label="แบบรวมงวด" />
                                <FormControlLabel value="1" control={<Radio />} label="แบบงวด" />
                                <FormControlLabel value="2" control={<Radio />} label="แบบวันที่" disabled={params && params.typereport === '3'} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {(params.picked_select_date === '1') && <>
                        <Grid item xs={12} md={4.5} >
                            <Autocomplete
                                fullWidth
                                disableClearable
                                id="year"
                                name="year"
                                onChange={(event, newValue) => {
                                    handleInstallments(newValue.value);
                                    handleChange(newValue.value, "year");
                                }}
                                options={[
                                    { label: 'Please Select', value: null, key: -1 },
                                    ...yearsInstallments
                                        .map(({ year }, index) => {
                                            return { label: year, value: year };
                                        })
                                ]}
                                value={params?.year}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                variant="outlined"
                                renderInput={(params) => <TextField {...params} label="Year" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4.5} >
                            <Autocomplete
                                fullWidth
                                disableClearable
                                id="ref_installment"

                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        handleChange(newValue.value, "ref_installment");
                                    }
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.key}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                                value={installment.find(f => f.value === params?.ref_installment)?.label}
                                options={installment ? installment : []}
                                isOptionEqualToValue={(option, value) => option.label === value}
                                variant="outlined"
                                renderInput={(params) => <TextField {...params} label={t('form.installment')} name="ref_installment" />}
                                disabled={!params?.year}
                            />
                        </Grid>
                    </>}

                    {(params.picked_select_date === '2') && <Grid item xs={12} md={9}>
                        <DatePicker
                            label={t('form.date')}
                            format='YYYY-MM-DD'
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                            onChange={(value) => {
                                handleChange(value.format('YYYY-MM-DD'), "date");
                            }} />
                    </Grid>}

                    {(params.picked_select_date === '3') && <>
                        <Grid item xs={12} md={4.5} >
                            <Autocomplete
                                fullWidth
                                disableClearable
                                id="year"
                                name="year"
                                onChange={(event, newValue) => {
                                    handleInstallments(newValue.value);
                                    handleChange(newValue.value, "year");
                                }}
                                options={[
                                    { label: 'Please Select', value: null, key: -1 },
                                    ...yearsInstallments
                                        .map(({ year }, index) => {
                                            return { label: year, value: "" + year };
                                        })
                                ]}
                                getOptionLabel={(option) => option.value || ""}
                                value={params?.year}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                variant="outlined"
                                renderInput={(params) => <TextField {...params} label="Year" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4.5} >
                            <Autocomplete
                                multiple
                                limitTags={2}
                                fullWidth
                                disableClearable
                                disableCloseOnSelect
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        handleChange(newValue.map(x => x.value).join(','), "ref_installment_array");
                                    }
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                options={installment ? installment : []}
                                variant="outlined"
                                renderInput={(params) => <TextField {...params} label={t('form.installment')} />}
                                disabled={!params?.year}
                            />
                        </Grid>
                    </>}

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="type-select-label">เลือกรูปแบบรายงาน</InputLabel>
                            <Select labelId="type-select-label" id="select-type-report" label="เลือกรูปแบบรายงาน" value={params.typereport}
                                onChange={e => { handleChange(e.target.value, "typereport") }} fullWidth>
                                <MenuItem value={''}>{t('title.please_select')}</MenuItem>
                                {menuItem.map((v, i) => (<MenuItem key={i} value={v.value}>{v.title}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </LocalizationProvider>

            <Grid container spacing={1} sx={{ margin: "0 auto", mt: 2, width: "25%" }} >
                <Grid item xs={6} textAlign={'center'} >
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleExport}
                        size="medium"
                        sx={{ mb: 3 }}
                        disabled={!params.ref_companyid || params.typereport === ''}
                    >{t('display.export')}</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        type="reset"
                        onClick={handleReset}
                        variant="outlined"
                        size="medium"
                        sx={{ mb: 3 }}
                    >{t('display.reset')}</Button>
                </Grid>
            </Grid>

        </JumboCardQuick>
    );
};

export default SearchPanel;