import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import layoutConfig from "./layoutConfig";
import {useJumboLayout} from "@jumbo/components/JumboLayout/hooks";

const SoloPage = ({children}) => {
    const {setJumboLayout} = useJumboLayout();

    React.useEffect(() => {
        setJumboLayout(layoutConfig);
    }, [setJumboLayout]);

    return (
        <JumboLayout>
            {children}
        </JumboLayout>
    );
};

export default SoloPage;