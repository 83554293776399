import React from 'react';
import { Box, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import { alpha } from "@mui/material/styles";

import { APP_API_IMAGES_URL } from "../../utils/constants/paths";

const Photos = ({ images, onClose }) => {
    return (
        <Box>
            <ImageList sx={{ width: '100%', m: 0, borderRadius: 2.5 }} cols={3} rowHeight={"auto"}>
                {images.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${APP_API_IMAGES_URL}${item.physical_path}`}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
};

export default Photos;