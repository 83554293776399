import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { Card, CardHeader, CardContent, Typography, Box, Button, Chip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { fileServices } from "../../../services";
import Storage from "../../../common/Storage";

const FormUploadFile = ({
  disabled = true,
  triggerUpload,
  category = null,
  sub_category = null,
  keyRef1 = "",
  fileType = "",
  onLoad = (data) => { },
  onChange = (result) => { },
  onUploadSuccess = (result) => { },
  onError = (errors) => { }
}) => {
  const { t } = useTranslation();
  const didMountRef = useRef(false);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const handlerGetFile = async (keyRef1) => {
    if (keyRef1 === "") {
      setLoading(false);
      return;
    }

    let user = Storage.getUser()
    const param = { keyRef1: keyRef1 };

    const response = await fileServices.getFile(param);
    if (response) {
      const data = response.data.map((item) => {
        return {
          name: item.original_file_name,
          id: item.upLoadFileId,
          new: 0,
          delete: 0,
          createDate: item.createDate,
          createBy: user.username,
          url: item.fileUrl,
        };
      });
      const fileUrl = response.data.map((item) => item.fileUrl);
      setFiles([...data]);
      setSelectedFiles([fileUrl]);
      setLoading(false);

      if (data.length > 0) {
        onChange([...data]);
        onLoad(data);
      }
    }
  };

  const handlerSaveFile = async () => {
    setLoading(true);
    const user = Storage.getUser();
    let result = [];
    if (files && files.length > 0) {
      for (const file of files) {
        if (file.delete === 0 && file.new === 1) {
          const data = new FormData();
          data.append("category", `${category}`);
          data.append("sub_category", `${sub_category}`);
          data.append("key_ref1", `${keyRef1}`);
          data.append("key_ref2", "");
          data.append("key_ref3", "");
          data.append("file_type", fileType);
          data.append("file", file.file);
          data.append("uploadBy", user ? user.userName : "");

          const response = await fileServices.uploadFile(data);
          result = [...result, response];
          setLoading(false);
        } else if (file.delete === 1 && file.new === 0) {
          fileServices.deleteFile(file.id, keyRef1, null, null, fileType);
          setLoading(false);
        } else {
        }
      }
      onUploadSuccess(result.map((e) => e.data));
    }
  }

  const handlerSelectFile = (event) => {
    const target = event.target;
    if (target) {
      var newFile = {
        name: event.target.files[0].name,
        url: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
        id: uuidv4(),
        new: 1,
        delete: 0,
        createDate: moment().format('YYYY-MM-DD'),
      };
      setFiles([...files, newFile]);
    }
  }

  const deleteUploadfile = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setFiles((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    [],
  );
  const downloadFile = React.useCallback(
    (item) => () => {
      setTimeout(() => {
        fileServices.downloadFile(`${item.physical_path}`, `${item.original_file_name}`);
        // setFiles((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    [],
  );


  useEffect(() => {
    return () => {
      didMountRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      //do upload file
      handlerSaveFile();
    } else didMountRef.current = true;
  }, [triggerUpload]);

  useEffect(() => {
    // setLoading(true);
    // fetchConfig();
    if (keyRef1) {
      handlerGetFile(keyRef1);
    }
  }, [keyRef1]);

  const columns = React.useMemo(() => [
    {
      field: 'actions1',
      headerName: 'Name',
      type: 'actions',
      minWidth: 250,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Chip label={params.row.name}></Chip>}
          label={params.row.name}
          onClick={downloadFile(params.row)}
        />
      ],
    },
    { field: 'createDate', headerName: 'Create Date', type: 'string', minWidth: 150, headerAlign: 'center', },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteUploadfile(params.id)}
        />
      ],
    },
  ], [deleteUploadfile]);

  return (
    <React.Fragment>
      <Card variant="outlined">
        <CardHeader
          title={<Typography variant={"h4"} mb={0}>{t('title.uploadfile')}</Typography>}
          action={
            <Button variant="contained" component="label" startIcon={<UploadFileIcon />} size={"small"} disabled={disabled}>
              {t('title.uploadfile')}
              <input type="file" hidden onChange={handlerSelectFile} />
            </Button>
          }
        />
        <CardContent sx={{ pt: 0, mt: 0 }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <DataGrid
              columns={columns}
              rows={files}
              autoHeight={true}
            />
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FormUploadFile;