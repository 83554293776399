import React from 'react';
import Page from "@jumbo/shared/Page";
import AdminUserexpenserequest from "../pages/admin/times/Userexpenserequest";
import AdminUserovertimerequest from "../pages/admin/times/AdminUserovertimerequest";
import AdminUserleaverequest from "../pages/admin/times/AdminUserleaverequest";
import AdminExpenses from "../pages/admin/times/AdminExpenses";
import Expenses from "../pages/admin/Expenses";
import ExpensesGroupProject from "../pages/admin/ExpensesGroupProject";

const adminRoutes = [
    {
        path: "/admin/expenses",
        element: <Page component={Expenses} />
    },
    {
        path: "/admin/expenses/group-project",
        element: <Page component={ExpensesGroupProject} />
    },
    {
        path: "/admin/approve/expense-request",
        element: <Page component={AdminUserexpenserequest} />
    },
    {
        path: "/admin/approve/overtime-request",
        element: <Page component={AdminUserovertimerequest} />
    },
    {
        path: "/admin/approve/leave-request",
        element: <Page component={AdminUserleaverequest} />
    },
    {
        path: "/admin/approve/expenses",
        element: <Page component={AdminExpenses} />
    },
];

export default adminRoutes;