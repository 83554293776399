import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const socialsecurityServices = {};
socialsecurityServices.getList = async ({ queryKey }) => {
    const { queryParams } = queryKey[queryKey.length - 1];
    return await get('/socialsecurity', queryParams);
};

socialsecurityServices.getAll = async (param) => {
    return await get('/socialsecurity', param);
};

socialsecurityServices.getDetail = async (id) => {
    return get(`/socialsecurity/${id}`);
};
socialsecurityServices.add = async (params) => {
    return post(`/socialsecurity`, params);
};
socialsecurityServices.update = async (params) => {
    return put(`/socialsecurity/${+ params.id}`, params);
};

socialsecurityServices.delete = async (id) => {
    return del(`/socialsecurity/${id}`);
};
socialsecurityServices.getYears = async () => {
    return await get('/socialsecurity/get-years');
};

export default socialsecurityServices;