import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import { useJumboLayout, useJumboLayoutSidebar } from "@jumbo/components/JumboLayout/hooks";
import JumboCustomizer from "../../shared/JumboCustomizer/JumboCustomizer";
import JumboCustomizerButton from "../../shared/JumboCustomizer/JumboCustomizerButton";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import { headerTheme as theme4 } from "../../themes/header/theme4";
import { headerTheme as defaultTheme } from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";

const VerticalDefault = ({ children }) => {
    const muitheme = useTheme();
    const { setJumboLayout } = useJumboLayout();
    const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
    const { theme } = useJumboTheme();
    const appBarBgColor = React.useMemo(() => headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background, [headerTheme]);
    const { sidebarOptions } = useJumboLayoutSidebar();
    const appData = useApp();

    const isNotMobile = useMediaQuery(muitheme.breakpoints.up('lg'));

    React.useEffect(() => {
        if (!isNotMobile) {
            layoutConfig.sidebar.open = false;
        } else {
            layoutConfig.sidebar.open = true;
        }
        setJumboLayout(layoutConfig)
    }, [setJumboLayout, isNotMobile]);


    React.useEffect(() => {

        if (appBarBgColor === "#F5F7FA" && sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER) {
            setHeaderTheme({ ...theme, ...theme4 });
            appData.setAppState({
                prevHeaderBgColor: theme?.mode === "dark" ? "#222D45" : "#F5F7FA",
            });
        } else if (appData.prevHeaderBgColor && appData.prevHeaderBgColor === "#F5F7FA") {
            setHeaderTheme({ ...theme, ...defaultTheme });
        }

    }, [sidebarOptions.style, appData.setAppState, setHeaderTheme, theme]);


    return (
        <JumboLayout
            header={<Header />}
            sidebar={<Sidebar />}
            footer={<Footer />}
            headerSx={{
                height: 80,
            }}
        >
            {children}
            <JumboCustomizer />
            {/* <JumboCustomizerButton /> */}
        </JumboLayout>
    );
};

export default VerticalDefault;
