import { http } from "../common";
const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put } = http(baseUrl);
const adminExpenseDailyServices = {};

adminExpenseDailyServices.getList = async (params) => {
    return await get('/admin-expense-daily-request', { ...params });
};

adminExpenseDailyServices.getById = async (id) => {
    return await get('/admin-expense-daily-request/' + id);
};

adminExpenseDailyServices.getTypes = async () => {
    return await get('/admin-expense-daily-request/getTypes');
};

adminExpenseDailyServices.add = async (params) => {
    return await post('/admin-expense-daily-request', { ...params });
};

adminExpenseDailyServices.update = async (params) => {
    return await put('/admin-expense-daily-request/' + params.id, { ...params });
};

adminExpenseDailyServices.approve = async (params) => {
    return await post('/admin-expense-daily-request/approve', { ...params });
}
adminExpenseDailyServices.reject = async (params) => {
    return await post('/admin-expense-daily-request/reject', { ...params });
}

adminExpenseDailyServices.getsummaryGroupProject = async (params) => {
    return await get('/admin-expense-daily-request/summary-group-project', { ...params });
}
adminExpenseDailyServices.postsummaryGroupProject = async (params) => {
    return await post('/admin-expense-daily-request/bulk-summary-group-project', { ...params });
}


export default adminExpenseDailyServices;
