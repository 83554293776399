import React, { Fragment } from 'react';
import { Typography } from "@mui/material";

import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import { companyServices } from "../../../../services";

import StyledMenu from "../../../../shared/StyledMenu";

import CompanyItem from "./CompanyItem";

const CompanyList = () => {
    const rqListRef = React.useRef();
    const { showDialog, hideDialog } = useJumboDialog();
    // const { refreshLabelsList, setLabelsListRefresh, setUsersListRefresh } = useUsersApp();

    // React.useEffect(() => {
    //     if (rqListRef?.current && refreshLabelsList)
    //         rqListRef.current.refresh();

    //     if (refreshLabelsList)
    //         setLabelsListRefresh(false);
    // }, [refreshLabelsList]);

    const refreshListAndCloseDialog = React.useCallback(() => {
        hideDialog();
    }, []);

    const renderCompanyItem = React.useCallback((company) => (
        <CompanyItem company={company} onSave={refreshListAndCloseDialog} />
    ), []);

    return (
        <Fragment>
            <Typography variant={"h6"} color={"text.secondary"}
                sx={{
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    fontSize: '11px'
                }}>Company</Typography>

            <JumboRqList
                service={companyServices.getList}
                component={StyledMenu}
                componentElement={"div"}
                primaryKey={"id"}
                queryOptions={{
                    queryKey: "name",
                    dataKey: "data",
                }}
                renderItem={renderCompanyItem}
                sx={{
                    mb: 3
                }}
                ref={rqListRef}
            />
        </Fragment>
    );
};

export default CompanyList;