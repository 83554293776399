import { http } from "../common";
const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put } = http(baseUrl);
const adminExpenseServices = {};

adminExpenseServices.getList = async (params) => {
    return await get('/admin-expense-request', { ...params });
};

adminExpenseServices.getById = async (id) => {
    return await get('/admin-expense-request/' + id);
};

adminExpenseServices.getTypes = async () => {
    return await get('/admin-expense-request/getTypes');
};

adminExpenseServices.add = async (params) => {
    return await post('/admin-expense-request', { ...params });
};

adminExpenseServices.update = async (params) => {
    return await put('/admin-expense-request/' + params.id, { ...params });
};

export default adminExpenseServices;
