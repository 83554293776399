import React from 'react';
import Page from "@jumbo/shared/Page";
import Userexpenserequest from "../pages/times/Userexpenserequest/Userexpenserequest";
import Userovertimerequest from "../pages/times/Userovertimerequest/Userovertimerequest";
import Userleaverequest from "../pages/times/Userleaverequest/Userleaverequest";
import Timeadjustmentrequest from "../pages/times/Timeadjustmentrequest/Timeadjustmentrequest";
import Myworkshifts from "../pages/times/Myworkshifts/Myworkshifts";

const timesRoutes = [
    {
        path: "time/user-expense-request",
        element: <Page component={Userexpenserequest} />
    },
    {
        path: "time/user-overtime-request",
        element: <Page component={Userovertimerequest} />
    },
    {
        path: "time/user-leave-request",
        element: <Page component={Userleaverequest} />
    },
    {
        path: "time/time-adjustment-request",
        element: <Page component={Timeadjustmentrequest} />
    },
    {
        path: "time/my-work-shifts",
        element: <Page component={Myworkshifts} />
    },
];

export default timesRoutes;