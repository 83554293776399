import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import UserovertimerequestForm from './UserovertimerequestForm'
import UserovertimerequestList from './UserovertimerequestList'

import { expenseServices } from "../../../services";
import Storage from "../../../common/Storage";

const Userovertimerequest = () => {
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [searchParams] = useSearchParams();
    const [userovertime, setUserovertime] = React.useState(null);
    const user = Storage.getUser();

    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    React.useEffect(async () => {
        const getid = searchParams.get('id');
        if (getid) {
            const response = await expenseServices.getById(getid);
            if (response) {
                setUserovertime(response.data);
            }
        }
    }, [])

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t("sidebar.menuItem.userovertimerequest")} />}
            layoutOptions={layoutOptions}
        >
            {searchParams.get('mode') ? (<UserovertimerequestForm mode={searchParams.get('mode')} userovertime={userovertime} />) : (<UserovertimerequestList />)}
        </JumboContentLayout>
    );
};

export default Userovertimerequest;