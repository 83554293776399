import React from 'react';
import {config} from "../../config/main";
import JumboApp from "@jumbo/components/JumboApp";
import JumboTheme from "@jumbo/components/JumboTheme";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import BasicAuth from "../../auth-providers/BasicAuth";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider/JumboAuthProvider";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {routesForAuthenticatedOnly, routesForNotAuthenticatedOnly} from "../../routes";

const AppJumboBuilder = ({children}) => {
    return (
        <JumboApp activeLayout={config.defaultLayout}>
            <JumboTheme init={config.theme}>
                <JumboRTL>
                    <JumboAuthProvider
                        providerComponent={BasicAuth}
                        onlyAuthAccessData={{
                            routes: [...routesForAuthenticatedOnly],
                            fallbackPath: config.authSetting.onlyAuthFallbackPath
                        }}
                        onlyNotAuthAccessData={{
                            routes: [...routesForNotAuthenticatedOnly],
                            fallbackPath: config.authSetting.onlyNotAuthFallbackPath
                        }}
                    >
                        <JumboDialogProvider>
                            <JumboDialog/>
                            {children}
                        </JumboDialogProvider>
                    </JumboAuthProvider>
                </JumboRTL>
            </JumboTheme>
        </JumboApp>
    );
};

export default AppJumboBuilder;