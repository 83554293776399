import React from "react";
import Page from "@jumbo/shared/Page";
import Clockinout from "../pages/clockin-outs/Clockinout";


const clockinoutRoutes = [
    {
        path: "/clockin-out",
        element: <Page component={Clockinout} />
    }
];

export default clockinoutRoutes;
