import React from 'react';
import { useNavigate } from "react-router-dom";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";


const CompanyItem = ({ company, onSave }) => {
    const navigate = useNavigate();

    return (
        <JumboListItem
            component={MenuItem}
            componentElement={"div"}
            disableRipple
            sx={{ p: 0, my: 0 }}
            itemData={company}
        >
            <ListItemIcon sx={{ color: (company.color ? "#0f9af7" : "#0f9af7") }} onClick={() =>
                navigate(`/admin/users/company/${company.id}`)
            }>
                <LabelIcon fontSize={"small"} />
            </ListItemIcon>
            <ListItemText onClick={() =>
                navigate(`/admin/users/company/${company.id}`)
            }>
                {company.name}
            </ListItemText>
        </JumboListItem>
    );
};

export default CompanyItem;