import React from 'react';
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Div from "@jumbo/shared/Div";

const PageHeaderCustom = ({ title, subheader }) => {
    const theme = useTheme();

    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                borderRadius: '4px',
                padding: '1rem',
                backgroundColor: `${theme.palette.primary.main}`,
            }}
        >
            <Typography variant={"h2"} color={"common.white"} mb={0}>{title}</Typography>
            {subheader && (<Typography variant={"body1"} mb={1} color={"text.secondary"}>{subheader}</Typography>)}
        </Div>
    );
};

export default PageHeaderCustom;