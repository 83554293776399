import React from 'react';
import Page from "@jumbo/shared/Page";
import Installments from "../pages/setting/Installments";
import Socialsecurity from "../pages/setting/Socialsecurity";

const settingRoutes = [
    {
        path: "admin/setting/installments",
        element: <Page component={Installments} />
    },
    {
        path: "admin/setting/socialsecurity",
        element: <Page component={Socialsecurity} />
    },
];

export default settingRoutes;