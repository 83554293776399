import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
    ALERT_SHOW_ERROR,
    ALERT_SHOW_ERROR_DETAIL,
    ALERT_SHOW_INFO,
    ALERT_SHOW_SUCCESS,
    ALERT_SHOW_WARNING,
} from "../../redux/actions/actionTypes";
import {
    showErrorAction,
    showWarningAction,
    showSuccessAction,
    showInfoAction,
    showErrorDetailAction,
    hideErrorAction,
    hideInfoAction,
    hideSuccessAction,
    hideWarningAction,
    hideErrorDetailAction
} from '../../redux/actions/alertsAction';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertContainer = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { showError, showInfo, showSuccess, showWarning, showErrorDetail, success, warning, danger, dangerDetail, info, message } = props;
    const [state, setState] = useState({
        success: false,
        warning: false,
        danger: false,
        dangerDetail: false,
        info: false
    });

    window.emitter.addListener(ALERT_SHOW_ERROR, function (message) {
        showError(message);
    });

    window.emitter.addListener(ALERT_SHOW_ERROR_DETAIL, function (message) {
        showErrorDetail(message);
    });

    window.emitter.addListener(ALERT_SHOW_INFO, function (message) {
        showInfo(message);
    });

    window.emitter.addListener(ALERT_SHOW_SUCCESS, function (message) {
        showSuccess(message);
    });

    window.emitter.addListener(ALERT_SHOW_WARNING, function (message) {
        showWarning(message);
    });

    const toggleSuccess = () => {
        props.hideSuccess();
    }

    const toggleWarning = () => {
        props.hideWarning();
    }

    const toggleDanger = () => {
        props.hideError();
    }

    const toggleDangerDetail = () => {
        props.hideErrorDetail();
    }

    const toggleInfo = () => {
        props.hideInfo();
    }


    return (
        <>
            <Snackbar open={success} autoHideDuration={1000} onClose={toggleSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={toggleSuccess} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar open={danger} autoHideDuration={1000} onClose={toggleDanger} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={toggleDanger} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar open={warning} autoHideDuration={1000} onClose={toggleWarning} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={toggleWarning} severity="warning" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar open={info} autoHideDuration={1000} onClose={toggleInfo} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={toggleInfo} severity="info" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

const mapStateToProps = state => {
    const { success, warning, danger, dangerDetail, info, message } = state.AlertsRedux;

    return {
        success: success,
        warning: warning,
        danger: danger,
        dangerDetail: dangerDetail,
        info: info,
        message: message,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (message) => dispatch(showErrorAction(message)),
        showErrorDetail: (message) => dispatch(showErrorDetailAction(message)),
        showSuccess: (message) => dispatch(showSuccessAction(message)),
        showWarning: (message) => dispatch(showWarningAction(message)),
        showInfo: (message) => dispatch(showInfoAction(message)),
        hideError: () => dispatch(hideErrorAction()),
        hideInfo: () => dispatch(hideInfoAction()),
        hideSuccess: () => dispatch(hideSuccessAction()),
        hideWarning: () => dispatch(hideWarningAction()),
        hideErrorDetail: () => dispatch(hideErrorDetailAction()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertContainer); 