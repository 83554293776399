import React from "react";
import Page from "@jumbo/shared/Page";

import Userlist from "../pages/users/Userlist/Userlist";
import UsersApp from "../pages/users/UsersApp";
// import Usergroup from "../pages/users/Usergroup/Usergroup";
// import Userdetail from "../pages/users/Userdetail/Userdetail";

const userRoutes = [
    {
        path: [
            "/admin/users/:category",
            "/admin/users/:category/:id",
        ],
        element: <Page component={UsersApp} />,
    },
    // {
    //     path: "admin/user/:id",
    //     element: <Page component={Userdetail} />
    // },
    // {
    //     path: "admin/user-group",
    //     element: <Page component={Usergroup} />
    // },
];
export default userRoutes;
