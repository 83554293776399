import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import { deepOrange } from '@mui/material/colors';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useBasicAuth } from "../../../auth-providers/BasicAuth/hooks";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { logout } = useBasicAuth();
    const { authUser } = useJumboAuth();
    const { t } = useTranslation();

    const onLogout = () => {
        logout()
    };
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={authUser && authUser.coverImage}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer', bgcolor: deepOrange[500] }}
                    >{authUser && authUser?.firstname?.charAt(0) + authUser?.lastname?.charAt(0)}</Avatar>
                }
            >
                <Div
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        p: theme => theme.spacing(2.5),
                    }}
                >
                    <Avatar src={authUser && authUser.profile_pic} alt={authUser && authUser.name} sx={{ width: 60, height: 60, mb: 2 }} />
                    <Typography variant={"h5"}>{authUser && (authUser.firstname + ' ' + authUser.lastname)}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{authUser && authUser.email}</Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {/* <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('display.profile')} sx={{ my: 0 }} />
                        </ListItemButton> */}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('display.logout')} sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
