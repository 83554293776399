import React from "react";
import Page from "@jumbo/shared/Page";
import Detail from "../pages/company/Detail/Detail";
import Department from "../pages/company/Department/Department";
import Holiday from "../pages/company/Holiday/Holiday";
import Project from "../pages/company/Project";
import WorkCycle from "../pages/company/WorkCycle";

const companyRoutes = [
    {
        path: "admin/company/detail",
        element: <Page component={Detail} />
    },
    {
        path: "admin/company/department",
        element: <Page component={Department} />
    },
    {
        path: "admin/company/holiday",
        element: <Page component={Holiday} />
    },
    {
        path: "admin/company/project",
        element: <Page component={Project} />
    },
    {
        path: "admin/company/workcycle",
        element: <Page component={WorkCycle} />
    },
];
export default companyRoutes;
