import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const projectServices = {};

projectServices.getlist = async (params) => {
    return await get('/project', params);
};

projectServices.add = async (params) => {
    return post(`/project`, params);
};

projectServices.update = async (params) => {
    return put(`/project/${params.id}`, params);
};

projectServices.delete = async (id) => {
    return del(`/project/${id}`);
};

export default projectServices;