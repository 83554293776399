import FileDownload from "js-file-download";
import { http, axios } from "../common";
import { authHeader, commonHeader } from "../common/requestHeader";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const usersServices = {};
usersServices.clockInOut = async (data) => {
    return await post('/users/clockInOut', data);
};

usersServices.getCurrentUser = async () => {
    return await get("/users/profile");
};

usersServices.getList = async ({ queryKey }) => {
    const { queryParams, page, limit } = queryKey[queryKey.length - 1];
    return await get('/user', { ...queryParams, page, limit });
};

usersServices.getListByParams = async (queryParams) => {
    return await get('/user', queryParams);
};
usersServices.getListByRole = async (queryParams) => {
    return await get('/users/listByRole', queryParams);
};

usersServices.add = async (user) => {
    return await post('/user', { ...user });
};

usersServices.update = async (user) => {
    return await put('/user/' + user.id, { ...user });
};

usersServices.delete = async (user) => {
    return await del('/user/' + user.id);
};
usersServices.deleteMultiple = async () => {
    return null
};

usersServices.export = async (params, fileName) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
                ...commonHeader(),
            },
            responseType: "blob",
            method: "get",
            url: '/users/export',
        })
            .then((response) => {
                if (response) {
                    FileDownload(response.data, fileName);
                    resolve({ success: true });
                } else {
                    reject("404 (Not Found)");
                }
            })
            .catch((error) => reject(error));
    });
};

usersServices.import = (file, company, onUploadProgress) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("refcompanyid", company);
    formData.append("category", 'FILE_IMPORT');
    formData.append("subCategory", 'EMPLOYEE');

    return axios.post("/users/import", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            ...authHeader(),
            ...commonHeader(),
        },
        onUploadProgress,
    });
};

export default usersServices;