import FileDownload from "js-file-download";
import { axios, http } from "../common";
import { authHeader, commonHeader } from "../common/requestHeader";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, del } = http(baseUrl);

const fileServices = {};
fileServices.uploadFile = (data) => {
    return new Promise(function (resolve, reject) {
        axios({
            headers: {
                ...authHeader(),
                ...commonHeader(),
                Pragma: "no-cache",
                "Content-Type": "multipart/form-data",
            },
            method: "post",
            url: `${baseUrl}/uploadfile/addFile`,
            data: data,
            maxContentLength: 104857600,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

fileServices.deleteFile = (fileId, keyRef1, keyRef2, keyRef3, fileType) => {
    return del(`/uploadfile`, {
        fileId: fileId,
        keyRef1: keyRef1,
        keyRef2: keyRef2,
        keyRef3: keyRef3,
        fileType: fileType,
    });
};

fileServices.getFile = (param) => {
    return get(`/uploadfile`, { ...param });
};

fileServices.downloadFile = (url, fileName) => {
    FileDownload(`${baseUrl}/${url}`, fileName);
};

export default fileServices;