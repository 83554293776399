import React from 'react';
import * as yup from "yup";
import moment from 'moment-timezone';

import { Form, Formik } from "formik";
import { useMutation } from "react-query";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import LoadingButton from "@mui/lab/LoadingButton";

import { workShiftServices } from "../../../../services";

const validationSchema = yup.object({
    work_shift_code: yup
        .string('Enter your Code')
        .required('Code is required'),
});
const initialValues = {
    ref_companyid: null,
    work_shift_code: "",
    type: "sameday",
    value_type: null,
    work_start_time: null,
    work_end_time: null,
    break_start_time: null,
    break_end_time: null,
};

const shiftType = [
    {
        value: "sameday",
        display: "sameday"
    }
]

const WorkCycleForm = ({ item, company, t, onSave }) => {
    const Swal = useSwalWrapper();

    const onWorkCycleSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const addworkShiftMutation = useMutation(workShiftServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Shift has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Shift has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateworkShiftMutation = useMutation(workShiftServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Shift has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Shift has been updated failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return item?.id ? updateworkShiftMutation : addworkShiftMutation;
    }, [item, updateworkShiftMutation, addworkShiftMutation]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={item?.id ? item : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onWorkCycleSave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values, errors, onChange }) => (
                <Form noValidate autoComplete="off">
                    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <Autocomplete
                                fullWidth
                                id="ref_companyid"
                                name="ref_companyid"
                                onChange={(event, newValue) => {
                                    setFieldValue("ref_companyid", newValue.value);
                                }}
                                options={company ? company : []}
                                variant="outlined"
                                defaultValue={item ? item.company.name : null}
                                renderInput={(params) => <TextField {...params} label={t('display.company')} />}
                            />

                            <JumboTextField fullWidth variant="outlined" name="work_shift_code" label={t('display.shift_code')} helperText={t('display.shift_code_detail')} />

                            <FormControl sx={{ mb: 1 }}>
                                <FormLabel id="demo-radio-buttons-group-label">{t('display.shift_type')}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="sameday"
                                    name="type"
                                >
                                    {shiftType.map((v, i) => {
                                        return (<FormControlLabel key={i} value={v.value} control={<Radio />} label={t('display.' + v.display)} />);
                                    })}
                                </RadioGroup>
                            </FormControl>

                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        label={t('display.work_start_time')}
                                        name="work_start_time"
                                        defaultValue={moment(values?.work_start_time, "HH:mm:ss")}
                                        format="HH:mm:ss"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => {
                                            setFieldValue("work_start_time", newValue.format('HH:mm:00'))
                                        }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        label={t('display.work_end_time')}
                                        name="work_end_time"
                                        defaultValue={moment(values?.work_end_time, "HH:mm:ss")}
                                        format="HH:mm:ss"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => {
                                            setFieldValue("work_end_time", newValue.format('HH:mm:00'))
                                        }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        label={t('display.break_start_time')}
                                        name="break_start_time"
                                        defaultValue={moment(values?.break_start_time, "HH:mm:ss")}
                                        format="HH:mm:ss"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => {
                                            setFieldValue("break_start_time", newValue.format('HH:mm:00'))
                                        }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        label={t('display.break_end_time')}
                                        name="break_end_time"
                                        defaultValue={moment(values?.break_end_time, "HH:mm:ss")}
                                        format="HH:mm:ss"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => {
                                            setFieldValue("break_end_time", newValue.format('HH:mm:00'))
                                        }} />
                                </Grid>
                            </Grid>
                        </Div>
                    </LocalizationProvider>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )}
        </Formik>
    );
};

export default WorkCycleForm;