import React from 'react';
import Page from "@jumbo/shared/Page";
import ReportLeave from "../pages/admin/report/ReportLeave";
import ReportExpense from "../pages/admin/report/ReportExpense";
import ReportAttendance from "../pages/admin/report/ReportAttendance";
import ReportExpenseDaily from "../pages/admin/report/ReportExpenseDaily";

const reportRoutes = [
    {
        path: "admin/report/leave",
        element: <Page component={ReportLeave} />
    },
    {
        path: "admin/report/expenses",
        element: <Page component={ReportExpense} />
    },
    {
        path: "admin/report/attendance",
        element: <Page component={ReportAttendance} />
    },
    {
        path: "admin/report/expensesdaily",
        element: <Page component={ReportExpenseDaily} />
    },
];
export default reportRoutes;