import FileDownload from "js-file-download";
import { http, axios } from "../common";
import { authHeader, commonHeader } from "../common/requestHeader";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const userleaveServices = {};
userleaveServices.getList = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    return await get('/leave-request', { ...queryParams, page, limit });
};

userleaveServices.getById = async (id) => {
    return await get('/leave-request/' + id);
};

userleaveServices.add = async (params) => {
    return await post('/leave-request', { ...params });
};

userleaveServices.update = async (params) => {
    return await put('/leave-request/' + params.id, { ...params });
};

userleaveServices.approve = async (params) => {
    return await post('/leave-request/approve/' + params.id, { ...params });
};

userleaveServices.reject = async (params) => {
    return await post('/leave-request/reject/' + params.id, { ...params });
};
userleaveServices.getTypes = async () => {
    return await get('/leave-request/getTypes');
};
userleaveServices.approveGetList = async (params) => {
    const { page, pageSize, queryParams } = params;
    return await get('/leave-request', { ...queryParams, page, limit: pageSize });
};

export default userleaveServices;