import React from "react";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const YearsDropdown = ({ years, active, onYearsChange }) => {
    const handleChange = (e) => {
        onYearsChange(e.target.value);
    };
    return (
        <Select value={active} onChange={handleChange} size={"small"}>
            <MenuItem value={"all"}>All Years</MenuItem>
            {
                years && years.length > 0 && years.map((year, index) => (
                    <MenuItem key={index} value={year?.year}>
                        {year?.year}
                    </MenuItem>
                ))
            }
        </Select>
    );
};
export default YearsDropdown;
