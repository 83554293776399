
import {
    USER,
    TOKEN,
    COMPANY
} from './storageTypes'
let Storage = {};

Storage.getUser = () => {
    let result = {};
    try {
        result = JSON.parse(localStorage.getItem(USER));
    } catch {
        result = {};
    }
    return result;
};

Storage.setUser = (value) => {
    localStorage.setItem(USER, JSON.stringify(value));
};

Storage.getToken = () => {
    let result = null;
    try {
        result = localStorage.getItem(TOKEN);
    } catch {
        result = null;
    }
    return result;
};

Storage.setToken = (value) => {
    localStorage.setItem(TOKEN, value);
};

Storage.getCompany = () => {
    let result = null;
    try {
        result = JSON.parse(localStorage.getItem(COMPANY));
    } catch {
        result = null;
    }
    return result;
}

Storage.setCompany = (value) => {
    localStorage.setItem(COMPANY, JSON.stringify(value));
}

Storage.clear = () => localStorage.clear();
export default Storage;
