import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Box, Card, CardContent, Step, StepLabel, Stepper, Grid, Typography, TextField, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/History';

import Div from "@jumbo/shared/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from "@jumbo/components/JumboFormik/JumboSelectField";

import FormUploadFile from "../../FormUploadFile"
import { expenseServices, projectServices } from "../../../../services";
import Storage from "../../../../common/Storage";

const steps = [
    'CREATED',
    'DRAFTED',
    'WAIT FOR APPROVAL',
    'COMPLETED',
];
const validationSchema = yup.object({
    type_name: yup
        .string('Please Select Type')
        .required('Type is required'),
    ref_project: yup
        .string('Please Select project')
        .required('Project is required'),
});
const initialValues = {
    ref_project: null,
    date: "",
    type_name: "",
    mobile: "",
    mobile_amount: "",
    travel_location_start: "",
    travel_location_end: "",
    travel_express_way: "",
    travel_distance: "",
    withdraw_list: "",
    other_expense_amount: null,
    note: "",
};
const Modifyuserexpenserequest = ({ userexpenser, mode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const user = Storage.getUser();

    const pkref = useRef(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [triggerUploadFile, setTriggerUploadFile] = React.useState(false);
    const [types, setTypes] = React.useState([]);
    const [projects, setProjects] = React.useState([]);

    const onUserExpenserSave = (data, { setSubmitting }) => {
        if (!userexpenser) {
            data.statusCode = "waiting_approve"
        }
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };
    const addUserExpenserMutation = useMutation(expenseServices.add, {
        onSuccess: (data) => {
            pkref.current = data.data.id;
            setTriggerUploadFile((current) => !current);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User Expense has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
            navigate({
                pathname: '/time/user-expense-request',
            })
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'User Expense has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateUserExpenserMutation = useMutation(expenseServices.update, {
        onSuccess: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User Expense has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'User Expense has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const saveMutation = React.useMemo(() => {
        return userexpenser?.id ? updateUserExpenserMutation : addUserExpenserMutation;
    }, [userexpenser, updateUserExpenserMutation, addUserExpenserMutation]);

    React.useEffect(() => {
        if (userexpenser && userexpenser.statusCode === "waiting_approve") {
            setActiveStep(2)
        }
    }, [userexpenser]);

    React.useEffect(() => {
        async function fetchData() {
            const resTypes = await expenseServices.getTypes();
            if (resTypes) {
                let labelsTypes = [
                    { label: "Please Select", value: "" },
                    ...resTypes
                        .map(({ value1 }, index) => {
                            return { label: t('form.' + value1), value: value1 };
                        }),
                ];
                setTypes(labelsTypes);
            }

            const resProjects = await projectServices.getlist();
            if (resProjects) {
                let labelsproject = [
                    { label: "Please Select", value: null },
                    ...resProjects.data.data
                        .map(({ name, id }, index) => {
                            return { label: name, value: id };
                        }),
                ];
                setProjects(labelsproject);
            }
        }
        fetchData();
    }, [t]);

    return (
        <>
            <JumboCardQuick sx={{ mb: 2 }}>
                <Div sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Div>
            </JumboCardQuick>

            <JumboCardQuick
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
                title={<Typography variant={"h5"} mb={0}>{t('title.created_user_expense')}</Typography>}>
                <Formik
                    validateOnChange={true}
                    initialValues={userexpenser?.id ? userexpenser : initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onUserExpenserSave}
                    validator={() => ({})}
                >
                    {({ isSubmitting, setFieldValue, values, errors }) => (
                        <Form noValidate autoComplete="off">
                            <Div sx={{ '& .MuiTextField-root': { m: 1 } }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Card variant="outlined">
                                                <CardContent sx={{ pt: 1, }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                {mode === "view" ? (<JumboTextField fullWidth name="type_name" defaultValue={t('form.' + userexpenser?.type_name)} disabled={mode && mode === "view"} />) :
                                                                    <JumboSelectField fullWidth name="type_name" defaultValue={userexpenser?.type_name ? userexpenser?.type_name : ""} options={types} disabled={mode && mode === "view"} />
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <JumboSelectField fullWidth name="ref_project" label="Project" options={projects} disabled={mode && mode === "view"} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} sx={{ ' .MuiTextField-root': { m: 0 } }} >
                                                            <DesktopDatePicker label={t('display.request_date')} name="date" defaultValue={moment(userexpenser?.date)} format="YYYY-MM-DD" slotProps={{ textField: { fullWidth: true } }} disabled={mode && mode === "view"}
                                                                onChange={(value) => {
                                                                    setFieldValue("date", value.format('YYYY-MM-DD'));
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField fullWidth {...params} />
                                                                )} />
                                                        </Grid>

                                                        {['Private_Car_Expense', 'Taxi_Expense'].includes(values?.type_name) && (
                                                            <>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="travel_location_start" label="Start At" disabled={mode && mode === "view"} />
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="travel_location_end" label="Destination" disabled={mode && mode === "view"} />
                                                                </Grid>

                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="travel_express_way" label="Express Way" disabled={mode && mode === "view"} />
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="travel_distance" label="Distance" disabled={mode && mode === "view"} />
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="travel_amount" label="Total Expenses" disabled={mode && mode === "view"} />
                                                                </Grid>
                                                            </>
                                                        )}

                                                        {['Other_Expense_Welfare', 'Other_Expense_Allowance', 'Other_Expense'].includes(values?.type_name) && (
                                                            <>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="withdraw_list" label="Withdrawal detail" multiline minRows={3} maxRows={20} />
                                                                </Grid>

                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="other_expense_amount" label="Total Expenses" />
                                                                </Grid>
                                                            </>
                                                        )}

                                                        {['Mobile_Expense'].includes(values?.type_name) && (
                                                            <>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="mobile" label={t('form.mobile')} disabled={mode && mode === "view"} />
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sx={{
                                                                    ' .MuiTextField-root': { m: 0 },
                                                                }} >
                                                                    <JumboTextField fullWidth name="mobile_amount" label={t('form.mobile_amount')} disabled={mode && mode === "view"} />
                                                                </Grid>
                                                            </>
                                                        )}

                                                        <Grid item xs={12} md={12} sx={{ ' .MuiTextField-root': { m: 0 } }} >
                                                            <JumboTextField fullWidth multiline name="note" label={t('form.note')} minRows={3} maxRows={20} disabled={mode && mode === "view"} />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormUploadFile
                                            disabled={mode && mode === "view"}
                                            triggerUpload={triggerUploadFile}
                                            keyRef1={userexpenser ? userexpenser.id : pkref.current}
                                            // fileType={fileTypes.ATTACH_FILE}
                                            onChange={(res) => {
                                                // handleChange(res);
                                            }}
                                            onError={(error) => {
                                                // setHasError(error);
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box textAlign='center' sx={{ mt: 1 }} >
                                    {(userexpenser && userexpenser.statusCode === "waiting_approve" && !['employee'].includes(user.role)) && (
                                        <LoadingButton
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mr: 1 }}
                                            endIcon={<SendIcon />}
                                            loadingPosition="end"
                                            loading={isSubmitting || saveMutation.isLoading}
                                        >{t('display.approve')}</LoadingButton>
                                    )}

                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        sx={{ mr: 1 }}
                                        endIcon={<SendIcon />}
                                        loadingPosition="end"
                                        loading={isSubmitting || saveMutation.isLoading}
                                        disabled={(mode && mode === "view") || (userexpenser && !['waiting_approve'].includes(userexpenser.statusCode))}
                                    >{t('display.submit')}</LoadingButton>

                                    <Button
                                        sx={{ mr: 1 }}
                                        variant='outlined'
                                        startIcon={<HistoryIcon />}
                                        onClick={() => navigate({
                                            pathname: "/time/user-expense-request",
                                        })}>
                                        {t('display.cancel')}
                                    </Button>
                                </Box>
                            </Div>
                        </Form>
                    )}

                </Formik>
            </JumboCardQuick>
        </>
    );
};

export default Modifyuserexpenserequest;