import React from 'react';

const ModalComponent = (props) => {
  const { type } = props.item;

  const onClose = () => {
    if (props.item.onClose) {
      props.item.onClose();
      props.onClose(props.item);
    }
  }

  const onConfirm = (data) => {
    if (props.item.onConfirm) {
      props.item.onConfirm(data);
      props.onClose(props.item);
    }
  }


  if (type === "FORM") {
    const { headerText, contents, buttonOKText, buttonCancelText, data, orderType } = props.item;
    const contentsRender = React.cloneElement(contents, {
      onConfirm: (data) => onConfirm(data),
      onClose: () => onClose(),
      headerText: headerText || "",
      isOpen: true,
      buttonOKText: buttonOKText || "submit",
      buttonCancelText: buttonCancelText || "cancel",
      data: data, // || {}
      orderType: orderType, // Custom
    });

    return <div>{contentsRender}</div>;
  }

  return (<>
  </>);
}

export { ModalComponent };
