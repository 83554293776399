import React, { useEffect } from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Paper, Stack, Fab, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, TablePagination, IconButton } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import NoDataPlaceHolder from '../../../components/NoDataPlaceHolder';
import CompanyDropdown from './CompanyDropdown'
import ProjectForm from './ProjectForm'

import { Storage } from "../../../common";
import { projectServices, usersServices } from "../../../services";

const Project = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const listCompany = Storage.getCompany();
    const { showDialog, hideDialog } = useJumboDialog();
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [project, setProject] = React.useState([]);
    const [company] = React.useState(
        listCompany && listCompany
            .map(({ name, id }) => {
                return { label: name, value: id };
            }),
    );
    const [activeCompany, setActiveCompany] = React.useState("all");

    const columns = [
        {
            id: 'company',
            label: t('display.company'),
            minWidth: 120,
            align: 'center',
        },
        {
            id: 'name',
            label: t('display.project_name'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'description',
            label: t('form.description'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'owner',
            label: t('display.owner'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'totalUsers',
            label: t('display.user_total'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'operations',
            label: t('display.operations'),
            minWidth: 100,
            align: 'center',
        },
    ];


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const hideDialogAndRefresh = React.useCallback(() => {
        hideDialog();
        setRefreshKey(oldKey => oldKey + 1);
    }, [hideDialog]);

    const showAddProjectDialog = React.useCallback(() => {
        showDialog({
            title: `${t('display.add')}${t('display.project')}`,
            content: <ProjectForm company={company} onSave={hideDialogAndRefresh} t={t} />
        });
    }, [hideDialogAndRefresh, showDialog, company]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: `${t('display.edit')}${t('display.project')}`,
                    content: <ProjectForm project={menuItem.item} company={company} onSave={hideDialogAndRefresh} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this project?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteContactMutation.mutate(menuItem.item.id),
                    onNo: hideDialog
                });
                break;
            default:
                throw Error("Invalid action type to update Content Layout in ContentLayoutProvider");
        }
    };

    const deleteContactMutation = useMutation(projectServices.delete, {
        onSuccess: () => {
            hideDialog();
            setRefreshKey(oldKey => oldKey + 1);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Project has been Delete successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Project has been Delete failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    useEffect(() => {
        async function fetchData() {
            let param = { ref_company: activeCompany, page: page }
            const response = await projectServices.getlist(param);
            if (response) {
                setProject(response.data.data)
                setTotal(response.data.total);
            }
        }
        fetchData();
    }, [refreshKey, activeCompany, page]);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={t('display.project')}
                action={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <CompanyDropdown companies={company} activeCampany={activeCompany} onCampanyChange={setActiveCompany} />
                        <Fab size="small" color="primary" aria-label="add" onClick={showAddProjectDialog}>
                            <AddRoundedIcon />
                        </Fab>
                    </Stack>
                }
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
                wrapperSx={{ p: 0 }}
            >
                {
                    project && project.length <= 0 && (<NoDataPlaceHolder></NoDataPlaceHolder>)
                }
                {
                    project && project.length > 0 && (
                        <>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {project.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column, index) => {
                                                        let value = "";
                                                        if (column.id === 'company') {
                                                            value = row?.company?.name;
                                                        } else if (column.id === 'owner') {
                                                            value = row?.owner?.user_no;
                                                        } else {
                                                            value = row[column.id];
                                                        }

                                                        return (
                                                            <TableCell key={index} align={column.align} >
                                                                {(column.id === 'operations') ?
                                                                    <>
                                                                        <IconButton size='small' onClick={() => { handleItemAction({ title: t('display.edit'), action: "edit", item: row }); }} >
                                                                            <EditRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                        <IconButton size='small' color="error" onClick={() => { handleItemAction({ title: t('display.delete'), action: "delete", item: row }); }} >
                                                                            <DeleteRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </>
                                                                    : <>
                                                                        {column.id === 'totalUsers' ? <>{row?.user_group?.length}</> :
                                                                            column.format && typeof value === 'number'
                                                                                ? column.format(value)
                                                                                : value}
                                                                    </>}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>)
                }

            </JumboCardQuick>
        </React.Fragment>
    );
};

export default Project;