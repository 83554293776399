import React from 'react';

import { alpha, ListItem, ListItemAvatar, ListItemText, Typography, Avatar, CardHeader, Divider, Chip, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";

import Div from "@jumbo/shared/Div";

const UserDetail = ({ user, onClose }) => {
    return (
        <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={user?.userNo}
                avatar={<Avatar src={user?.coverImage} />}
                action={
                    <IconButton onClick={onClose}><CloseIcon /></IconButton>
                }
            />

            <List disablePadding>
                <ListItem sx={{ px: 3 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <CorporateFareIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Company</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{user?.company?.name}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"} />
                <ListItem sx={{ px: 3 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <MailOutlineIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Email</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{user?.email}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"} />
                <ListItem sx={{ px: 3 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Position</Typography>}
                        secondary={<Typography component={"div"}>
                            <Chip color="primary" label="Employee" />
                        </Typography>
                        }
                    />
                </ListItem>
                <Divider component={"li"} />
                <ListItem sx={{ px: 3 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <AssignmentIndRoundedIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Manager</Typography>}
                        secondary={user?.userGroup ? <Typography component={"div"}>
                            <Chip color="info" label={user?.userGroup ? user?.userGroup?.managers?.userNo : null} />
                        </Typography> : <>-</>
                        }
                    />
                </ListItem>
                <Divider component={"li"} />
            </List>
        </Div>
    );
};

export default UserDetail;