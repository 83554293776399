import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ContactsIcon from "@mui/icons-material/Contacts";
import StarsIcon from "@mui/icons-material/Stars";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";

import { companyServices } from "../../../../services";

const CompanyDropdown = () => {
    const navigate = useNavigate();
    const [company, setCompany] = React.useState("");
    const [companys, setCompanys] = React.useState([]);

    useEffect(async () => {
        const response = await companyServices.getAll();
        if (response) {
            setCompanys(response.data);
        }
    }, [])


    return (
        <FormControl sx={{ width: 120 }} size={"small"}>
            <InputLabel>Company</InputLabel>
            <Select
                value={company}
                label="Company"
                onChange={(event) => setCompany(event.target.value)}
            >
                <MenuItem value="">
                    <em>Select Company</em>
                </MenuItem>
                {
                    companys.map((company, index) => (
                        <MenuItem
                            key={index}
                            value={company}
                            onClick={() => navigate(`/admin/users/company/${company.id}`)}
                        >
                            {company.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default CompanyDropdown;