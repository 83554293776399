import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, LinearProgress, TextField, Stack, MenuItem } from "@mui/material";

import Div from "@jumbo/shared/Div";

import { Storage } from "../../../../common";
import { usersServices, companyServices } from '../../../../services'

const ImportUser = () => {
    const listCompany = Storage.getCompany();
    const [company, setCompany] = useState(null);
    const [companys, setCompanys] = React.useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [success, setSuccess] = useState({
        isSuccess: false,
    });
    const [progress, setProgress] = useState(0);

    const handleFile = (event) => {
        setSelectedFiles(event.target.files);
    }

    const onUploadFile = () => {
        const currentFile = selectedFiles[0];
        setCurrentFile(currentFile);

        usersServices.import(currentFile, company, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total))
        }).then((response) => {
            if (response.status === 200) {
                const res = response.data.data;
                console.log(res);
                setSuccess({
                    isSuccess: true,
                    total: res.total,
                    totalError: res.totalError,
                    totalSuccess: res.totalSuccess,
                });
            }
            setCurrentFile(null);
            setSelectedFiles(null);
        }).catch((error) => {
            console.error(error);
        });

    }

    useEffect(() => {
        setCompanys(listCompany);
    }, []); // Or [] if effect doesn't need props or state

    useEffect(() => {
    }, [selectedFiles]);

    return (
        <>
            <Div sx={{ minHeight: '100%' }}>
                <TextField
                    id="select-company"
                    select
                    label="Company"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    sx={{ width: '100%', color: '#000', mb: 2 }}
                    variant="standard"
                >
                    {companys.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                            {company.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Div>
            {currentFile && (
                <Box className="mb25" display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <LinearWithValueLabel value={progress} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                    </Box>
                </Box>)
            }

            <Div sx={{ minHeight: '100%' }}>
                <TextField
                    id="standard-start-adornment"
                    sx={{ width: '100%', color: '#000' }}
                    variant="standard"
                    disabled
                    value={selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                />
            </Div>
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    my: 1,
                }}
            >
                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={handleFile} />
                    <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Choose Files
                    </Button>
                </label>

                <Button
                    className="btn-upload"
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={!selectedFiles || !company}
                    onClick={onUploadFile}>
                    Upload
                </Button>
            </Div>

            {success.isSuccess && (
                <>
                    <hr color='#eee' />
                    <Stack direction={"row"} spacing={1} sx={{ m: { md: '0 auto' }, mt: 2, float: 'right' }}>
                        <Button size={"small"} variant="contained" color="info" >
                            Total {success.total}
                        </Button>
                        <Button size={"small"} variant="outlined" color="success"  >
                            Success {success.totalSuccess}
                        </Button>
                        <Button size={"small"} variant="outlined" color="error"  >
                            Failed {success.totalError}
                        </Button>
                    </Stack>
                </>
            )}
        </>
    );
};

const LinearWithValueLabel = (props) => {
    return (
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
            <Div sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Div>
        </Div>
    );

};
export default ImportUser;