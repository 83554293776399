import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const client = axios.create({
    baseURL: baseUrl,
});

client.interceptors.response.use(
    (res) => {
        //console.log("axios success", res);
        return res;
    },
    (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            window.emitter.emit('on-error', { code: 401, message: error.response });

            // console.log('axios error with 401:', error.response);
            return Promise.reject(error);
        } else if (error.response && error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            window.emitter.emit('on-error', { code: 403, message: error.response });

            //console.log('axios error with 403:', error.response);
            return Promise.reject(error);
        } else if (error.response && error.response.status === 404) {
            let errorData = {
                error_code: error.response.status,
                success: false,
                data: "API 404 (Not Found)"
            };
            return Promise.reject(errorData);
        } else {
            let errorData = {
                error_code: error?.response?.status,
                success: false,
                data: error?.response?.data
            };
            return Promise.reject(errorData);
        }

    }
);

export default client;
