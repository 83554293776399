import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    Typography,
    Button,
    Badge,
    IconButton,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import { DISPLAY_FORMAT_DATE } from "../../../../utils/constants/date";
import PageHeaderCustom from "../../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import CancelForm from './CancelForm'
import Photos from '../../../../components/Photos'
import { expenseServices } from "../../../../services";

const useStyles = makeStyles({
    grid: {
        display: "flex",
        flexDirection: "column-reverse"
    }
});
const AdminUserexpenserequest = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { theme } = useJumboTheme();
    const params = useParams();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 5,
        queryParams: { category: params.category, id: params.id, approve: true },
    });
    const columns = [
        {
            field: 'date',
            headerName: t('display.request_date'),
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return params.row.created_at ? moment(params.row.created_at).format(DISPLAY_FORMAT_DATE) : ""
            },
        },
        {
            field: 'user_no',
            headerName: t('display.employeeno'),
            sortable: false,
            width: 120,
            renderCell: (params) => {
                return params.row.user.user_no
            },
        },
        {
            field: 'fullname',
            headerName: t('display.employeename'),
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return "" + params.row.user.firstname + " " + params.row.user.lastname
            },
        },
        {
            field: 'type_name',
            headerName: t('display.expense_type'),
            width: 190,
            renderCell: (params) => {
                return t('form.' + params.row.type_name)
            },
        },
        {
            field: 'detail',
            headerName: t('display.detail'),
            width: 400,
            renderCell: (params) => {
                return (<>
                    {['Private_Car_Expense', 'Taxi_Expense'].includes(params.row.type_name) && (
                        <Typography variant={"body1"}>
                            <Typography
                                fontSize={"14px"}
                                variant={"h6"}
                                color={"text.dark"}
                                mb={.15}
                            >
                                โครงการ : {params.row.projectName}
                            </Typography>
                            <Typography
                                fontSize={"14px"}
                                variant={"h6"}
                                color={"text.dark"}
                                mb={.15}
                            >
                                เริ่มจาก: {params.row.travel_location_start} ถึง {params.row.travel_location_end}
                            </Typography>
                            <Typography
                                fontSize={"14px"}
                                variant={"h6"}
                                color={"text.dark"}
                                mb={.15}
                            >
                                ระยะทาง: {params.row.travel_distance} กม., ค่าเดินทาง: {params.row.travel_amount} บาท, ค่าทางด่วน: {params.row.travel_express_way} บาท,
                            </Typography>
                            <Typography
                                fontSize={"14px"}
                                variant={"h6"}
                                color={"text.dark"}
                                mb={.15}
                            >
                            </Typography>
                        </Typography>
                    )}

                    {['Other_Expense_Welfare', 'Other_Expense_Allowance', 'Other_Expense'].includes(params.row.type_name) && (
                        <>
                            <Typography variant={"body1"}>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    โครงการ : {params.row.projectName}
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    รายการเบิกจ่าย: {params.row.withdraw_list}
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    ค่าใช้จ่ายทั้งหมด: {params.row.other_expense_amount} บาท
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                </Typography>
                            </Typography>
                        </>
                    )}

                    {['Mobile_Expense'].includes(params.row.type_name) && (
                        <>
                            <Typography variant={"body1"}>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    โครงการ : {params.row.projectName}
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    เบอร์โทรศัพท์: {params.row.mobile}
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                    ค่าใช้จ่ายทั้งหมด: {params.row.mobile_amount} บาท
                                </Typography>
                                <Typography
                                    fontSize={"14px"}
                                    variant={"h6"}
                                    color={"text.dark"}
                                    mb={.15}
                                >
                                </Typography>
                            </Typography>
                        </>
                    )}
                </>);
            },
        },
        {
            field: 'note',
            headerName: t('form.note'),
            sortable: false,
            width: 200
        },
        {
            field: 'approve_by',
            headerName: t('form.confirm_by'),
            sortable: false,
            width: 120
        },
        {
            field: 'images',
            headerName: "",
            sortable: false,
            width: 60,
            renderCell: (params) => {
                return (
                    <IconButton aria-label="Library Phone" onClick={(e) => handlePopupImages(e, params.row)} disabled={params?.row.images.length === 0}>
                        <Badge badgeContent={params?.row.images.length} color="primary">
                            <PhotoLibraryRoundedIcon />
                        </Badge>
                    </IconButton>);
            }
        },
        {
            field: 'operation',
            headerName: "",
            sortable: false,
            width: 230,
            renderCell: (params) => {
                return (<>
                    {(params.row.statusCode === "waiting_approve") && (
                        <div>
                            <Button variant="outlined" onClick={(e) => handleApprove(e, params.row)} color="success" aria-label="Approve" startIcon={<CheckCircleOutlineRoundedIcon />} size='small'>
                                {t('display.approve')}
                            </Button>

                            <Button variant="outlined" onClick={(e) => handleReject(e, params.row)} color="error" aria-label="Reject" startIcon={<HighlightOffRoundedIcon />} size='small' sx={{ ml: 1 }}>
                                {t('display.reject')}
                            </Button>
                        </div>
                    )}
                    {(params.row.statusCode === "reject") && (
                        <div>
                            <Typography fontSize={"14px"} variant={"h6"}>
                                <span>{t('display.reject_at')} : {params.row.approve_at} </span>
                            </Typography>
                            <Typography fontSize={"12px"} variant={"h6"}>
                                <span>{params.row.reason}</span>
                            </Typography>
                        </div>
                    )}
                    {(params.row.statusCode === "approve") && (
                        <div>
                            <Typography fontSize={"14px"} variant={"h6"}>
                                <span>{t('display.approve_at')} : {params.row.approve_at} </span>
                            </Typography>
                        </div>
                    )}
                </>)
            },
        }
    ];

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    const handleRefresh = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }
    const handleReject = (e, item) => {
        e.stopPropagation();
        showDialog({
            title: t("display.reject_request"),
            content: <CancelForm item={item} onSave={handleRejectSave} onCancel={hideDialog} />
        });
    }
    const handleApprove = (e, item) => {
        e.stopPropagation();
        showDialog({
            variant: 'confirm',
            title: t("display.approve_request"),
            content: t("detail.approve_expense_request"),
            onYes: () => approveMutation.mutate(item),
            onNo: hideDialog
        });
    }
    const approveMutation = useMutation(expenseServices.approve, {
        onSuccess: () => {
            handleRefresh();
            hideDialog();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Approve successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Approve failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });
    const handleRejectSave = () => {
        handleRefresh();
        hideDialog();
    }
    const handlePopupImages = (e, item) => {
        e.stopPropagation();
        showDialog({
            title: t("display.photos"),
            content: <Photos images={item?.images} onClose={hideDialog} />
        });
    }
    const handleChangeSizePage = (newPageSize) => {
        setPageState(old => ({ ...old, pageSize: newPageSize }));
    }

    useEffect(() => {
        const fetchData = async () => {
            setPageState(old => ({ ...old, isLoading: true }))
            const response = await expenseServices.approveGetList(pageState);
            if (response) {
                setPageState(old => ({ ...old, isLoading: false, data: response.data, total: response.total }))
            }
        }
        fetchData()
    }, [pageState.page, pageState.pageSize, refreshKey])

    return (
        <>
            <JumboContentLayout
                header={<PageHeaderCustom title={t('title.approve_expense_request')} />}
                layoutOptions={layoutOptions}
            >
                <JumboCardQuick
                    sx={{
                        '& .MuiCardHeader-action': {
                            my: '-5px'
                        },
                        '.MuiCardContent-root': {
                            p: 0
                        },
                    }}
                >
                    <DataGrid
                        disableSelectionOnClick
                        className={classes.grid}
                        sortModel={[
                            {
                                field: "id",
                                sort: "asc"
                            }
                        ]}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: pageState.pageSize, page: pageState.page - 1 },
                            },
                        }}
                        getRowHeight={({ id, densityFactor }) => {
                            return 100 * 0.8;
                        }}
                        columns={columns}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page}
                        pageSize={pageState.pageSize}
                        paginationMode="server"
                        onPaginationModelChange={(newPage) => {
                            console.log(newPage);
                            setPageState(old => ({ ...old, page: newPage.page + 1 }))
                        }}
                        onPageSizeChange={handleChangeSizePage}
                        sx={{
                            "& .MuiDataGrid-row": {
                                borderTop: "1px solid lightgray",
                                backgroundColor: "white",
                            },
                        }}
                    />
                </JumboCardQuick>
            </JumboContentLayout>
        </>
    );
};
export default AdminUserexpenserequest;