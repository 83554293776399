import baseAxios from "axios";
import { LONGDO_API_KEY } from "../utils/constants/paths";

const axios = baseAxios.create({
    baseURL: `https://api.longdo.com`
});

const longdoMapServices = {};

longdoMapServices.getAddress = (params) => {
    return new Promise(function (resolve, reject) {
        axios({
            method: "get",
            url: `/POIService/json/search`,
            params: {
                lon: params.lng,
                lat: params.lat,
                key: LONGDO_API_KEY,
                span: '1000'
            }
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });

};

export default longdoMapServices;