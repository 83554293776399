import React from 'react';
import { connect } from 'react-redux'

import { ModalComponent } from './ModalComponent'
import { openModalAction, closeModalAction } from '../../redux/actions/ModalAction';

const ModalContainer = (props) => {
    const modalsRender = props.modals.map((item, i) =>
        <ModalComponent
            item={item}
            key={i}
            zIndex={i}
            onClose={(item) => props.closeModal(item)}

        />)

    return (
        <>
            {modalsRender}
        </>
    );
};

const mapStateToProps = state => {
    const { modalRedux } = state
    return {
        modals: modalRedux.modals
    }
}
const mapDispatchToProps = dispatch => {
    return {
        openModal: (modal) => dispatch(openModalAction(modal)),
        closeModal: (modal) => dispatch(closeModalAction(modal)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalContainer); 