import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";

import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { departmentServices } from "../../../../services";

const validationSchema = yup.object({
    name: yup
        .string('Enter your Department Name')
        .required('Department Name is required'),
});
const initialValues = {
    ref_companyid: null,
    code: "",
    name: ""
};
const DepartmentForm = ({ department, company, onSave }) => {
    const Swal = useSwalWrapper();

    const onDepartmentSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const addDepartmentMutation = useMutation(departmentServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Department has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Department has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateDepartmentMutation = useMutation(departmentServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Department has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Department has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const saveMutation = React.useMemo(() => {
        return department?.id ? updateDepartmentMutation : addDepartmentMutation;
    }, [department, updateDepartmentMutation, addDepartmentMutation]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={department?.id ? department : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onDepartmentSave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            id="ref_companyid"
                            name="ref_companyid"
                            onChange={(event, newValue) => {
                                setFieldValue("ref_companyid", newValue.value);
                            }}
                            options={company ? company : []}
                            size="small"
                            variant="outlined"
                            defaultValue={department ? department.company.name : null}
                            renderInput={(params) => <TextField {...params} label="Company" />}
                        />
                        <JumboTextField fullWidth size="small" variant="outlined" name="code" label="Department code" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Department name" />
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMutation.isLoading}
                    >Save</LoadingButton>
                </Form>
            )}
        </Formik >
    );
};

export default DepartmentForm;