import React, { Fragment, useEffect } from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Paper, Stack, Fab, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton } from "@mui/material";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { socialsecurityServices } from "../../../services";
import Storage from "../../../common/Storage";
import NoDataPlaceHolder from '../../../components/NoDataPlaceHolder';

import CompanyDropdown from './CompanyDropdown'
import SocialsecurityForm from './SocialsecurityForm'

const columns = [
    {
        id: 'companyName',
        label: 'บริษัท',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'years',
        label: 'ปี',
        minWidth: 100,
        align: 'center',
        format: "YYYY"
    },
    {
        id: 'month',
        label: 'เดือน',
        minWidth: 100,
        align: 'center',
        format: "MM"
    },
    {
        id: 'totalcal',
        label: 'ค่าประกันสังคม',
        minWidth: 100,
        align: 'center'
    },
    {
        id: 'operations',
        label: 'Operations',
        minWidth: 100,
        align: 'center',
    },
];

const Socialsecurity = () => {
    const { t } = useTranslation();
    const Swal = useSwalWrapper();
    const listCompany = Storage.getCompany();
    const { showDialog, hideDialog } = useJumboDialog();
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [socialSecurityList, setSocialSecurityList] = React.useState([]);
    const [active, setActive] = React.useState("all");
    const [company] = React.useState(
        listCompany ? [
            ...listCompany?.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
        ] : []
    )

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: 'Update Project',
                    content: <SocialsecurityForm model={menuItem.item} onSave={hideDialogAndRefresh} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this project?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteMutation.mutate(menuItem.item.id),
                    onNo: hideDialog
                });
                break;
            default:
                throw Error("Invalid action type to update Content Layout in ContentLayoutProvider");
        }
    };

    const hideDialogAndRefresh = React.useCallback(() => {
        hideDialog();
        setRefreshKey(oldKey => oldKey + 1);
    }, [hideDialog]);

    const showAddInstallmentsDialog = React.useCallback(() => {
        showDialog({
            title: "เพิ่มค่าประกันสังคม",
            content: <SocialsecurityForm onSave={hideDialogAndRefresh} />
        });
    }, [hideDialogAndRefresh, showDialog]);

    const deleteMutation = useMutation(socialsecurityServices.delete, {
        onSuccess: () => {
            hideDialog();
            setRefreshKey(oldKey => oldKey + 1);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Social Security has been Delete successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Social Security has been Delete failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    useEffect(() => {
        async function fetchData() {
            let param = { ref_company: active }
            const response = await socialsecurityServices.getAll(param);
            if (response) {
                setSocialSecurityList(response.data)
            }
        }
        fetchData();
    }, [refreshKey, active]);

    return (
        <Fragment>
            <JumboCardQuick
                title={t('sidebar.menuItem.socialsecurity')}
                action={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <CompanyDropdown company={company} active={active} onChange={setActive} />
                        <Fab size="small" color="primary" aria-label="add" onClick={showAddInstallmentsDialog}>
                            <AddRoundedIcon />
                        </Fab>
                    </Stack>
                }
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
                wrapperSx={{ p: 0 }}
            >
                {
                    socialSecurityList && socialSecurityList.length <= 0 && (<NoDataPlaceHolder></NoDataPlaceHolder>)
                }

                {
                    socialSecurityList && socialSecurityList.length > 0 && (
                        <>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {socialSecurityList.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column, index) => {
                                                        let value = row[column.id];

                                                        if (column?.format && value) {
                                                            value = moment(value).format(column.format);
                                                        }

                                                        if (row.is_default && column.id === 'years') {
                                                            value = "ค่าตั้งต้น"
                                                        }

                                                        return (
                                                            <TableCell key={index} align={column.align} >
                                                                {(column.id === 'operations') ?
                                                                    <>
                                                                        <IconButton size='small' onClick={() => { handleItemAction({ title: "Edit", action: "edit", item: row }); }} >
                                                                            <EditRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                        <IconButton size='small' color="error" onClick={() => { handleItemAction({ title: "Delete", action: "delete", item: row }); }} >
                                                                            <DeleteRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </>
                                                                    : <>
                                                                        {value}
                                                                    </>}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>)
                }
            </JumboCardQuick>
        </Fragment>
    );
};

export default Socialsecurity;