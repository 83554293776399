import React, { useMemo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";
import SearchPanel from './SearchPanel'

import { exportReportServices } from "../../../../services";

const ReportAttendance = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const { theme } = useJumboTheme();

    const handleExport = async (params) => {
        try {
            const filename = "Attendance_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            await exportReportServices.attendance(params, filename);
        } catch (error) {
            console.error(error, "handleExport Attendance")
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Export Report Attendance failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    return (
        <JumboContentLayout
            header={<PageHeaderCustom title={t("display.report_attendance")} />}
            layoutOptions={layoutOptions}
        >
            <SearchPanel
                title={t('display.report_attendance_detail')}
                onExport={handleExport}
            />

        </JumboContentLayout>
    );
};

export default ReportAttendance;