import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from "../actions/actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    modals: [],
};

function ModalRedux(state = initialState, action) {

    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                modals: state.modals.concat({ ...action.payload, id: uuidv4() }),
            };
        case CLOSE_MODAL:
            return {
                ...state,
                modals: state.modals.filter(item => item.id !== action.payload.id),
            };
        default:
            return state;
    }
}

export default ModalRedux;
