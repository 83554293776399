import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";

import { userleaveServices } from "../../../../services";

import UserleaverequestItem from "../UserleaverequestItem"

const UserleaverequestList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "data",
        queryParams: { category: params.category, id: params.id },
        countKey: "total",
        dataKey: "data",
    });

    const renderUserleave = React.useCallback((Item) => {
        return (<UserleaverequestItem userleave={Item} t={t} />)
    }, []);


    return (
        <JumboCardQuick
            title={t('display.user_leave_request_list')}
            action={<Button variant={"contained"} startIcon={<AddRoundedIcon />} size={"small"} onClick={() => {
                navigate({
                    pathname: '/time/user-leave-request',
                    search: '?mode=create'
                })
            }}>Add</Button>}
            sx={{
                '& .MuiCardHeader-action': {
                    my: '-5px'
                },
                '.MuiCardContent-root': {
                    p: 0
                },
            }}>

            <JumboRqList
                queryOptions={queryOptions}
                primaryKey={"id"}
                service={userleaveServices.getList}
                renderItem={renderUserleave}
                componentElement={"div"}
                itemsPerPage={5}
                itemsPerPageOptions={[5, 10, 15]}
                wrapperSx={{
                    pb: 1,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar hideItemsPerPage={true} >
                    </JumboListToolbar>
                }
            />
        </JumboCardQuick>
    );
};

export default UserleaverequestList;