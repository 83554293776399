import React from "react";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import routes from "../../routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const AppRoutes = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const appRoutes = useJumboRoutes(routes);
    const { isLoading } = useJumboAuth();

    return (
        <React.Fragment>
            {
                isLoading ? t('display.loading') : appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;
