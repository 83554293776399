import {
    ALERT_SHOW_ERROR,
    ALERT_SHOW_INFO,
    ALERT_SHOW_SUCCESS,
    ALERT_SHOW_WARNING,
    ALERT_HIDE_ERROR,
    ALERT_HIDE_INFO,
    ALERT_HIDE_SUCCESS,
    ALERT_HIDE_WARNING,
    ALERT_SHOW_ERROR_DETAIL,
    ALERT_HIDE_ERROR_DETAIL
} from "./actionTypes";

export function showErrorAction(message) {
    return function (dispatch) {
        dispatch({
            type: ALERT_SHOW_ERROR,
            payload: message
        })
    }
}

export function showErrorDetailAction(message) {
    return function (dispatch) {
        dispatch({
            type: ALERT_SHOW_ERROR_DETAIL,
            payload: message
        })
    }
}

export function showInfoAction(message) {
    return function (dispatch) {
        dispatch({
            type: ALERT_SHOW_INFO,
            payload: message
        })
    }
}

export function showSuccessAction(message) {
    return function (dispatch) {
        dispatch({
            type: ALERT_SHOW_SUCCESS,
            payload: message
        })
    }
}

export function showWarningAction(message) {
    return function (dispatch) {
        dispatch({
            type: ALERT_SHOW_WARNING,
            payload: message
        })
    }
}

export function hideErrorAction() {
    return function (dispatch) {
        dispatch({
            type: ALERT_HIDE_ERROR
        })
    }
}

export function hideInfoAction() {
    return function (dispatch) {
        dispatch({
            type: ALERT_HIDE_INFO
        })
    }
}

export function hideSuccessAction() {
    return function (dispatch) {
        dispatch({
            type: ALERT_HIDE_SUCCESS
        })
    }
}

export function hideWarningAction() {
    return function (dispatch) {
        dispatch({
            type: ALERT_HIDE_WARNING
        })
    }
}

export function hideErrorDetailAction() {
    return function (dispatch) {
        dispatch({
            type: ALERT_HIDE_ERROR_DETAIL
        })
    }
}