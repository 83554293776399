import React, { useMemo, useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import { installmentsServices, exportReportServices, adminExpenseDailyServices } from "../../../services";
import Storage from "../../../common/Storage";

import SearchPanel from './SearchPanel';
import DataTable from './DataTable';

const Expenses = () => {
    const user = Storage.getUser();
    const Swal = useSwalWrapper();
    const listCompany = Storage.getCompany();
    const mounted = React.useRef(false);
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [searchParams] = useSearchParams();

    const [company, setCompany] = React.useState(
        [
            { label: t('title.please_select'), value: null, key: -1 },
        ]
    )

    const [loading, setLoading] = React.useState(false);
    const [params, SetParams] = React.useState({});
    const [data, Setdata] = React.useState([]);
    const [yearsInstallments, setYearsInstallments] = React.useState([]);
    const [refreshKey, setRefreshKey] = React.useState(0);

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    const handleSearch = (data, param) => {
        SetParams(param);
        Setdata(data);
    };

    const handleSubmit = async (request) => {
        setLoading(true);
        try {
            const res = await adminExpenseDailyServices.add(request);
            if (res) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Expenses has been Delete successfully.',
                    showConfirmButton: false,
                    timer: 1500
                });
                setRefreshKey(oldKey => oldKey + 1);
                setLoading(false);
            }
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Expenses has been added failed.',
                showConfirmButton: false,
                timer: 1500
            });
            setLoading(false);
        }
    }

    const handleExport = async () => {
        try {
            const { objinstallment, ...param } = params;

            const filename = "ExpensesByHrs_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            await exportReportServices.adminexpenseDaily(param, filename);
        } catch (error) {
            console.error(error, "handleExport Expenses")
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Export Report Expenses failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    useEffect(() => {
        if (listCompany) {
            const selectbox = listCompany.map(({ name, id }, index) => {
                return { label: name, value: id, key: index };
            })
            setCompany(selectbox)
        }
    }, []);

    useEffect(() => {
        async function fetchYearsInstallments() {
            const response = await installmentsServices.getYears();
            if (response && response) {
                setYearsInstallments(response.data)
            }
        }
        fetchYearsInstallments();
        return () => mounted.current = false;
    }, []);

    return (
        <React.Fragment>
            <JumboContentLayout
                header={<PageHeaderCustom title={t("display.report_expense_daily")} />}
                layoutOptions={layoutOptions}
            >
                <SearchPanel
                    company={company}
                    yearsInstallments={yearsInstallments}
                    onSubmit={handleSearch}
                    refreshKey={refreshKey}
                />

                <DataTable
                    data={data}
                    params={params}
                    listCompany={listCompany}
                    swal={Swal}
                    onSubmit={handleSubmit}
                    onExport={handleExport}
                    loading={loading} />
            </JumboContentLayout>
        </React.Fragment>
    );
};

export default Expenses;