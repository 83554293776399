import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const departmentServices = {};
departmentServices.getList = async (param) => {
    // const { queryParams } = queryKey[queryKey.length - 1];
    return await get('/department', param);
};

departmentServices.getAll = async (param) => {
    return await get('/department', param);
};

departmentServices.getDetail = async (id) => {
    return get(`/department/${id}`);
};
departmentServices.add = async (params) => {
    return post(`/department`, params);
};
departmentServices.update = async (params) => {
    return put(`/department/${+ params.id}`, params);
};

departmentServices.delete = async (id) => {
    return del(`/department/${id}`);
};

export default departmentServices;