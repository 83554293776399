import React from 'react';
import { ListItemText, Typography, IconButton } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";

import { menuItems } from './MenuItem';

const CompanyItem = ({ company, onAction }) => {
    return (
        <React.Fragment>
            <ListItemButton
                component={"li"}
                sx={{
                    p: theme => theme.spacing(1, 3),
                    borderBottom: 1,
                    borderBottomColor: 'divider',

                    '&:last-child': {
                        borderBottomColor: 'transparent'
                    }
                }}
            >
                <Div sx={{ width: '25%', flexShrink: 0, px: 1 }}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Company
                    </Typography>
                    <Typography variant={"body1"}>{company.name}</Typography>
                </Div>
                <Div sx={{ display: { xs: 'none', lg: 'block' }, width: '25%', flexShrink: 0, px: 1 }}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Phone
                    </Typography>
                    <Typography variant={"body1"}>{company.phone_number ? company.phone_number : "-"}</Typography>
                </Div>
                <Div sx={{ width: '20%', flexShrink: 0, px: 1 }}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Allow Check-in Late (Mins)
                    </Typography>
                    <Typography variant={"body1"}>{company.allow_timelate ? company.allow_timelate : 0}</Typography>
                </Div>
                <Div sx={{ width: '15%', flexShrink: 0, px: 1 }}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Working Hours
                    </Typography>
                    <Typography variant={"body1"}>{company.total_work_date ? company.total_work_date : '-'}</Typography>
                </Div>
                <Div sx={{ display: { xs: 'none', lg: 'block' }, width: '15%', flexShrink: 0, px: 1, float: 'right' }}>
                    <IconButton aria-label="edit" color='primary' onClick={() => { onAction("EDIT", company) }}>
                        <EditRoundedIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="error" onClick={() => { onAction("DELETE", company) }}>
                        <DeleteRoundedIcon />
                    </IconButton>
                </Div>
                <ListItemText sx={{ display: { xs: 'block', lg: 'none' }, width: '15%', flexShrink: 0, px: 1, float: 'right' }}>
                    <JumboDdMenu menuItems={menuItems} onClickCallback={(action) => { onAction(action.value, company) }} />
                </ListItemText>
            </ListItemButton>
        </React.Fragment>
    );
};

export default CompanyItem;