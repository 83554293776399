import React, { useMemo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import PageHeaderCustom from "../../../layouts/shared/headers/PageHeaderCustom/PageHeaderCustom";

import UserExpenseRequest from "../../../shared/widgets/UserExpenseRequest";
import Modifyuserexpenserequest from "../../../shared/widgets/UserExpenseRequest/Modifyuserexpenserequest";

import { expenseServices } from "../../../services";
import Storage from "../../../common/Storage";

const Userexpenserequest = () => {
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const [searchParams] = useSearchParams();
    const [userexpense, setUserexpense] = React.useState(null);
    const user = Storage.getUser();

    const layoutOptions = useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    useEffect(async () => {
        const getid = searchParams.get('id');
        if (getid) {
            console.log(getid);
            const response = await expenseServices.getById(getid);
            if (response) {
                setUserexpense(response.data);
            }
        }
    }, [])

    return (
        <>
            <JumboContentLayout
                header={<PageHeaderCustom title={t("sidebar.menuItem.userexpenserequest")} />}
                layoutOptions={layoutOptions}
            >
                {searchParams.get('mode') ? (<Modifyuserexpenserequest mode={searchParams.get('mode')} userexpenser={userexpense} />) : (<UserExpenseRequest />)}
            </JumboContentLayout>
        </>
    );
};
export default Userexpenserequest;