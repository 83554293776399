import React from "react";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const CompanyDropdown = ({ companies, activeCampany, onCampanyChange }) => {
    const handleChange = (e) => {
        onCampanyChange(e.target.value);
    };
    return (
        <Select value={activeCampany} onChange={handleChange} size={"small"}>
            <MenuItem value={"all"}>All Campany</MenuItem>
            {
                companies && companies.length > 0 && companies.map((company, index) => (
                    <MenuItem key={index} value={company?.value}>
                        {company?.label}
                    </MenuItem>
                ))
            }
        </Select>
    );
};
export default CompanyDropdown;
