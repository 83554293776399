import React from 'react';
import moment from 'moment';
import {
    ListItemText,
    Typography,
    Chip,
    Stack,
} from "@mui/material";

import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";

import { DISPLAY_FORMAT_DATE } from "../../../../utils/constants/date";

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const UserleaverequestItem = ({ userleave, t }) => {
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={userleave}
                secondaryAction={
                    <>
                        {(userleave?.statusCode === "waiting_approve") && (
                            <>
                                <Typography variant={"body1"}>{userleave.statusCode && <Chip color="primary" label={t('display.' + userleave.statusCode)}></Chip>}</Typography>
                            </>
                        )}
                    </>
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >

                <ListItemText
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
                                <Item sx={{ flexBasis: { xs: '100%', sm: '50%', md: '20%' } }} >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('display.request_date')}
                                    </Typography>
                                    <Typography variant={"body1"}>{moment(userleave?.create_at).format(DISPLAY_FORMAT_DATE)}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '20%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('display.expense_type')}
                                    </Typography>
                                    <Typography variant={"body1"}>{userleave?.type_name ? t('form.' + userleave.type_name) : ""}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '20%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('form.startdate')}
                                    </Typography>
                                    <Typography variant={"body1"}>{moment(userleave?.start_date).format('YYYY-MM-DD')}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '20%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('form.enddate')}
                                    </Typography>
                                    <Typography variant={"body1"}>{moment(userleave?.end_date).format('YYYY-MM-DD')}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '35%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('form.note')}
                                    </Typography>
                                    <Typography variant={"body1"}>{userleave?.note ? userleave.note : "-"}</Typography>
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};

export default UserleaverequestItem;