//---------- Modals -----------------------------
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

//---------- Alert -----------------------------
export const ALERT_SHOW_ERROR = "ALERT_SHOW_ERROR";
export const ALERT_SHOW_WARNING = "ALERT_SHOW_WARNING";
export const ALERT_SHOW_SUCCESS = "ALERT_SHOW_SUCCESS";
export const ALERT_SHOW_INFO = "ALERT_SHOW_INFO";
export const ALERT_SHOW_ERROR_DETAIL = "ALERT_SHOW_ERROR_DETAIL";

export const ALERT_HIDE_ERROR = "MSG_HIDE_ERROR";
export const ALERT_HIDE_WARNING = "MSG_HIDE_WARNING";
export const ALERT_HIDE_SUCCESS = "MSG_HIDE_SUCCESS";
export const ALERT_HIDE_INFO = "MSG_HIDE_INFO";
export const ALERT_HIDE_ERROR_DETAIL = "MSG_HIDE_ERROR_DETAIL";
