import React from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Avatar, Stack, Checkbox, Chip, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Typography, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import styled from "@emotion/styled";

import useUsersApp from "../hooks/useUsersApp";
import { usersServices } from "../../../services";
import UserForm from '../components/UserForm';
import UserDetail from '../components/UserDetail';
import Div from '@jumbo/shared/Div/Div';

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const UserItem = (props) => {
    const { user, company, role, usersByHeader, departments } = props;
    const { t } = useTranslation();
    const { showDialog, hideDialog } = useJumboDialog();
    const { setUsersListRefresh } = useUsersApp();

    const deleteContactMutation = useMutation(usersServices.delete, {
        onSuccess: () => {
            hideDialogAndRefreshContactsList();
        }
    });

    const hideDialogAndRefreshContactsList = React.useCallback(() => {
        hideDialog();
        setUsersListRefresh(true);
    }, [hideDialog, setUsersListRefresh]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: 'Update detail',
                    content: <UserForm user={user} company={company} role={role} usersByHeader={usersByHeader} departments={departments} onSave={hideDialogAndRefreshContactsList} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this contact?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteContactMutation.mutate(user),
                    onNo: hideDialog
                })
        }
    };

    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <UserDetail user={user} onClose={hideDialog} />
        })
    }, [showDialog, user]);

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={user}
                secondaryAction={
                    <>
                        <Div sx={{ display: { xs: 'block', md: 'none' } }}>
                            <JumboDdMenu
                                icon={<MoreHorizIcon />}
                                menuItems={[
                                    { icon: <EditRoundedIcon />, title: "Edit", action: "edit" },
                                    { icon: <DeleteRoundedIcon />, title: "Delete", action: "delete" }
                                ]}
                                onClickCallback={handleItemAction}
                            />
                        </Div>
                        <Div sx={{ display: { xs: 'none', md: 'block' } }}>
                            <IconButton onClick={() => { handleItemAction({ title: "Edit", action: "edit" }); }} >
                                <EditRoundedIcon fontSize="small" />
                            </IconButton>
                            <IconButton color="error" onClick={() => { handleItemAction({ title: "Delete", action: "delete" }); }} >
                                <DeleteRoundedIcon fontSize="small" />
                            </IconButton>
                        </Div>
                    </>
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >
                <ListItemAvatar onClick={showContactDetail}>
                    <Avatar alt={`${user.firstname} ${user.lastname}`} src={user.coverImage} />
                </ListItemAvatar>
                <ListItemText onClick={showContactDetail}
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
                                <Item
                                    sx={{
                                        flexBasis: { md: '15%' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        UserNo
                                    </Typography>
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0} noWrap>{user.user_no}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { xs: '100%', sm: '50%', md: '20%' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        FullName
                                    </Typography>
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0}
                                        noWrap>{user.firstname} {user.lastname}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        {user.email}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '20%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Company
                                    </Typography>
                                    {user.company && (<Chip label={`${user.company.name}`} size={"small"} />)}
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '28%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Email
                                    </Typography>
                                    <Typography variant={"body1"} noWrap>{user.email ? user.email : "-"}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '20%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Phone Number
                                    </Typography>
                                    <Typography variant={"body1"} noWrap>{user.phonenumber ? user.phonenumber : "-"}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '25%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        Role
                                    </Typography>
                                    <Typography variant={"body1"} noWrap>{user?.roletype?.name}</Typography>
                                </Item>
                            </Stack>
                        </Typography>
                    }>

                </ListItemText>
            </JumboListItem>
        </React.Fragment>
    );
};

export default UserItem;