import React, { useEffect } from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Paper, Stack, Fab, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, TablePagination, IconButton, ButtonGroup, Button } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';

import HolidayForm from './HolidayForm'
import HolidayFilter from './HolidayFilter'
import NoDataPlaceHolder from '../../../components/NoDataPlaceHolder';
import CalendarHoliday from './Calendar';
import { Storage } from "../../../common";
import { holidayServices } from "../../../services";


const Holiday = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const listCompany = Storage.getCompany();
    const { showDialog, hideDialog } = useJumboDialog();
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filter, setFilter] = React.useState({});
    const [holiday, setHoliday] = React.useState([]);
    const [view, setView] = React.useState("list");
    const [company] = React.useState(
        listCompany && listCompany
            .map(({ name, id }) => {
                return { label: name, value: id };
            }),
    )

    const columns = [
        {
            id: 'ref_companyid',
            label: t('display.company'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'name',
            label: t('display.holidayname'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'date',
            label: t('display.holiday_date'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'totaluser',
            label: t('display.user_total'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'operations',
            label: t('display.operations'),
            minWidth: 100,
            align: 'center',
        },
    ];

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: t('display.update_holiday'),
                    content: <HolidayForm holiday={menuItem.item} onSave={hideDialogAndRefresh} company={company} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this holiday?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteContactMutation.mutate(menuItem.item.id),
                    onNo: hideDialog
                });
                break;
            default:
                throw Error("Invalid action type to update Content Layout in ContentLayoutProvider");
        }
    };

    const deleteContactMutation = useMutation(holidayServices.delete, {
        onSuccess: () => {
            hideDialog();
            setRefreshKey(oldKey => oldKey + 1);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Holiday has been Delete successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Holiday has been Delete failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const hideDialogAndRefresh = React.useCallback(() => {
        hideDialog();
        setRefreshKey(oldKey => oldKey + 1);
    }, [hideDialog]);

    const hideDialogAndRefreshByFilter = React.useCallback((data) => {
        hideDialog();
        setRefreshKey(oldKey => oldKey + 1);
        setFilter(data);
    }, [hideDialog]);

    const showAddHolidayDialog = React.useCallback(() => {
        showDialog({
            title: t('display.add_holiday'),
            content: <HolidayForm onSave={hideDialogAndRefresh} company={company} />
        });
    }, [hideDialogAndRefresh, showDialog]);

    const showFilterHolidayDialog = React.useCallback(() => {
        showDialog({
            title: "Filter",
            content: <HolidayFilter onSave={hideDialogAndRefreshByFilter} company={company} />
        });
    }, [hideDialogAndRefresh, showDialog]);

    useEffect(() => {
        async function fetchData() {
            let param = {
                page
            }
            const response = await holidayServices.getList(param);
            if (response) {
                console.log(response);
                setHoliday(response.data.data)
                setTotal(response.data.total)
            }
        }
        fetchData();
    }, [refreshKey, page]);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={t('display.holiday')}
                action={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <IconButton size="small" color="primary" aria-label="filter" onClick={showFilterHolidayDialog}>
                            <FilterAltRoundedIcon />
                        </IconButton>
                        <ButtonGroup
                            variant="outlined"
                            disableElevation
                            sx={{
                                '& .MuiButton-root': {
                                    px: 1,
                                }
                            }}
                        >
                            <Button variant={view === "list" ? "contained" : "outlined"}
                                onClick={() => setView("list")}><ListRoundedIcon /></Button>
                            <Button variant={view === "calendar" ? "contained" : "outlined"}
                                onClick={() => setView("calendar")}><CalendarMonthRoundedIcon /></Button>
                        </ButtonGroup>

                        <Fab size="small" color="primary" aria-label="add" onClick={showAddHolidayDialog}>
                            <AddRoundedIcon />
                        </Fab>
                    </Stack>
                }
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
                wrapperSx={{ p: 0 }}
            >
                {
                    holiday && holiday.length <= 0 && (<NoDataPlaceHolder></NoDataPlaceHolder>)
                }
                {
                    holiday && holiday.length > 0 && view === 'list' && (
                        <>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {holiday.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column, index) => {
                                                        let value = row[column.id];

                                                        return (
                                                            <TableCell key={index} align={column.align} >
                                                                {(column.id === 'operations') ?
                                                                    <>
                                                                        <IconButton size='small' onClick={() => { handleItemAction({ title: "Edit", action: "edit", item: row }); }} >
                                                                            <EditRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                        <IconButton size='small' color="error" onClick={() => { handleItemAction({ title: "Delete", action: "delete", item: row }); }} >
                                                                            <DeleteRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </>
                                                                    :
                                                                    (column.id === 'ref_companyid') ?
                                                                        <>
                                                                            {listCompany && listCompany.find(f => f.id == value)?.name}
                                                                        </>
                                                                        :
                                                                        (column.id === 'totaluser') ?
                                                                            <>
                                                                                {row?.usergroup_holiday?.length}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {column.format && typeof value === 'number'
                                                                                    ? column.format(value)
                                                                                    : value}
                                                                            </>}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>)
                }
                {
                    holiday && holiday.length > 0 && view === 'calendar' && (
                        <>
                            <CalendarHoliday holiday={holiday} />
                        </>)
                }

            </JumboCardQuick>

        </React.Fragment>
    );
};

export default Holiday;