import React, { useMemo } from 'react';
import { Grid } from "@mui/material";

import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";

const Misc = () => {
  const { theme } = useJumboTheme();

  const layoutOptions = useMemo(() => ({
    sidebar: {
      sx: {
        [theme.breakpoints.up('lg')]: {
          position: 'sticky',
          zIndex: 5,
          top: 96,
          minHeight: 'auto',
        },
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      }
    },
    wrapper: {
      sx: {
        alignItems: 'flex-start',
      }
    }
  }), [theme]);

  return (
    <React.Fragment>
      <JumboContentLayout
        header={
          <PageHeader
            title={"Dashboard"}
            subheader={""} />
        }
        layoutOptions={layoutOptions}
      >
        
      </JumboContentLayout>
    </React.Fragment>
  );
}
export default Misc;