import React from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Button, Grid, Select, MenuItem, InputLabel, FormControl, Divider } from "@mui/material";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";

const SearchPanel = ({ title, onExport }) => {
    const { t } = useTranslation();
    const [typeSearch, setTypeSearch] = React.useState("");
    const [params, SetParams] = React.useState({});

    const handleChangeType = (event) => {
        setTypeSearch(event.target.value);
        SetParams({
            ...params,
            "type": event.target.value
        });
    };

    const handleChange = (value, type) => {
        SetParams({
            ...params,
            [type]: value
        });
    };

    const handleExport = () => {
        onExport(params);
    };

    const handleReset = () => {
        SetParams({
            typeleave: "all",
            typeexpense: "all",
        });
    };

    return (

        <JumboCardQuick
            title={title}
            headerSx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -.75
                }
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container spacing={1} sx={{ margin: "0 auto", mt: 2, width: "50%" }} >
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <InputLabel id="label-listtypereport">{t('form.listtypereport')}</InputLabel>
                            <Select
                                labelId="label-listtypereport"
                                id="select-listtypereport"
                                value={typeSearch}
                                label={t('form.listtypereport')}
                                onChange={handleChangeType}
                            >
                                <MenuItem value={""}>{t('form.please_select')}</MenuItem>
                                {/* <MenuItem value={'summary_attendance_report'}>{t('form.summary_attendance_report')}</MenuItem> */}
                                <MenuItem value={'employee_attendance_report'}>{t('form.employee_attendance_report')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {typeSearch && typeSearch === "employee_attendance_report" && (
                    <>
                        <Divider sx={{ mt: 2 }}></Divider>

                        <Grid container spacing={1} sx={{ margin: "0 auto", mt: 2, width: "50%" }}  >
                            <Grid item xs={6} >
                                <FormControl fullWidth>
                                    <DatePicker label={t('form.startdate')} maxDate={moment(params.enddate) || null}
                                        onChange={(value) => {
                                            handleChange(value.format('YYYY-MM-DD'), "startdate")
                                        }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                                <FormControl fullWidth>
                                    <DatePicker label={t('form.enddate')} minDate={moment(params.startdate) || null} onChange={(value) => {
                                        handleChange(value.format('YYYY-MM-DD'), "enddate")
                                    }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: 2 }}></Divider>
                    </>
                )}
            </LocalizationProvider>

            {typeSearch && (
                <Grid container spacing={1} sx={{ margin: "0 auto", mt: 2, width: "25%" }} >
                    <Grid item xs={6} textAlign={'center'} >
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleExport}
                            size="medium"
                            sx={{ mb: 3 }}
                        >Export</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            type="reset"
                            onClick={handleReset}
                            variant="outlined"
                            size="medium"
                            sx={{ mb: 3 }}
                        >Reset</Button>
                    </Grid>
                </Grid>
            )}
        </JumboCardQuick>
    );
};

export default SearchPanel;