import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Box, Button, IconButton, styled, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Chip } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TextField from '@mui/material/TextField';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const DataTable = ({ data, params, listCompany, swal, onSubmit, onExport, loading }) => {
    const { t } = useTranslation();
    const [body, Setbody] = React.useState([]);
    const [getCompany, SetGetCompany] = React.useState(listCompany && listCompany.find(f => f.id === params.ref_companyid));

    const columns = [
        {
            id: 'date',
            label: t('form.date'),
            cal_label: t('form.date'),
            minWidth: 120,
            align: 'center',
            cal_disabled: false,
        },
        {
            id: 'user_no',
            label: t('display.employeeno'),
            cal_label: t('display.employeeno'),
            minWidth: 150,
            align: 'center',
            cal_disabled: false,
        },
        {
            id: 'fullname',
            label: t('display.fullname'),
            cal_label: t('display.fullname'),
            minWidth: 200,
            align: 'center',
            cal_disabled: false,
        },
        {
            id: 'project',
            label: t('display.project'),
            cal_label: t('display.project'),
            minWidth: 50,
            align: 'center',
            cal_disabled: false,
        },
        {
            id: 'wage',
            label: t('display.wage'),
            cal_label: t('display.wage'),
            minWidth: 80,
            align: 'center',
            cal_disabled: true,
        },
        {
            id: 'wage_hr',
            label: t('display.wage_hr'),
            cal_label: t('display.wage_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'wage_extra_1_hr',
            label: t('display.wage_extra_1_hr'),
            cal_label: t('display.wage_extra_1_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'wage_extra_2_hr',
            label: t('display.wage_extra_2_hr'),
            cal_label: t('display.wage_extra_2_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'travel_allowance_1_hr',
            label: t('display.travel_allowance_1_hr'),
            cal_label: t('display.travel_allowance_1_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'travel_allowance_2_hr',
            label: t('display.travel_allowance_2_hr'),
            cal_label: t('display.travel_allowance_2_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'ot_hr',
            label: t('display.ot_hr'),
            cal_label: t('display.ot_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'allowance_overnight_day',
            label: t('display.allowance_overnight_day'),
            cal_label: t('display.allowance_overnight_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'cost_of_driving_day',
            label: t('display.cost_of_driving_day'),
            cal_label: t('display.cost_of_driving_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: false,
        },
        {
            id: 'holiday_with_certificate_day',
            label: t('display.holiday_with_certificate_day'),
            cal_label: t('display.holiday_with_certificate_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2),
            cal_disabled: true,
        },
        {
            id: 'note',
            label: t('form.note'),
            cal_label: t('form.note'),
            minWidth: 200,
            align: 'center',
            format: (value) => value,
            cal_disabled: true,
        },
    ];

    useEffect(() => {
        Setbody([]);
        data && data?.grpexpenser && Object.keys(data.grpexpenser).map((key, index1) => (
            data && data.user?.map((row, index) => {
                const getExpenser = data.grpexpenser[key].find(f => f.user_id === row.id);
                if (getExpenser) {
                    let value = {
                        ...getExpenser,
                        userId: getExpenser.user_id
                    }
                    Setbody(oldArray => [...oldArray, value]);
                }
            })
        ));

    }, [data]);

    const handleInput = (value, userId, type, user, dateString) => {
        try {
            // setTimeout(() => {
            const getTotalWorkdate = getCompany?.total_work_date || 8;
            const getUser = user;
            const wage = getUser?.wage;
            const cal_wage_default = (wage / getTotalWorkdate); // แปลงเป็นวัน

            let getFindInput = body.find(f => f.userId === userId && f.date === dateString);
            let calculateValue = {};
            let wage_baht = 0;
            switch (type) {
                case 'holiday_with_certificate_day':
                    const wage_hr = getFindInput?.wage_hr || 0;
                    wage_baht = Math.ceil(Number((wage_hr / getTotalWorkdate) * wage) + Number(value * wage));
                    calculateValue = {
                        'wage_baht': wage_baht
                    };
                    break;
                case 'wage_hr':
                    const holiday_with_certificate_day = getFindInput?.holiday_with_certificate_day || 0;
                    wage_baht = wage_baht + (value * wage);
                    wage_baht = Math.ceil(((value / getTotalWorkdate) * wage) + (holiday_with_certificate_day * wage));

                    calculateValue = {
                        'wage_baht': wage_baht
                    };
                    break;
                case 'wage_extra_1_hr':
                    const wage_extra_1_baht = (cal_wage_default * 1 * value);
                    calculateValue = {
                        'wage_extra_1_baht': wage_extra_1_baht,
                    };
                    break;
                case 'wage_extra_2_hr':
                    const wage_extra_2_baht = (cal_wage_default * 2 * value);
                    calculateValue = {
                        'wage_extra_2_baht': wage_extra_2_baht,
                    };
                    break;
                case 'wage_extra_3_hr':
                    const wage_extra_3_baht = (cal_wage_default * 3 * value);
                    calculateValue = {
                        'wage_extra_3_baht': wage_extra_3_baht,
                    };
                    break;
                case 'travel_allowance_1_hr':
                    const travel_allowance_1_baht = (cal_wage_default * 1 * value);
                    calculateValue = {
                        'travel_allowance_1_baht': travel_allowance_1_baht,
                    };
                    break;
                case 'travel_allowance_2_hr':
                    const travel_allowance_2_baht = (cal_wage_default * 1 * value);
                    calculateValue = {
                        'travel_allowance_2_baht': travel_allowance_2_baht,
                    };
                    break;
                case 'ot_hr':
                    const ot_baht = (cal_wage_default * 1.5 * value);
                    calculateValue = {
                        'ot_baht': ot_baht,
                    };
                    break;
                case 'allowance_overnight_day':
                    const overnight_rate_day = getUser?.overnight_rate_day || 0
                    const allowance_overnight_baht = (overnight_rate_day * value);
                    calculateValue = {
                        'allowance_overnight_baht': allowance_overnight_baht,
                    };
                    break;
                case 'cost_of_driving_day':
                    const driving_rate_day = getUser?.driving_rate_day || 0
                    const cost_of_driving_baht = (driving_rate_day * value);

                    calculateValue = {
                        'cost_of_driving_baht': cost_of_driving_baht,
                    };
                    break;
                default:
                    break;
            }

            if (getFindInput) {
                Setbody(prevState => {
                    const newState = prevState.map(obj => {
                        if (obj.userId === userId && obj.date === dateString) {
                            return {
                                ...obj,
                                ...calculateValue,
                                actionMode: "EDIT",
                                [type]: value,
                            };
                        }
                        return obj;
                    });
                    return newState;
                });
            } else {
                getFindInput = {
                    type: type,
                    userId: userId,
                    date: dateString,
                    actionMode: "EDIT",
                    ...calculateValue,
                    [type]: value,
                }
                Setbody(prevState => [...prevState, getFindInput])
            }
            // }, 10);
        } catch (error) {
            console.log(error);
            swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Expenses Error : ' + error.message,
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const handleSubmit = () => {
        onSubmit({
            ...params,
            body
        });
    };

    const handleExport = () => {
        onExport();
    }

    const handleDelete = (row) => {
        Setbody(prevState => {
            const newState = prevState.map(obj => {
                if (row.userId === obj.userId && row.date === obj.date) {
                    return {
                        ...obj,
                        actionMode: "DELETE",
                    };
                }
                return obj;
            });
            return newState;
        });
    }

    const handleReverse = (row) => {
        Setbody(prevState => {
            const newState = prevState.map(obj => {
                if (row.userId === obj.userId && row.date === obj.date) {
                    return {
                        ...obj,
                        actionMode: "",
                    };
                }
                return obj;
            });
            return newState;
        });
    }

    const dateRange = () => {
        const dateArray = [];
        if (params) {
            let startdate = moment();
            let enddate = moment();
            if (params.picked_select_date === "2") {
                startdate = moment(params.date);
                enddate = moment(params.date).add(1, 'days');
            } else {
                if (params.objinstallment) {
                    const { start_date, end_date } = params.objinstallment;
                    startdate = moment(start_date);
                    enddate = moment(end_date).add(1, 'days');
                }
            }
            for (var m = startdate; m.isBefore(enddate); m.add(1, 'days')) {
                dateArray.push(m.format('YYYY-MM-DD'))
            }
        }
        return dateArray;
    }

    const renderTableForm = () => {
        const arr = [];
        let index = 0;
        dateRange().map((dateString, index1) => {
            return (
                data && data.user?.map((row, index2) => {
                    arr.push(
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={index2 + '-' + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" id={`${row.user_no}`} scope="row" >
                                {body?.find(f => f.userId === row.id && f.date === dateString) && (
                                    <>
                                        {body?.find(f => f.userId === row.id && f.date === dateString).actionMode !== "DELETE" ?
                                            <>
                                                <IconButton color="error" aria-label="delete" disabled={body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1} onClick={() => { handleDelete(body.find(f => f.userId === row.id && f.date === dateString)) }} >
                                                    <HighlightOffRoundedIcon />
                                                </IconButton>
                                            </>
                                            :
                                            <>
                                                <IconButton color="success" aria-label="reverse" disabled={body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1} onClick={() => { handleReverse(body.find(f => f.userId === row.id && f.date === dateString)) }}>
                                                    <SettingsBackupRestoreRoundedIcon />
                                                </IconButton>
                                            </>}
                                    </>

                                )}
                            </TableCell>
                            <TableCell component="th" id={`${row.user_no}`} scope="row" >
                                {dateString}
                            </TableCell>
                            <TableCell component="th" id={`${row.user_no}`} scope="row" >
                                {row?.user_no}
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                {row?.prefix} {row?.firstname} {row?.lastname}
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                {body.find(f => f.userId === row.id) && body.find(f => f.userId === row.id).project?.name}
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" align='center' >
                                {Number(row.wage)}
                            </TableCell>
                            <TableCell component="th" id={`wage_hr_${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    name={`wage_hr`}
                                    size='small'
                                    value={body && body.find(f => f.userId === row.id && f.date === dateString)?.wage_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`wage_extra_1_hr_${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`wage_extra_1_hr`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.userId === row.id && f.date === dateString)?.wage_extra_1_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>

                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`wage_extra_2_hr`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.user_id === row.id && f.date === dateString)?.wage_extra_2_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`travel_allowance_1_hr`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.user_id === row.id && f.date === dateString)?.travel_allowance_1_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`travel_allowance_2_hr`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.user_id === row.id && f.date === dateString)?.travel_allowance_2_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`ot_hr`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.user_id === row.id && f.date === dateString)?.ot_hr}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`allowance_overnight_day`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.userId === row.id && f.date === dateString)?.allowance_overnight_day}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`cost_of_driving_day`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.userId === row.id && f.date === dateString)?.cost_of_driving_day}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`holiday_with_certificate_day`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.user_id === row.id && f.date === dateString)?.holiday_with_certificate_day}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                            <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                <TextField
                                    type="text"
                                    name={`note`}
                                    size='small'
                                    fullWidth
                                    value={body && body.find(f => f.userId === row.id && f.date === dateString)?.note}
                                    onChange={(e) => {
                                        handleInput(e.target.value, row.id, e.target.name, row, dateString)
                                    }}
                                    disabled={body.find(f => f.userId === row.id && f.date === dateString)?.actionMode === "DELETE" || body.find(f => f.userId === row.id && f.date === dateString)?.status_code === 1}
                                />
                            </TableCell>
                        </TableRow>
                    );
                    index++;
                })
            )
        })
        return arr;
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={<span>ฟอร์มกรอกค่าใช้จ่าย {body.find(f => f.actionMode) && <Chip label="แก้ไขข้อมูล" color="warning" size="small" />}</span>}
                action={
                    <Button variant="contained" size="small" onClick={handleExport} startIcon={<FileDownloadRoundedIcon />}>
                        Export
                    </Button>
                }
                wrapperSx={{ p: 0 }}
                sx={{ mt: 2 }}
            >
                <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                </StyledTableCell>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                renderTableForm()
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box textAlign='center'>
                    <Button id="submitsearch" variant="contained" size="medium" onClick={handleSubmit} disabled={loading}>{t('display.submit')}</Button>
                    <Button variant='outlined' sx={{ ml: 1 }}>{t('display.cancel')}</Button>
                </Box>
            </JumboCardQuick>

            <JumboCardQuick
                title={'คำนวณค่าใช้จ่าย (บาท)'}
                wrapperSx={{ p: 0 }}
                sx={{ mt: 2 }}
            >
                <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.filter(f => !f.cal_disabled).map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.cal_label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dateRange().map((dateString, index1) => (
                                    data && data.user?.map((row, index2) => {
                                        const getExpenser = data.grpexpenser[dateString] ? data.grpexpenser[dateString].find(f => f.user_id === row.id) : null;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index1 + "-" + index2}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" id={`${dateString}`} scope="row" align='center'  >
                                                    {dateString}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.user_no}`} scope="row" align='center'  >
                                                    {row.user_no}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    {row.prefix} {row.firstname} {row.lastname}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" align='center' >
                                                    {getExpenser?.project ? getExpenser.project.name : "-"}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.wage_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.wage_extra_1_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.wage_extra_2_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.travel_allowance_1_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.travel_allowance_2_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.ot_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.allowance_overnight_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={body && body.find(f => f.user_id === row.id && f.date === dateString)?.cost_of_driving_baht} variant="outlined" fullWidth sx={{ width: '100%' }} />

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default DataTable;