import React from 'react';
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import Stack from "@mui/material/Stack";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import { usersServices } from "../../../services";
import useContactsApp from "../hooks/useUsersApp";

const BulkActions = () => {
    const { selectedContacts, setUsersListRefresh } = useContactsApp();
    const { showDialog, hideDialog } = useJumboDialog();
    const { enqueueSnackbar } = useSnackbar();

    const deleteContactsMutation = useMutation(usersServices.deleteMultiple, {
        onSuccess: () => {
            hideDialog();
            setUsersListRefresh(true);
            enqueueSnackbar('Users has been deleted successfully.', {
                variant: "success"
            });
        }
    });
    const handleBulkDelete = React.useCallback(() => {
        showDialog({
            variant: "confirm",
            title: "Are you sure?",
            onYes: () => deleteContactsMutation.mutate(selectedContacts.map(contact => contact?.id)),
            onNo: hideDialog
        })
    }, [selectedContacts, deleteContactsMutation, hideDialog]);

    return (
        <Stack direction={"row"} sx={{ backgroundColor: 'transparent', ml: -2 }}>
            <Tooltip title={"Delete"}>
                <IconButton onClick={handleBulkDelete}><DeleteOutlineIcon /></IconButton>
            </Tooltip>
        </Stack>
    );
};

export default BulkActions;
