import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam'
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Fab } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

import Div from "@jumbo/shared/Div";

import Storage from "../../../../common/Storage";

import { fileServices } from '../../../../services/index'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: 'user',
    // facingMode: 'environment', //--environment
}

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CameraModal = (props) => {
    const { data, isOpen, headerText, onClose, onConfirm } = props;
    const { t } = useTranslation();
    const webcamRef = useRef(null);
    const [picture, setPicture] = useState(null);
    const [loading, setLoading] = useState(false);
    const [geo, setGeo] = useState(null);

    const handleClose = () => {
        onClose();
    }
    const handleUploadFile = async () => {
        const user = Storage.getUser();
        const data = new FormData();
        const file = DataURIToBlob(picture);
        data.append("category", `CHECKINOUT`);
        data.append("sub_category", `CAPTURESCREEN`);
        data.append("key_ref1", ``);
        data.append("key_ref2", "");
        data.append("key_ref3", "");
        data.append("file_type", "image/jpeg");
        data.append('file', file, `${uuidv4()}.jpeg`)
        data.append("user_id", user ? user.id + "" : "");
        data.append("uploadBy", user ? user.username : "");

        return await fileServices.uploadFile(data);
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            const response = await handleUploadFile();
            if (response) {
                onConfirm({ ...response, getSelect: geo })
                handleClose();
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        setPicture(null);
    }

    const handleChange = (event) => {
        const getSelect = data.find(f => f.id === event.target.value);

        setGeo(getSelect);
    };

    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot();
        setPicture(pictureSrc)
    }, [webcamRef])

    const onUserMediaError = (err) => {
        alert('error camera');
    };

    return (
        <BootstrapDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {headerText}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <FormControl sx={{ my: 1 }} fullWidth>
                    <InputLabel id="geo-input-label">{t('display.geo')}</InputLabel>
                    <Select
                        label={t('display.geo')}
                        labelId="geo-input-label"
                        id="geo-input"
                        value={geo?.id || null}
                        onChange={handleChange}
                    >
                        <MenuItem key={-1} value={null}>โปรดเลือก สถานที่</MenuItem>
                        {data && data.map((row, index) => {
                            return <MenuItem key={index} value={row.id}>{row.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {picture === null ? (
                    <>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            onUserMediaError={onUserMediaError}
                            style={{
                                textAlign: "center",
                                zindex: 8,
                                right: 0,
                                width: "100%",
                                objectFit: "fill",
                            }}
                        />
                        <Div sx={{
                            position: 'absolute',
                            bottom: '3.5em',
                            textAlign: 'center',
                            left: "0",
                            right: "0",
                            margin: "0 auto"
                        }}>
                            <Fab
                                aria-label="save"
                                color={"primary"}
                                onClick={capture}
                            >
                                <PhotoCameraIcon />
                            </Fab>
                        </Div>
                        {/* <button onClick={handleClose}>Capture Photo</button> */}
                    </>
                ) : (
                    <>
                        <img src={picture} />
                        <Div sx={{
                            position: 'absolute',
                            bottom: '2.5em',
                            textAlign: 'center',
                            left: "2em",
                        }}>
                            <Fab size="small"
                                variant="extended"
                                aria-label="save"
                                onClick={handleCancel}
                            >
                                <UndoOutlinedIcon />
                            </Fab>
                        </Div>

                        <Div sx={{
                            position: 'absolute',
                            bottom: '3.5em',
                            textAlign: 'center',
                            left: "0",
                            right: "0",
                            margin: "0 auto"
                        }}>
                            <Fab
                                aria-label="save"
                                color={"secondary"}
                                onClick={handleSave}
                                disabled={(loading === true || geo === null) ? true : false}
                            >
                                <CheckIcon />
                            </Fab>
                        </Div>
                    </>
                )}
            </DialogContent>
        </BootstrapDialog>
    );
};

export default CameraModal;