import React, { useEffect } from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { Box, Paper, Stack, Fab, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, TablePagination, IconButton, Typography, TextField } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import NoDataPlaceHolder from '../../../components/NoDataPlaceHolder';
import { Storage } from "../../../common";
import { workShiftServices } from "../../../services";
import WorkCycleForm from './WorkCycleForm'


const WorkCycle = () => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const { theme } = useJumboTheme();
    const listCompany = Storage.getCompany();
    const { showDialog, hideDialog } = useJumboDialog();
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [listItem, setListItem] = React.useState([]);
    const [activeCompany, setActiveCompany] = React.useState("all");
    const [company] = React.useState(
        listCompany && listCompany
            .map(({ name, id }) => {
                return { label: name, value: id };
            }),
    );

    const columns = [
        {
            id: 'openorclose',
            label: t('display.openorclose'),
            minWidth: 120,
            align: 'center',
        },
        {
            id: 'work_shift_code',
            label: t('display.shift_code'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'work_period',
            label: t('form.work_period'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'break_period',
            label: t('form.break_period'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'type',
            label: t('display.shift_type'),
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'operations',
            label: t('display.operations'),
            minWidth: 100,
            align: 'center',
        },
    ];

    const hideDialogAndRefresh = React.useCallback(() => {
        hideDialog();
        setRefreshKey(oldKey => oldKey + 1);
    }, [hideDialog]);

    const showAddShiftDialog = React.useCallback(() => {
        showDialog({
            title: `${t('display.add')}${t('display.shift')}`,
            content: <WorkCycleForm company={company} onSave={hideDialogAndRefresh} t={t} />
        });
    }, [hideDialogAndRefresh, showDialog, company]);

    const handleSearch = (e) => {
        const { name, value } = e.target;
    }

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: `${t('display.edit')}${t('display.shift')}`,
                    content: <WorkCycleForm item={menuItem.item} company={company} onSave={hideDialogAndRefresh} t={t} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this WorkShift?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteContactMutation.mutate(menuItem.item.id),
                    onNo: hideDialog
                });
                break;
            default:
                throw Error("Invalid action type to update Content Layout in ContentLayoutProvider");
        }
    };

    const deleteContactMutation = useMutation(workShiftServices.delete, {
        onSuccess: () => {
            hideDialog();
            setRefreshKey(oldKey => oldKey + 1);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Project has been Delete successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Project has been Delete failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });


    useEffect(() => {
        async function fetchData() {
            let param = { ref_company: activeCompany, page: page }
            const response = await workShiftServices.getlist(param);
            if (response) {
                setListItem(response.data);
            }
        }
        fetchData();
    }, [refreshKey, activeCompany, page]);

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography
                        component={"div"}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            [theme.breakpoints.down('md')]: {
                                flexWrap: 'wrap'
                            }
                        }}
                    >
                        <Typography
                            variant={"h4"}
                            mb={0}
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                    marginBottom: 2
                                }
                            }}
                        >
                            {t("display.shift")}
                        </Typography>
                    </Typography>
                }
                action={
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Box>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="name"
                                label={t("display.search")}
                                sx={{
                                    mr: 1
                                }}
                                onChange={handleSearch}
                            />
                        </Box>

                        <Fab size="small" color="primary" aria-label="add" onClick={showAddShiftDialog}>
                            <AddRoundedIcon />
                        </Fab>
                    </Stack>
                }
                headerSx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiCardHeader-action': {
                        my: -.75
                    }
                }}
                wrapperSx={{ p: 0 }}
            >
                {
                    listItem && listItem.length <= 0 && (<NoDataPlaceHolder></NoDataPlaceHolder>)
                }

                {
                    listItem && listItem.length > 0 && (
                        <>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listItem.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column, index) => {
                                                        let value = "";
                                                        if (column.id === 'company') {
                                                            value = row?.company?.name;
                                                        } else if (column.id === 'type') {
                                                            value = row?.type && t('display.' + row?.type);
                                                        } else {
                                                            value = row[column.id];
                                                        }

                                                        return (
                                                            <TableCell key={index} align={column.align} >
                                                                {(column.id === 'operations') ?
                                                                    <>
                                                                        <IconButton size='small' onClick={() => { handleItemAction({ title: t('display.edit'), action: "edit", item: row }); }} >
                                                                            <EditRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                        <IconButton size='small' color="error" onClick={() => { handleItemAction({ title: t('display.delete'), action: "delete", item: row }); }} >
                                                                            <DeleteRoundedIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </>
                                                                    : (column.id === 'work_period') ?
                                                                        <>
                                                                            {row?.work_start_time} -  {row?.work_end_time}
                                                                        </> :
                                                                        (column.id === 'break_period') ?
                                                                            <>
                                                                                {row?.break_start_time} -  {row?.break_end_time}
                                                                            </> :
                                                                            value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>)
                }

            </JumboCardQuick>
        </React.Fragment>
    );
};

export default WorkCycle;