import { http } from "../common";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const { get, post, put, del } = http(baseUrl);

const installmentsServices = {};
installmentsServices.getList = async ({ queryKey }) => {
    const { queryParams } = queryKey[queryKey.length - 1];
    return await get('/installments', queryParams);
};

installmentsServices.getAll = async (param) => {
    return await get('/installments', param);
};

installmentsServices.getDetail = async (id) => {
    return get(`/installments/${id}`);
};
installmentsServices.add = async (params) => {
    return post(`/installments`, params);
};
installmentsServices.update = async (params) => {
    return put(`/installments/${+ params.id}`, params);
};

installmentsServices.delete = async (id) => {
    return del(`/installments/${id}`);
};
installmentsServices.getYears = async () => {
    return await get('/installments/get-years');
};

export default installmentsServices;