import React from "react";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        role: ["superadmin", "admin", "employee"],
        children: [
            {
                uri: "/clockin-out",
                label: 'sidebar.menuItem.clockin',
                type: "nav-item",
                icon: <AccessTimeRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <DashboardRoundedIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'sidebar.menu.timemanagement',
        type: "section",
        role: ["superadmin", "admin", "employee"],
        children: [
            {
                uri: "/time/user-expense-request",
                label: 'sidebar.menuItem.userexpenserequest',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/time/user-overtime-request",
                label: 'sidebar.menuItem.userovertimerequest',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/time/user-leave-request",
                label: 'sidebar.menuItem.userleaverequest',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'sidebar.menu.companymanagement',
        type: "section",
        role: ["superadmin", "admin"],
        children: [
            {
                uri: "/admin/company/detail",
                label: 'sidebar.menuItem.companydetail',
                type: "nav-item",
                icon: <BusinessRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/company/department",
                label: 'sidebar.menuItem.department',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/company/holiday",
                label: 'sidebar.menuItem.holiday',
                type: "nav-item",
                icon: <CalendarMonthRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/company/project",
                label: 'sidebar.menuItem.project',
                type: "nav-item",
                icon: <WorkRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/company/workcycle",
                label: 'sidebar.menuItem.workcycle',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/expenses",
                label: 'sidebar.menuItem.expenses',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                label: 'sidebar.menuItem.reqmanagement',
                type: "collapsible",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/admin/approve/expense-request",
                        label: "sidebar.menuItem.userexpenserequest",
                        type: "nav-item"
                    },
                    {
                        uri: "/admin/approve/overtime-request",
                        label: "sidebar.menuItem.userovertimerequest",
                        type: "nav-item"
                    },
                    {
                        uri: "/admin/approve/leave-request",
                        label: "sidebar.menuItem.userleaverequest",
                        type: "nav-item"
                    },
                    {
                        uri: "/admin/approve/expenses",
                        label: "sidebar.menuItem.expenses",
                        type: "nav-item"
                    },
                ]
            },
            {
                uri: "/admin/expenses/group-project",
                label: 'sidebar.menuItem.expensesProject',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'sidebar.menu.report',
        type: "section",
        role: ["superadmin", "admin"],
        children: [
            {
                uri: "/admin/report/expensesdaily",
                label: 'sidebar.menuItem.expensesreportdaily',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/admin/report/leave",
            //     label: 'sidebar.menuItem.leavereport',
            //     type: "nav-item",
            //     icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/admin/report/expenses",
            //     label: 'sidebar.menuItem.expensesreport',
            //     type: "nav-item",
            //     icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/admin/report/attendance",
                label: 'sidebar.menuItem.attendancereport',
                type: "nav-item",
                icon: <CategoryRoundedIcon sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'sidebar.menu.usermanagement',
        type: "section",
        role: ["superadmin", "admin"],
        children: [
            {
                uri: "/admin/users/list",
                label: 'sidebar.menuItem.userlist',
                type: "nav-item",
                icon: <PersonRoundedIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'sidebar.menu.setting',
        type: "section",
        role: ["superadmin", "admin"],
        children: [
            {
                uri: "/admin/setting/installments",
                label: 'sidebar.menuItem.installments',
                type: "nav-item",
                icon: <PaymentRoundedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admin/setting/socialsecurity",
                label: 'sidebar.menuItem.socialsecurity',
                type: "nav-item",
                icon: <PeopleAltRoundedIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
];

export default menus;

