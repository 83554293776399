import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import InputMask from 'react-input-mask';

import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import JumboSelectField from "@jumbo/components/JumboFormik/JumboSelectField";

import { usersServices } from "../../../../services";

const validationSchema = yup.object({
    user_no: yup
        .string('Enter your user_no')
        .required('โปรดกรอกรหัสพนักงาน'),
});
const initialValues = {
    user_no: "",
    username: "",
    prefix: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    id_card_number: "",
    salary: "",
    ref_companyid: null,
    cover_image: "",
    ref_manager: null,
    ref_roletype: null,
    ref_department: null,
};

const UserForm = ({ user, company, role, usersByHeader, departments, onSave }) => {
    const Swal = useSwalWrapper();
    const { t } = useTranslation();
    const [header, setHeader] = React.useState(usersByHeader || []);
    const [roleByCompany, setRoleByCompany] = React.useState(role || []);
    const [department, setDepartment] = React.useState(departments || []);

    const addUserMutation = useMutation(usersServices.add, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User has been added successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const updateUserMutation = useMutation(usersServices.update, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'User has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    const onUserSave = (data, { setSubmitting }) => {
        setSubmitting(true);
        saveMutation.mutate(data);
        setSubmitting(false);
    };

    const saveMutation = React.useMemo(() => {
        return user?.id ? updateUserMutation : addUserMutation;
    }, [user, updateUserMutation, addUserMutation]);

    React.useEffect(() => {
        if (user) {
            const header = usersByHeader.filter(f => f.ref_companyid === user?.ref_companyid);
            setHeader(header);

            const getDepartments = departments?.filter(f => f.ref_companyid === user?.ref_companyid);
            setDepartment(getDepartments);
        }
    }, [user]);

    return (
        <Formik
            validateOnChange={true}
            initialValues={user?.id ? user : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onUserSave}
            validator={() => ({})}
        >
            {({ isSubmitting, setFieldValue, values, handleChange, errors }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="user_no" label={t('display.employeeno')} />
                        <JumboTextField fullWidth size="small" variant="outlined" name="username" label={t('form.username')} />

                        <Grid container spacing={1} >
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-prefix-label">{t('form.prefixname')}</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="select-prefix-label"
                                        id="select-prefix"
                                        name='prefix'
                                        label={t('form.prefixname')}
                                        size="small"
                                        defaultValue={user?.prefix}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''}>{t('title.please_select')}</MenuItem>
                                        <MenuItem value={'นาย'}>นาย</MenuItem>
                                        <MenuItem value={'นาง'}>นาง</MenuItem>
                                        <MenuItem value={'นางสาว'}>นางสาว</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4.5}>
                                <JumboTextField fullWidth size="small" variant="outlined" name="firstname" label={t('form.firstname')} />
                            </Grid>
                            <Grid item xs={4.5}>
                                <JumboTextField fullWidth size="small" variant="outlined" name="lastname" label={t('form.lastname')} />
                            </Grid>
                        </Grid>
                        <InputMask
                            mask="9-9999-99999-999"
                            value={values.id_card_number}
                            disabled={false}
                            maskChar="-"
                            onChange={handleChange}
                        >
                            {() => <TextField type="text" fullWidth variant="outlined" size="small" name="id_card_number" label={t('form.id_card_number')} />}
                        </InputMask>
                        <JumboTextField fullWidth size="small" variant="outlined" name="wage" label={t('form.wage')} />

                        <Autocomplete
                            fullWidth
                            id="ref_companyid"
                            name="ref_companyid"
                            onChange={(event, newValue) => {
                                setFieldValue("ref_companyid", newValue.value);

                                const mapheader = usersByHeader.filter(f => f.ref_companyid === newValue.value);
                                setHeader(mapheader);

                                const maprole = role.filter(f => f.ref_companyid === newValue.value);
                                setRoleByCompany(maprole);

                                const mapdepartment = departments.filter(f => f.ref_companyid === newValue.value);
                                setDepartment(mapdepartment);
                            }}
                            options={company ? company : []}
                            size="small"
                            variant="outlined"
                            defaultValue={user ? user.company.name : null}
                            isOptionEqualToValue={(option, value) => option.label === value}
                            renderInput={(params) => <TextField {...params} label={t('display.company')} />}
                        />

                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="ref_department"
                            name="ref_department"
                            options={department}
                            onChange={(event, newValue) => {
                                setFieldValue("ref_department", newValue.value);
                            }}
                            size="small"
                            variant="outlined"
                            defaultValue={user?.department ? user?.department?.name : null}
                            isOptionEqualToValue={(option, value) => option.label === value}
                            renderInput={(params) => <TextField {...params} label="Department" />}
                        />

                        <JumboSelectField fullWidth size="small" variant="outlined" name="ref_roletype" label="Role" options={roleByCompany} />
                        <Div sx={{ mt: 3 }}>
                            <Autocomplete
                                fullWidth
                                disablePortal
                                id="ref_manager"
                                name="ref_manager"
                                disabled={["manager", "superadmin"].includes(roleByCompany.find(f => f.value === values.ref_roletype)?.label)}
                                options={header && header.map(({ userNo, id }) => {
                                    return { label: userNo, value: id };
                                })}
                                onChange={(event, newValue) => {
                                    setFieldValue("ref_manager", newValue.value);
                                }}
                                size="small"
                                variant="outlined"
                                defaultValue={user?.userGroup ? user?.userGroup?.managers?.userNo : null}
                                renderInput={(params) => <TextField {...params} label="Manager" />}
                            />
                        </Div>

                        <FormControl fullWidth>
                            <InputLabel id="select-overnight_rate_day-label">{t('display.overnight_rate_day')}</InputLabel>
                            <Select
                                fullWidth
                                labelId="select-overnight_rate_day-label"
                                id="select-overnight_rate_day"
                                name='overnight_rate_day'
                                label={t('form.overnight_rate_day')}
                                size="small"
                                defaultValue={user?.overnight_rate_day}
                                onChange={handleChange}
                            >
                                <MenuItem value={''}>{t('title.please_select')}</MenuItem>
                                <MenuItem value={80}>80 บาท</MenuItem>
                                <MenuItem value={90}>90 บาท</MenuItem>
                                <MenuItem value={100}>100 บาท</MenuItem>
                            </Select>
                        </FormControl>

                        <JumboTextField sx={{ mt: 3 }} fullWidth type='number' size="small" variant="outlined" name="driving_rate_day" label={t('display.driving_rate_day')} />

                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting || saveMutation.isLoading}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik >
    );
};

UserForm.defaultProps = {
    onSave: () => {
    }
};
export default UserForm;