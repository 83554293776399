import React from 'react';
import { useField } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const JumboSelectField = (props) => {
    const { theme } = useJumboTheme();
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel {...props.id}>{props.label}</InputLabel>
                <Select {...props} {...field} error={!!errorText} >
                    {
                        props.options && props.options.map((value, key) => {
                            return <MenuItem key={key} value={value.value}>{value.label}</MenuItem>
                        })
                    }
                    {!props.disabled && (
                        <FormHelperText style={{ color: theme.palette.error.main }}>{errorText}</FormHelperText>
                    )}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};

export default JumboSelectField;