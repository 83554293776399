import React, { useEffect } from 'react';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { Box, Button, Checkbox, Chip, styled, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Tooltip } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import RejectForm from '../RejectForm'
import { formatNumber } from '../../../../../common/formatNumber';
import { adminExpenseDailyServices, exportReportServices } from "../../../../../services";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const DataTable = ({ data, params, onApprove, onReject }) => {
    const { t } = useTranslation();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();
    const [body, Setbody] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const columns = [
        {
            id: 'date',
            label: t('form.date'),
            cal_label: t('form.date'),
            minWidth: 150,
            align: 'center'
        },
        {
            id: 'user_no',
            label: t('display.employeeno'),
            cal_label: t('display.employeeno'),
            minWidth: 150,
            align: 'center'
        },
        {
            id: 'fullname',
            label: t('display.fullname'),
            cal_label: t('display.fullname'),
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'project',
            label: t('display.project'),
            cal_label: t('display.project'),
            minWidth: 50,
            align: 'center'
        },
        {
            id: 'wage_hr',
            label: t('display.wage_hr'),
            cal_label: t('display.wage_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'wage_extra_1_hr',
            label: t('display.wage_extra_1_hr'),
            cal_label: t('display.wage_extra_1_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'wage_extra_2_hr',
            label: t('display.wage_extra_2_hr'),
            cal_label: t('display.wage_extra_2_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'travel_allowance_1_hr',
            label: t('display.travel_allowance_1_hr'),
            cal_label: t('display.travel_allowance_1_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'travel_allowance_2_hr',
            label: t('display.travel_allowance_2_hr'),
            cal_label: t('display.travel_allowance_2_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'ot_hr',
            label: t('display.ot_hr'),
            cal_label: t('display.ot_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'allowance_overnight_day',
            label: t('display.allowance_overnight_day'),
            cal_label: t('display.allowance_overnight_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'cost_of_driving_day',
            label: t('display.cost_of_driving_day'),
            cal_label: t('display.cost_of_driving_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'holiday_with_certificate_day',
            label: t('display.holiday_with_certificate_day'),
            cal_label: t('display.holiday_with_certificate_baht'),
            minWidth: 150,
            align: 'center',
            format: (value) => value.toFixed(2)
        },
        {
            id: 'note',
            label: t('form.note'),
            cal_label: t('form.note'),
            minWidth: 200,
            align: 'center',
            format: (value) => value
        },
        {
            id: 'approve',
            label: t('display.approve'),
            cal_label: t('display.approve'),
            minWidth: 150,
            align: 'center',
            format: (value) => value
        },
    ];

    useEffect(() => {
        Setbody([]);
        data && data.user?.map((row, index) => {
            const getExpenser = data.expenser.find(f => f.user_id === row.id);
            if (getExpenser) {
                let value = {
                    ...getExpenser,
                    userId: getExpenser.user_id
                }
                Setbody(oldArray => [...oldArray, value]);
            }
        });
    }, [data]);

    const handleApprove = () => {
        showDialog({
            variant: 'confirm',
            title: t("display.approve_request"),
            content: t("detail.approve_Expenses_request"),
            onYes: () => approveMutation.mutate({ ...params, list_id: selected }),
            onNo: hideDialog
        });
    };

    const handleReject = () => {
        showDialog({
            title: t("display.reject_request"),
            content: <RejectForm params={params} selected={selected} onSave={handleRejectSave} onCancel={hideDialog} />
        });
    }

    const handleRejectSave = () => {
        onReject();
        hideDialog();
        setSelected([]);
    }

    const handleExport = async () => {
        try {
            const filename = "Expenses_" + moment().format("YYYYMMDD_hhmmss") + ".xlsx";
            await exportReportServices.adminexpenseDaily(params, filename);
        } catch (error) {
            console.error(error, "handleExport Expenses")
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Export Report Expenses failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.expenser.filter(f => f.status_code === 0).map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const approveMutation = useMutation(adminExpenseDailyServices.approve, {
        onSuccess: () => {
            onApprove();
            hideDialog();
            setSelected([]);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Approve successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        },
        onError: () => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Approve failed.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });


    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
        <React.Fragment>
            <JumboCardQuick
                title={'อนุมัติค่าใช้จ่าย (รายวัน)'}
                action={
                    <Box textAlign='right' >
                        <Button variant='contained' onClick={handleApprove} disabled={selected.length === 0}>
                            {t('display.approve')}
                        </Button>
                        <Button variant='contained' color="error" sx={{ ml: 1 }} onClick={handleReject} disabled={selected.length === 0}>
                            {t('display.reject')}
                        </Button>
                        <Button variant='outlined' color="primary" sx={{ ml: 1 }} onClick={handleExport} >
                            {t('display.export')}
                        </Button>
                    </Box>
                }
                wrapperSx={{ p: 0 }}
                sx={{ mt: 2 }}
            >
                <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='center' >
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selected.length > 0 && selected.length < data.expenser.filter(f => f.status_code === 0).length}
                                        checked={data.expenser.filter(f => f.status_code === 0).length > 0 && selected.length === data.expenser.filter(f => f.status_code === 0).length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                        }}
                                    />
                                </StyledTableCell>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{
                            '.Mui-disabled': {
                                '-webkit-text-fill-color': '#475259 !important',
                            }
                        }}>
                            {
                                data && data?.grpexpenser && Object.keys(data.grpexpenser).map((key, index1) => (
                                    data.grpexpenser && data.grpexpenser[key].map((row, index2) => {
                                        const labelId = `table-checkbox-${index1}-${index2}`;
                                        const isItemSelected = isSelected(row.id);
                                        const getUser = data.user.find(f => f.id === row.user_id);
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                aria-checked={isItemSelected}
                                                selected={isItemSelected}
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell padding="checkbox" align='center' >
                                                    {row.status_code === 0 && <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />}

                                                    {row.status_code !== 0 && <Tooltip title={row?.reason}><Chip label={t('display.' + row.status_name)} color={row.status_code === 1 ? "success" : "error"} variant="outlined" ></Chip></Tooltip>}

                                                </TableCell>
                                                <TableCell component="th" id={`${row.user_no}`} scope="row" align='center' >
                                                    {key}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.user_no}`} scope="row" align='center'  >
                                                    {getUser?.user_no}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    {getUser?.prefix} {getUser?.firstname} {getUser?.lastname}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" align='center' >
                                                    {row.project ? row.project.name : "-"}
                                                </TableCell>

                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.wage_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.wage_extra_1_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.wage_extra_2_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.travel_allowance_1_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.travel_allowance_2_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.ot_hr} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.allowance_overnight_day} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.cost_of_driving_day} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    <Chip label={row && row.holiday_with_certificate_day} variant="outlined" fullWidth sx={{ width: '100%' }} />
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" >
                                                    {row && row.note}
                                                </TableCell>
                                                <TableCell component="th" id={`${row.firstname}${row.lastname}`} scope="row" align='center' >
                                                    {row && row.approve_by}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </JumboCardQuick>


        </React.Fragment>
    );
};

export default DataTable;