import React from 'react';
import { useTranslation } from "react-i18next";

import { Stack, useMediaQuery } from "@mui/material";

import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";

import CompanyDropdown from "./components/CompanyDropdown";
import UsersAppSidebar from "./components/UsersAppSidebar";
import Userlist from "./Userlist";
import UsersAppProvider from "./UsersAppProvider";
import PageHeader from "../../layouts/shared/headers/PageHeader/PageHeader";

import { usersServices, rolesServices, departmentServices } from "../../services";
import Storage from "../../common/Storage";

const UsersApp = () => {
    const { theme } = useJumboTheme();
    const { t } = useTranslation();
    const listCompany = Storage.getCompany();

    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState([]);
    const [usersByHeader, setUsersByHeader] = React.useState([]);
    const [department, setDepartment] = React.useState([]);

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    React.useEffect(async () => {
        setLoading(true);
        const responseRoles = await rolesServices.getAll();
        if (responseRoles) {
            let roles = [
                { label: "Please Select", value: null },
                ...responseRoles.data
                    .map(({ name, id, ref_companyid }, index) => {
                        return { label: name, value: id, ref_companyid: ref_companyid };
                    }),
            ];
            setRole(roles);
        }

        const responseUserByRole = await usersServices.getListByRole({ nameRole: "Manager" });
        if (responseUserByRole) {
            setUsersByHeader(responseUserByRole.data);
        }

        const responseDepartment = await departmentServices.getAll();
        if (responseDepartment) {
            console.log(responseDepartment.data);
            let department = [
                { label: "Please Select", value: null },
                ...responseDepartment.data
                    .map(({ name, id, ref_companyid }, index) => {
                        return { label: name, value: id, ref_companyid: ref_companyid };
                    }),
            ];
            setDepartment(department);
        }
        setLoading(false);
    }, []);

    return (
        <UsersAppProvider>
            {!loading && <JumboContentLayout
                sidebar={<UsersAppSidebar listCompany={listCompany} role={role} usersByHeader={usersByHeader} department={department} t={t} />}
                header={
                    <PageHeader
                        title={"Employee Directory"}
                    />
                }
                layoutOptions={layoutOptions}
            >
                {
                    lg && (
                        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}>
                            <CompanyDropdown />
                        </Stack>
                    )
                }
                <Userlist listCompany={listCompany} role={role} usersByHeader={usersByHeader} department={department} t={t} />
            </JumboContentLayout>}
        </UsersAppProvider>
    );
};
export default UsersApp;