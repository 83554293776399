import {
    ALERT_SHOW_ERROR,
    ALERT_SHOW_INFO,
    ALERT_SHOW_SUCCESS,
    ALERT_SHOW_WARNING,
    ALERT_HIDE_ERROR,
    ALERT_HIDE_INFO,
    ALERT_HIDE_SUCCESS,
    ALERT_HIDE_WARNING,
    ALERT_SHOW_ERROR_DETAIL,
    ALERT_HIDE_ERROR_DETAIL
} from "../actions/actionTypes";

const initialState = {
    success: false,
    warning: false,
    danger: false,
    info: false,
    dangerDetail: false,
    message: ''
};

function AlertsRedux(state = initialState, action) {
    switch (action.type) {
        case ALERT_SHOW_ERROR:
            return {
                ...state,
                danger: true,
                message: action.payload
            };
        case ALERT_SHOW_ERROR_DETAIL:
            return {
                ...state,
                dangerDetail: true,
                message: action.payload
            };
        case ALERT_SHOW_INFO:
            return {
                ...state,
                info: true,
                message: action.payload
            };
        case ALERT_SHOW_SUCCESS:
            return {
                ...state,
                success: true,
                message: action.payload
            };
        case ALERT_SHOW_WARNING:
            return {
                ...state,
                warning: true,
                message: action.payload
            };
        case ALERT_HIDE_ERROR:
            return {
                ...state,
                danger: false
            };
        case ALERT_HIDE_INFO:
            return {
                ...state,
                info: false
            };
        case ALERT_HIDE_SUCCESS:
            return {
                ...state,
                success: false
            };
        case ALERT_HIDE_WARNING:
            return {
                ...state,
                warning: false
            };
        case ALERT_HIDE_ERROR_DETAIL:
            return {
                ...state,
                dangerDetail: false
            };
        default:
            return state;
    }
}
export default AlertsRedux;