import React from 'react';

const Myworkshifts = () => {
    return (
        <div>
            Myworkshifts
        </div>
    );
};

export default Myworkshifts;